import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import constants from 'ui/constants';

import PreviewHeader from 'ui/pages/homepage/previews/preview-header/PreviewHeader';

import './PreviewAbout.scss';

export default class PreviewAbout extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.setMessages = this.setMessages.bind(this);
  }

  componendDidMount() {
    this._isMounted = true;
    this.getMessages();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getMessages() {
    return fetch(`${constants.backendURL}messages`, { method: 'GET' }).then(
      this.setMessages.bind(this)
    );
  }

  setMessages(response) {
    return response.json().then(messages => {
      if (this._isMounted && messages && messages.length > 0) {
        this.setState({ showMessages: true });
      }
    });
  }

  render() {
    return (
      <div className="preview-about">
        <PreviewHeader headerText="Despre noi" dark />

        <div className="preview-about__content">
          <p>
            Biserica "Vestea Bună" din Iași este o biserică creștină după
            Evanghelie înființată în anul 2000.
          </p>

          <div className="preview-about__content__section">
            <div className="section-subtitle">Scopul</div>
            <div className="section-subcontent">
              Glorificarea lui Dumnezeu prin zidirea bisericii și extinderea
              împărăției Lui în comunitate.
            </div>
          </div>

          <div className="preview-about__content__section">
            <div className="section-subtitle">Viziunea</div>
            <div className="section-subcontent">
              Creștem în Cristos pentru a sluji pe semeni.
            </div>
          </div>

          <div className="preview-more">
            <Link to={{ pathname: '/despre', search: window.location.search }}>
              <span>Citește mai mult</span>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
