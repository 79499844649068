import React from 'react';
import Scroll from 'react-scroll';
import $ from 'jquery';

import UpcomingEvents from 'ui/pages/homepage/upcoming-events/UpcomingEvents';
import Schedule from './schedule/Schedule';
import Previews from './previews/Previews';
import AnimatedWrapper from 'ui/utils/animated-wrapper/AnimatedWrapper';
import pageViews from 'ui/utils/google-analytics/pageViews';
import Footer from 'ui/utils/footer/Footer';

import './homepage.scss';

class Homepage extends React.Component {
  constructor(props) {
    super(props);

    this.style = {
      backgroundImage: 'url(https://i.imgur.com/YxhnO2q.jpg)'
      // backgroundImage: 'url(https://i.imgur.com/Afd9Ak1.png)'
    };

    pageViews.registerPageView();
  }

  componentDidMount() {
    document.title = 'Acasă – Biserica Vestea Bună Iași';
    window.scrollTo(0, 0);

    $(window).scroll(() => {
      if ($(window).width() <= 859) {
        var scrolledY = $(window).scrollTop();
        $('.homepage__background').css(
          'background-position',
          'center ' + scrolledY + 'px'
        );
      }
    });
    $(window).resize(() => {
      if ($(window).width() <= 859) {
        var scrolledY = $(window).scrollTop();
        $('.homepage__background').css(
          'background-position',
          'center ' + scrolledY + 'px'
        );
      } else {
        $('.homepage__background').css('background-position', 'center');
      }
    });
  }

  render() {
    const ScrollLink = Scroll.Link;

    return (
      <div className="homepage">
        <div className="homepage__main">
          <div className="homepage__background" style={this.style} />
          <AnimatedWrapper type="fadeIn" duration={0.3}>
            <div className="homepage__background" style={this.style} />
          </AnimatedWrapper>
          <div className="homepage__content">
            <div className="homepage__title">
              <span>
                BISERICA <br />
                VESTEA BUNĂ
              </span>
            </div>
            <div className="homepage__slogan">
              <span>
                Creștem în Cristos pentru <br></br>a sluji pe semeni
              </span>
            </div>
            <div className="homepage-information">
              <div className="homepage-information__schedule">
                <Schedule />
              </div>
              <div className="homepage-information__right">
                <UpcomingEvents />
              </div>
            </div>
          </div>
          <ScrollLink
            className="homepage__scroll-mouse"
            to="previews"
            offset={$(window).width() > 767 ? -65 : 0}
            spy={true}
            smooth={true}
            duration={500}
          >
            <div className="scroll-wrapper">
              <div className="mouse">
                <div className="scroller" />
              </div>
            </div>
          </ScrollLink>
        </div>
        <div className="homepage__previews" id="previews">
          <Previews />
        </div>
        <Footer />
      </div>
    );
  }
}

export default Homepage;
