import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import { Typography, Popover } from '@material-ui/core';
import { OpenInNew } from '@material-ui/icons';
import moment from 'moment';

class EventDatesPopover extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      anchorEl: null,
      anchorOriginVertical: 'center',
      anchorOriginHorizontal: 'center',
      transformOriginVertical: 'center',
      transformOriginHorizontal: 'center',
      positionTop: 200,
      positionLeft: 400,
      anchorReference: 'anchorEl'
    };

    this.button = null;

    this.handleClickButton = this.handleClickButton.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClickButton() {
    this.setState({
      open: true,
      anchorEl: findDOMNode(this.button)
    });
  }

  handleClose() {
    this.setState({
      open: false
    });
  }

  render() {
    return (
      <div className="admin-table__extra-dates">
        <span
          ref={node => {
            this.button = node;
          }}
          className="admin-table__popover-toggle"
          onClick={this.handleClickButton}
        >
          ...
          <OpenInNew style={{ height: '16px', color: 'grey' }} />
        </span>
        <Popover
          open={this.state.open}
          anchorEl={this.state.anchorEl}
          anchorReference={this.state.anchorReference}
          anchorPosition={{ top: this.state.positionTop, left: this.state.positionLeft }}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: this.state.anchorOriginVertical,
            horizontal: this.state.anchorOriginHorizontal
          }}
          transformOrigin={{
            vertical: this.state.transformOriginVertical,
            horizontal: this.state.transformOriginHorizontal
          }}
        >
          <Typography style={{ margin: '4px', display: 'flex', flexDirection: 'column' }}>
            {this.props.times.map((time, key) => {
              return (
                <span key={key}>
                  {moment(time.startDate).format('DD MMM, YYYY, h:mm')} {' - '}
                  {moment(time.endDate).format('DD MMM, YYYY, h:mm')}
                </span>
              );
            })}
          </Typography>
        </Popover>
      </div>
    );
  }
}

export default EventDatesPopover;
