import React from 'react';
import { TableRow, Checkbox } from '@material-ui/core';
import classNames from 'classnames';

import CustomTableCell from 'ui/admin/custom-table/CustomTableCell';

const LiveTableRow = props => {
  const { video, editMode, onClickVideo, selectVideo, index } = props;

  return (
    <TableRow
      className={classNames('admin-table__row', { editable: editMode })}
      onClick={onClickVideo.bind(this, video)}
    >
      {editMode && (
        <CustomTableCell padding="checkbox" center={true}>
          <Checkbox
            style={{ fontSize: 20 }}
            checked={video.isSelected ? video.isSelected : false}
            onChange={selectVideo.bind(null, index, null)}
          />
        </CustomTableCell>
      )}
      <CustomTableCell padding="default" center={false} editMode={editMode}>
        {video.title}
      </CustomTableCell>
      <CustomTableCell padding="default" center={false} editMode={editMode}>
        {video.description}
      </CustomTableCell>
      <CustomTableCell padding="default" center={false} editMode={editMode}>
        {video.youtubeEmbeddedCode}
      </CustomTableCell>
    </TableRow>
  );
};

export default LiveTableRow;
