import React, { Component } from 'react';

import { Button, TextField } from '@material-ui/core';
import {
  Dialog,
  withMobileDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Switch
} from '@material-ui/core';

class AnnouncementDetailsDialog extends Component {
  constructor(props) {
    super(props);

    this.state = this.getBlankAnnouncement();
  }

  getBlankAnnouncement = (props = this.props) => {
    return {
      announcementId: props.announcement.announcementId || null,
      title: props.announcement.title || '',
      message: props.announcement.message || '',
      isFlagged: props.announcement.isFlagged ? props.announcement.isFlagged : false
    };
  };

  componentDidUpdate(prevPops) {
    if (prevPops.open !== this.props.open) {
      const newState = this.getBlankAnnouncement(this.props);
      this.setState(newState);
    }
  }

  onUpdateTitle = event => {
    this.setState({ title: event.target.value });
  };

  onUpdateMessage = event => {
    this.setState({ message: event.target.value });
  };

  onUpdateFlagged = isFlagged => {
    this.setState({ isFlagged });
  };

  onCloseDialog = () => {
    this.setState(this.getBlankAnnouncement());
    this.props.handleClose();
  };

  onSaveAnnouncement = () => {
    const newAnnouncement = {
      title: this.state.title,
      message: this.state.message,
      isFlagged: this.state.isFlagged
    };

    if (this.state.announcementId) {
      newAnnouncement.announcementId = this.state.announcementId;
    }

    this.props.handleSave(newAnnouncement);
    this.setState(this.getBlankAnnouncement());
  };

  render() {
    const { fullScreen } = this.props;

    return (
      <div>
        <Dialog
          className="admin-dialog"
          fullScreen={fullScreen}
          open={this.props.open}
          onClose={this.props.handleClose}
        >
          <DialogTitle>{this.props.dialogType === 'create' ? 'Adaugă un anunț' : 'Editează anunțul'}</DialogTitle>
          <DialogContent>
            <div>
              <div className="admin-row">
                <TextField
                  label="Titlu"
                  className="admin-input"
                  value={this.state.title}
                  onChange={this.onUpdateTitle}
                  fullWidth
                />
              </div>
              <div className="admin-row">
                <TextField
                  label="Mesaj"
                  className="admin-input"
                  rows={16}
                  style={{ overflowY: 'auto' }}
                  value={this.state.message}
                  onChange={this.onUpdateMessage}
                  multiline
                  fullWidth
                />
              </div>
              <div className="admin-row">
                <div>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={this.state.isFlagged}
                        onChange={(event, checked) => this.onUpdateFlagged(checked)}
                      />
                    }
                    label={<span style={{ fontSize: '14px' }}>Afișează pe prima pagină</span>}
                  />
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.onCloseDialog} className="admin-dialog__button" color="primary">
              Anulează
            </Button>
            <Button onClick={this.onSaveAnnouncement} className="admin-dialog__button" color="primary" autoFocus>
              Salvează
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withMobileDialog()(AnnouncementDetailsDialog);
