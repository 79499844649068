import React from 'react';
import { TableRow, Checkbox } from '@material-ui/core';
import classNames from 'classnames';
import moment from 'moment';

import CustomTableCell from 'ui/admin/custom-table/CustomTableCell';

const ExceptionsTableRow = props => {
  const { exception, editMode, onClickException, selectException, index } = props;
  const date = moment(exception.morningStartTime || exception.eveningStartTime).format('D MMMM YYYY');

  return (
    <TableRow
      className={classNames('admin-table__row', { editable: editMode })}
      onClick={onClickException.bind(this, exception)}
    >
      {editMode && (
        <CustomTableCell padding="checkbox" center={true}>
          <Checkbox
            style={{ fontSize: 20 }}
            checked={exception.isSelected ? exception.isSelected : false}
            onChange={selectException.bind(null, index, null)}
          />
        </CustomTableCell>
      )}
      <CustomTableCell padding="default" center={false} editMode={editMode}>
        {exception.name}
      </CustomTableCell>
      <CustomTableCell padding="default" center={false} editMode={editMode}>
        {date}
      </CustomTableCell>
      <CustomTableCell padding="none" center={false}>
        {exception.morningStartTime && moment(exception.morningStartTime).format('h:mm')}
      </CustomTableCell>
      <CustomTableCell padding="none" center={false}>
        {exception.eveningStartTime && moment(exception.eveningStartTime).format('h:mm')}
      </CustomTableCell>
    </TableRow>
  );
};

export default ExceptionsTableRow;
