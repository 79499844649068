import React from 'react';

import './footer.scss';
import { Link } from 'react-router-dom';

class Footer extends React.Component {
  render() {
    const currentYear = new Date().getFullYear();

    return (
      <div className="footer">
        <div className="footer__content">
          <div className="footer__salutation">
            <p className="section-text">
              „De aceea, preaiubiţii mei fraţi, fiţi tari, neclintiţi, sporiţi totdeauna în lucrul Domnului, căci ştiţi
              că osteneala voastră în Domnul nu este zadarnică.” (1 Corinteni 15:58)
            </p>
          </div>

          <div className="footer__contact">
            <div className="footer__contact__left">
              <div className="contact-item">
                <i className="fa fa-map-marker" aria-hidden="true" />
                <Link to="/contact">
                  <span className="section-text">Str. dr. Victor Babeș nr. 3, Iași</span>
                </Link>
              </div>
              <div className="contact-item">
                <i className="fa fa-envelope" aria-hidden="true" />
                <a className="section-text" href="mailto:contact@vesteabunaiasi.ro">
                  contact@vesteabunaiasi.ro
                </a>
              </div>
            </div>

            <div className="footer__contact__right">
              <a
                href="https://www.youtube.com/channel/UCaoVG3RhsB59sRq6cSBxovA"
                target="_blank"
                rel="noopener noreferrer"
                className="contact-item"
              >
                <i className="fa fa-youtube-play" aria-hidden="true" />
                <span className="section-text">Youtube</span>
              </a>
              <a
                href="https://www.facebook.com/vesteabunaiasi"
                className="contact-item"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-facebook-square" aria-hidden="true" />
                <span className="section-text">Facebook</span>
              </a>
            </div>
          </div>

          <div className="footer__copyright">
            <p>
              <i className="fa fa-copyright" aria-hidden="true" />
              <span>Biserica Vestea Bună Iași, {currentYear}</span>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
