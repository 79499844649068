import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Button, IconButton, Typography, Toolbar } from '@material-ui/core';
import { Menu } from '@material-ui/icons';

import AdminMenu from 'ui/admin/admin-menu/AdminMenu';

import './admin-header.scss';

class AdminHeader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            menuOpen: false
        };

        this.toggleMenu = this.toggleMenu.bind(this);
    }

    toggleMenu(openState, event) {
        this.setState({ menuOpen: openState });
    }

    render() {
        return (
            <div>
                <AppBar position="static" className="admin-header">
                    <Toolbar className="admin-header__toolbar">
                        <IconButton
                            color="inherit"
                            aria-label="Menu"
                            onClick={this.toggleMenu.bind(this, true)}
                            style={{ fontSize: '30px', marginLeft: '-18px' }}
                            className="admin-header__toggle"
                        >
                            <Menu />
                        </IconButton>
                        <Typography
                            type="title"
                            color="inherit"
                            style={{ fontSize: '16px', flex: 1 }}
                            className="admin-header__title"
                        >
                            <Link to="/admin">
                                <span>Admin – Biserica Vestea Bună</span>
                            </Link>
                        </Typography>
                        <Button color="inherit" onClick={this.props.onLogout}>
                            Logout
                        </Button>
                    </Toolbar>
                </AppBar>
                <AdminMenu
                    isPermanent={false}
                    isOpen={this.state.menuOpen}
                    onClose={this.toggleMenu.bind(this, false)}
                />
            </div>
        );
    }
}

export default AdminHeader;
