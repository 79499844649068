import React, { Component } from 'react';
import { Snackbar, IconButton } from '@material-ui/core';
import { Clear } from '@material-ui/icons';

import CustomPaginatedTable from '../custom-table/CustomPaginatedTable';

import constants from 'ui/constants';

class AdminMessages extends Component {
  constructor() {
    super();

    this.state = {
      snackbarOpen: false,
      snackbarMessage: '',
      currentPage: 0,
      headerCells: [
        { name: 'Titlu', padding: 'default', center: false },
        { name: 'Descriere', padding: 'default', center: false },
        { name: 'Link Youtube', padding: 'default', center: false }
      ]
    };
  }

  displaySnackbar = (type, positive, length) => {
    let snackbarMessage = '';

    if (type === 'create') {
      if (positive) {
        snackbarMessage = 'Mesajul a fost adăugat.';
      } else {
        snackbarMessage = 'Mesajul nu a putut fi adăugat.';
      }
    } else if (type === 'edit') {
      if (positive) {
        snackbarMessage = 'Mesajul a fost modificat.';
      } else {
        snackbarMessage = 'Mesajul nu a putut fi modificat.';
      }
    } else {
      if (positive) {
        snackbarMessage =
          length > 1 ? 'Mesajele au fost șterse.' : 'Mesajul a fost șters.';
      } else {
        snackbarMessage =
          length > 1
            ? 'Mesajele nu au putut fi șterse.'
            : 'Mesajul nu a putut fi șters.';
      }
    }

    this.setState({ snackbarOpen: true, snackbarMessage });
  };

  onCloseSnackbar = () => {
    this.setState({ snackbarOpen: false });
  };

  render() {
    return (
      <div className="admin-messages">
        <CustomPaginatedTable
          identifier="messageId"
          itemType="message"
          tableTitle="Mesaje"
          backendURL={`${constants.backendURL}messages`}
          displaySnackbar={this.displaySnackbar}
          headerCells={this.state.headerCells}
          propertyName={'messages'}
        />
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={this.state.snackbarOpen}
          onClose={this.onCloseSnackbar}
          autoHideDuration={3000}
          message={<span>{this.state.snackbarMessage}</span>}
          snackbarcontentprops={{
            style: { backgroundColor: '#ffffff', color: '#191919' }
          }}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.onCloseSnackbar}
            >
              <Clear />
            </IconButton>
          ]}
        />
      </div>
    );
  }
}

export default AdminMessages;
