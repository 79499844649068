import React from 'react';
import { TableRow, Checkbox } from '@material-ui/core';
import classNames from 'classnames';

import CustomTableCell from 'ui/admin/custom-table/CustomTableCell';

const PastorsTableRow = props => {
  const { pastor, editMode, onClickPastor, selectPastor, index } = props;

  return (
    <TableRow
      className={classNames('admin-table__row', { editable: editMode })}
      onClick={onClickPastor.bind(this, pastor)}
    >
      {editMode && (
        <CustomTableCell padding="checkbox" center={true}>
          <Checkbox
            style={{ fontSize: 20 }}
            checked={pastor.isSelected ? pastor.isSelected : false}
            onChange={selectPastor.bind(null, index, null)}
          />
        </CustomTableCell>
      )}
      <CustomTableCell padding="default" center={false} editMode={editMode}>
        {pastor.name}
      </CustomTableCell>
      <CustomTableCell padding="default" center={true} editMode={editMode} style={{ textAlign: 'center' }}>
        {pastor.photoURL && (
          <img
            src={pastor.photoURL}
            className="admin-table__image large"
            onClick={() => window.open(pastor.photoURL, '_blank')}
            alt=""
          />
        )}
      </CustomTableCell>
      <CustomTableCell padding="default" center={true}>
        {pastor.description}
      </CustomTableCell>
    </TableRow>
  );
};

export default PastorsTableRow;
