import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import Cookies from 'universal-cookie';

import constants from 'ui/constants';

import './admin-login.scss';

const cookies = new Cookies();

class AdminLogin extends Component {
  constructor() {
    super();

    this.state = {
      username: '',
      password: '',
      showErrorMessage: false
    };
  }

  shouldDisableLoginButton = () => {
    return !this.state.username || !this.state.password;
  };

  attemptLogin = () => {
    const username = this.state.username;
    const password = this.state.password;

    axios({
      method: 'post',
      url: `${constants.backendURL}authentication/login`,
      data: JSON.stringify({ username, password }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        this.setState({ showErrorMessage: false });
        cookies.set('token', response.data.token);
        axios.defaults.headers.common['x-access-token'] = cookies.get('token');
        this.props.onSuccessfulLogin();
      })
      .catch(() => {
        this.setState({ showErrorMessage: true });
      });
  };

  updateUsername = e => {
    this.setState({ username: e.target.value });
  };

  updatePassword = e => {
    this.setState({ password: e.target.value });
  };

  handleKeyPress = e => {
    if (this.shouldDisableLoginButton()) {
      return;
    }
    if (e.key === 'Enter') {
      this.attemptLogin();
    }
  };

  render() {
    const buttonStyle = {
      backgroundColor: '#ffcc00',
      border: 0,
      color: 'black',
      padding: '4px 15px',
      fontWeight: 700,
      textTransform: 'uppercase',
      marginTop: 20
    };

    return (
      <div className="admin-login-wrapper">
        <div className="admin-login">
          <p>Admin Login</p>
          <div className="admin-login__credentials">
            <div className="admin-login__username">
              <TextField
                fullWidth
                label="Username"
                name="username"
                labelclassname="label"
                value={this.state.username}
                onChange={this.updateUsername}
                onKeyPress={this.handleKeyPress}
              />
            </div>
            <div className="admin-login__password">
              <TextField
                fullWidth
                label="Password"
                name="password"
                type="password"
                labelclassname="label"
                value={this.state.password}
                onChange={this.updatePassword}
                onKeyPress={this.handleKeyPress}
              />
            </div>
            <Button disabled={this.shouldDisableLoginButton()} onClick={this.attemptLogin} style={buttonStyle}>
              Login
            </Button>
          </div>
          {this.state.showErrorMessage && <div className="admin-login__error">Numele sau parola sunt incorecte.</div>}
        </div>
      </div>
    );
  }
}

export default AdminLogin;
