import React from 'react';

import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';

export class GoogleMap extends React.Component {
  render() {
    var markers = this.props.markers;
    var location = markers.map(function(marker, key) {
      return <Marker position={{ lat: marker[1], lng: marker[2] }} key={key} />;
    });

    return (
      <Map
        google={this.props.google}
        zoom={this.props.zoom}
        zoomControl={this.props.zoomControl}
        initialCenter={this.props.center}
        clickableIcons={false}
        gestureHandling={'cooperative'}
        mapTypeControl={false}
        styles={this.props.styleMap}
      >
        {location}
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyBXwnjxkjL0JlKGXzswNnnwHufoWaqwofg'
})(GoogleMap);
