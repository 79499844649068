import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import PreviewHeader from 'ui/pages/homepage/previews/preview-header/PreviewHeader';
import Pastor from 'ui/pages/homepage/previews/preview-pastors/pastor/Pastor';
import constants from 'ui/constants';

import './previewPastors.scss';

type MyState = { pastors: any[] };

export default class PreviewPastors extends Component<any, MyState> {
  private _isMounted = false;

  constructor(public props: any) {
    super(props);

    this.state = {
      pastors: []
    };

    this.setPastors = this.setPastors.bind(this);
    this.displayPastors = this.displayPastors.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.getPastors();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getPastors() {
    return fetch(`${constants.backendURL}pastors`, { method: 'GET' }).then(
      this.setPastors
    );
  }

  setPastors(response: any) {
    return response.json().then((pastors: any) => {
      if (this._isMounted) {
        this.setState({ pastors: pastors });
      }
    });
  }

  displayPastors() {
    return this.state.pastors.map((item: any, key: number) => {
      return <Pastor data={item} key={key} />;
    });
  }

  render() {
    return (
      <div className="preview-pastors">
        <div className="preview-pastors__content">
          <PreviewHeader headerText="Prezbiteri" />
          <div className="preview-pastors__list">{this.displayPastors()}</div>
          {/* <div className="preview-more">
            <Link to={{ pathname: '/prezbiteri', search: window.location.search }}>
              <span>Citește mai mult</span>
            </Link>
          </div> */}
        </div>
      </div>
    );
  }
}
