const vision = {
    scripture: {
        sections: [
            {
                subtitle: `Noi credem și mărturisim că:`,
                paragraphs: [
                    `Biblia, care cuprinde cele 66 de cărţi, este revelaţia specială a lui Dumnezeu dată omului prin Duhul Sfânt, constituie cuvântul prenar (inspirat în egală măsură în toate părţile lui) al lui Dumnezeu (1 Cor. 2:7-16; 2 Petru 1:20-21; 2 Tim. 3:15-16).`,
                    `Biblia este o revelaţie obiectivă, propozițională (alcătuită din propoziţii – declarații informative) (1 Cor. 2:13; 1 Tes. 2:13), inspirată de Dumnezeu (2 Tim. 3:15-16), absolut inerantă (fără greşeală) în manuscrisele originale şi infailibilă (care nu poate greşi).`,
                    `Biblia este singura autoritate finală în materie de doctrină (credinţă) şi practică (Matei 5:18; 24:35; Ioan 10:35; 16:12-13; 17:17; 1 Cor. 2:13; 3:15-17; Evrei 4:12; 2 Petru 1:20-21).`,
                    `Deşi pot există mai multe aplicaţii ale unui text biblic, nu există decât o singură interpretare. Pentru înţelegerea Scripturii este nevoie de un studiu serios, aprofundat, zilnic şi folosind metode corecte de interpretare biblică (studiu lexical-gramatical, istoric şi contextual), însoţit de post, rugăciuni şi luminarea Spiritului Sfânt (Ioan 7:17; 16:12-15; 1 Cor. 2:7-16; 1 Ioan 2:20,27). Este marele privilegiu al creştinilor de a afla adevărul (Ioan 8:32) şi în egală măsură marea responsabilitate de a aplica în viaţă adevărul revelat în Sfânta Scriptură.`,
                    `Biblia este revelaţia finală a lui Dumnezeu şi nimeni nu are dreptul să adauge sau să scoată ceva din ea fără să suporte consecinţele cumplite cuprinse în ea (Apoc. 22:18-19; Exod 20:1-17; Deut 4:2; 12:32; 29:1; 31:24; Prov. 30:6; Ier. 36:27-28).`
                ]
            }
        ]
    },
    God: {
        sections: [
            {
                subtitle: `Noi credem:`,
                paragraphs: [
                    `Că există un singur Dumnezeu veşnic, viu şi adevărat (Deut. 6:4; Isaia 45:5-7; 1 Cor. 8:4; Efeseni 4:6), Spirit infinit, personal şi suveran, Creatorul şi Susţinătorul universului, care este şi omnipotent (Ioan 4:24; Ps. 139:1-12; Isaia 40:12-31; 46:10; Fapte 2:32; 4:10).`,
                    `În Dumnezeul unic, care este imuabil, există în trei Persoane distincte, dar de aceeaşi esenţă – Tatăl, Fiul şi Duhul Sfânt – care sunt co-eterne, co-egale şi care lucrează în armonie desăvârşită la împlinirea planului Sau veşnic (Maleahi 3:6; Evrei 13:8; Ioan 1:1; Matei 28:19; 2 Cor. 13:14; Efeseni 1:1-14; Ieremia 1:12; Isaia 46:10-11).`,
                    `Că Dumnezeu este infinit în sfinţenie, dragoste, credincioșie, dreptate şi bunătate şi că prezenţa Lui arată o măreţie şi slavă care nu pot fi exprimate în cuvinte şi, astfel, El este singurul vrednic de toată închinarea, dragostea, adorarea şi încrederea noastră (Isaia 6:1-17; 57:15; Exod. 15:11; Ioan 3:16; Romani 5:5,8; 1 Ioan 4:8,16; Efeseni 2:4; Romani 2:4; Fapte 17:31; 2 Tim. 2:13).`
                ]
            },
            {
                title: `Dumnezeu Tatăl`,
                subtitle: `Noi credem și mărturisim că:`,
                paragraphs: [
                    `Dumnezeu Tatăl, prima Persoană a Trinităţii, decretează şi porunceşte toate lucrurile după planul Sau înţelept, potrivit cu voia şi scopul Său sfânt (Ps. 145:8-9; 135:6; 1 Cor. 8:6; Efeseni 1:9). Decretele au ca scop slava lui Dumnezeu, au fost stabilite înainte de întemeierea lumii şi privesc controlul Sau suveran asupra tuturor lucrurilor, domeniilor şi evenimentelor din univers (Gen. 2:18; Iov 14:5; Ps. 2; 33:6-11; Zaharia 14; Fapte 17:26,31; Romani 8:28; Efeseni 1:3-12; 2 Tes. 2:7; 2 Tim. 2:9).`,
                    `Dumnezeu Tatăl este Creatorul tuturor lucrurilor (Gen. 1:1-31; Efes. 3:9). Ca singur Stăpân absolut şi atotputernic al universului, El este suveran în creaţie, răscumpărare şi providenţă (Ps. 103:19; Efes. 1:3-6; Romani 11:36). În cadrul Trinităţii există o subordonare de ordine, şi nu de esenţă (Ioan 1:1; 10:29-30; 14:6,9; 2 Cor. 5:19). Calitatea Sa de Tată se referă atât la titlul Sau în cadrul Trinităţii, cât şi la relaţia Lui cu omenirea. Ca şi Creator, El este Tatăl tuturor oamenilor (Ef. 4:6), dar ca Tată spiritual, numai pentru cei credincioşi (Romani 8:14-16; 2 Cor. 6:18; Efeseni 1:11-12; Ieremia 1:12). El a hotărât pentru propria Sa glorie toate lucrările care se petrec. El susţine, conduce şi stăpâneşte orice creatură şi eveniment (1 Cron. 29:11-12; Matei 6:26; Ps. 104:27-30). Dumnezeu Tatăl nu este autorul păcatului (Habacuc 1:13; Ioan 8:38-47) şi nici nu ispiteşte pe nimeni să păcătuiască (Iacov 1:13), dar omul este răspunzător pentru păcat înaintea lui Dumnezeu, pentru că El judecă fără părtinire (1 Petru 1:17-19). El a ales credincioşii pentru mântuire înainte de întemeierea lumii (Efes. 1:4-5; 2 Tim. 1:9) pentru că este un Tată nemărginit în iubire, îndurare şi milă şi doreşte „ca toţi oamenii să fie mântuiţi şi să vină la cunoştinţa adevărului” (1 Tim. 2:4; 2 Petru 3:9). Toţi cei care vin la El prin Isus Cristos devin copiii Lui spirituali (Ioan 1:12; Galateni 4:5; Evrei 12:5-9).`
                ]
            },
            {
                title: `Dumnezeu Fiul`,
                subtitle: `Noi credem și mărturisim că:`,
                paragraphs: [
                    `Isus Cristos, a doua Persoană a Trinităţii, are toate atributele divine şi astfel El este co-egal, co-etern şi de aceeaşi esenţă cu Tatăl (Ioan 1:1; 10:30; 14:9).`,
                    `În Naşterea Sa miraculoasă din fecioara Maria (Isaia 7:14; Matei 1:23,25; Luca 1:26-35), dar fără păcat.`,
                    `Isus Cristos reprezintă natura omenească şi cea divină într-o unitate indivizibilă (Mică 5:2; Ioan 5:23; 14:9-10; Col. 2:9).`,
                    `În întruparea Sa, Isus Cristos nu încetat niciodată să fie Dumnezeu, cu toate că S-a limitat în manifestarea atributelor Sale divine şi a luat chip de rob pentru a face voia Tatălui (Filipeni 2:5-8).`,
                    `Scopul întrupării Domnului Isus Cristos a fost acela de a-L revela pe Dumnezeu, (Ioan 1:14; 14:9) de a ridica păcatul lumii (Ioan 1:29; 1 Petru 1:18-20; 2 Cor. 5:19-21) şi de a instaura şi conduce Împărăţia lui Dumnezeu (Isaia 9:6-7).`,
                    `Prin lucrarea Să desăvârşită pe cruce, orice păcătos care crede în jertfă Sa răscumpărătoare este eliberat de pedeapsa, de consecinţa, de puterea şi într-o zi de însăşi prezenţa păcatului; el este declarat neprihănit (drept), primeşte viaţă veşnică şi este adoptat în familia lui Dumnezeu (Romani 3:24-26; 5:8-9; 1 Petru 2:23-24; 3:18).`,
                    `Îndreptăţirea noastră este asigurată prin învierea fizică a Domnului Isus Cristos; acum El stă la dreapta Tatălui şi mijloceşte ca Avocat (Apărător) şi Mare Preot pentru noi (Matei 28:6; Luca 24:38-39; Fapte 20:30-31; Romani 4:25; 8:34; Evrei 7:25-27).`,
                    `Prin învierea din morţi a Domnului Isus Cristos, Dumnezeu a confirmat dumnezeirea Fiului Sau şi prin aceasta a arătat că Dumnezeu a acceptat lucrarea de ispăşire a lui Cristos pe cruce; învierea în trup a Domnului Isus este de asemenea, garanţia unei învieri viitoare pentru toţi credincioşii (Ioan 5:26-29; 14:19; Romani 4:25; 6:5-10; 1 Cor. 15:20,23).`,
                    `Domnul Isus Cristos este Cel prin care Dumnezeu va judeca întreaga lume (Ioan 5:22-23): 
					a)	Credincioşii din Biserica Sa (1 Cor. 3:10-15; 2 Cor. 5:10; Romani 14:10-12). 
					b)	Cei în viaţă la revenirea Sa în slavă (Matei 25:31-46). 
					c)	Cei necredincioşi, la Marele Tron Alb (Apoc. 20:11-15).`,
                    `În calitate de Singur Mijlocitor între Dumnezeu şi om (1 Tim. 2:5), Cap al Bisericii care e trupul Său (Efeseni 1:22; 5:23; Col. 1:18) şi Împărat al Lumii, va domni pe tronul lui David (Isaia 9:6-7; Luca 1:31-33; 2 Samuel 7:12-16; Ezec. 37:24-28), El este Judecătorul unic al tuturor celor care nu au crezut în El şi nu L-au acceptat că Mântuitor şi Domn (Matei 25:14-46; Fapte 17:30-31; Apoc. 20:11-15).`
                ]
            },
            {
                title: `Dumnezeu Duhul Sfânt`,
                subtitle: `Noi credem și mărturisim că:`,
                paragraphs: [
                    `Duhul Sfânt, cea de a treia Persoană a Trinităţii, este co-egală, co-eternă şi de aceeaşi esenţă cu Dumnezeu Tatăl şi Dumnezeu Fiul (Ier. 31:31-34; Mat. 18:19; Fapte 5:3-4; 28:25-26; 1 Cor. 12:4-6; 2 Cor. 13:14; Evr. 10:5-17). Duhul Sfânt este numit Mângâietorul şi acest nume este folosit atât pentru Duhul Sfânt (Ioan 14:16,26; 15:26; 16:7), cât şi pentru Cristos (Ioan 14:16; 1 Ioan 2:1). Are cele trei elemente ale personalităţii: intelect (1 Cor. 2:11), sentimente (Romani 8:26-27; 15:30) şi voinţă (1 Cor. 12:11).`,
                    `Duhul Sfânt are atributele divinităţii. El este etern (Evr. 9:14), omniscient (1 Cor. 2:10-11; Ioan 14:26; 16:12-13), omiprezent (Ps. 139:7-10) şi omnipotent (Luca 1:35; Romani 15:13). El este implicat în creaţie (Gen. 1:2; Iov 33:4; Ps. 104:30), în întruparea Fiului lui Dumnezeu (Matei 1:18; Luca 1:35), în inspirarea Scripturilor (2 Petru 1:20-21; Fapte 1:16; 2 Tim. 3:16-17) şi în învierea morţilor (Romani 8:11).`,
                    `Lucrarea unică a Duhului Sfânt a început în Ziua Cinzecimii, când a luat naştere Trupul Domnului Isus (Biserica Sa) şi de atunci El continuă să edifice Biserica până la răpirea ei de către Domnul Isus Cristos. Lucrarea Sa este să dovedească lumea de păcat, de neprihănire şi de judecată (Ioan 16:8-11); să glorifice pe Domnul Isus Cristos, să producă naşterea din nou şi să boteze pe toţi credincioşii în Trupul lui Cristos, în momentul exercitării credinţei în lucrarea Domnului Isus Cristos (Ioan 3:5-8; 1 Cor. 12-13; Ioan 16:14).`,
                    `Duhul Sfânt locuieşte permanent în credincios (Ioan 14:16), face să apară roada Duhului (Gal. 5:22-23) şi transformă pe credincioşi asemenea chipului Domnului Isus Cristos (2 Cor. 3:18). Tot El pecetluieşte pentru ziua răscumpărării, învaţă, călăuzeşte în adevăr, sfinţeşte, cheamă la slujire, mijloceşte, vorbeşte, cercetează, ajută pe credincios şi-l îmbracă cu putere pentru trăirea creştină, pentru predicarea şi mărturisirea Evangheliei (Efeseni 1:13; Ioan 16:13; 14:26; Fapte 1:8; Romani 8:26-27; 2 Petru 1:19-21; 1 Ioan 2:20,27; Romani 8:9-11).`,
                    `Duhul Sfânt dă daruri spirituale Bisericii, nu pentru Slava Sa, ci pentru glorificarea lui Cristos, spre zidirea credincioşilor în credinţa dată sfinţilor odată pentru totdeauna (Ioan 16:14; Fapte 1:8; 1 Cor. 12:4-11; 2 Cor. 3:18; Iuda 3).`,
                    `Duhul Sfânt este absolut suveran în modul în care acordă daruri spirituale pentru zidirea Bisericii. De aceea, vorbirea în limbi, vindecările şi minunile au fost „daruri semne” la începutul formării Bisericii (înaintea apariţiei Noului Testament) pentru autentificarea lucrărilor apostolilor şi ele nu au intenţionat să fie o caracteristică a vieţii credincioşilor (1 Cor. 12:4-11; 1 Cor. 13:8-10; 2 Cor. 12:12; Efes. 4:7-12; Evr. 2:1-4; 1 Petru 4:10-11).`,
                    `El este numit în mod voit Dumnezeu (Fapte 5:3; 2 Cor. 3:17) şi toate aceste referiri dovedesc faptul că Duhul Sfânt nu e o influenţă sau putere divină, ci El este o persoană şi, la fel că Tatăl şi Fiul, este Dumnezeu.`
                ]
            }
        ]
    },
    man: {
        sections: [
            {
                subtitle: `Noi credem că:`,
                paragraphs: [
                    `Omul a fost creat printr-un act personal şi direct al lui Dumnezeu, după chipul Său. Omul a fost creat un suflet viu, fără păcat, cu conştiinţă, cu raţiune, sentiment, voinţă liberă şi responsabilitate morală faţă de Dumnezeu (Gen. 2:7,15-25; Iacov 3:9; Romani 1:18-19).`,
                    `Omul a fost creat pentru a aduce slavă lui Dumnezeu, pentru a avea părtăşie cu El, pentru a trăi o viaţă după voia lui Dumnezeu şi astfel să împlinească scopul lui Dumnezeu cu privire la om în lume (Isaia 43:7; Col. 1:16; Apoc. 4:11).`
                ]
            },
            {
                title: `Căderea omului în păcat`,
                subtitle: `Noi credem că:`,
                paragraphs: [
                    `În urma păcatului lui Adam, comis din cauza neascultării de voia revelată şi faţă de Cuvântul lui Dumnezeu, omul şi-a pierdut starea de nevinovăţie şi drept consecinţă a atras asupra sa moartea spiriuală şi fizică şi este sub pedeapsa mâniei lui Dumnezeu, devenind corupt, total depravat spiritual şi moral şi complet incapabil să aleagă sau să facă ceea ce este plăcut înaintea lui Dumnezeu, dacă nu intervine graţia divină (harul) (Gen. 2:15-17; 3:1-19).`,
                    `Omul, în sine însuşi, nu are potenţialul de a se mântui singur şi astfel este pierdut pentru totdeauna. Biblia arată limpede că omul nu-L caută din proprie iniţiativa pe Dumnezeu (Romani 3:11-18). El este rob al păcatului (Romani 6:17); pentru el lucrările spirituale sunt o nebunie (1 Cor. 1:18; 2:14); inima lui este nespus de înşelătoare şi de deznădăjduit de rea (Ieremia 17:9). De aceea omul nu se poate mântui singur, ci numai prin graţia (harul) lui Dumnezeu, prin actul suveran de răscumpărare a lui Dumnezeu, prin credinţă în jertfa ispăşitoare a Domului Isus Cristos (Ioan 3:36; 6:44; 14:6; Efeseni 2:1-3; 1 Timotei 2:13-14; 1 Ioan 1:8).`,
                    `Deoarece toţi oamenii au fost în Adam, natura adamică coruptă de păcat a fost transmisă tuturor oamenilor, cu excepţia Domnului Isus Cristos care S-a născut fără păcat (Evr. 4:15; 7:26; 2 Cor. 5:21; 1 Petru 2:22). Astfel, din cauza originii adamice, toţi oamenii sunt păcătoşi prin natură, prin alegere şi prin declaraţie divină (Ps. 14:1-3; 51:5; Ieremia 17:9; Romani 3:9-19,23; 5:10-12).`
                ]
            },
            {
                title: `Căsătoria`,
                subtitle: `Noi credem că:`,
                paragraphs: [
                    `Dumnezeu a instituit căsătoria şi astfel, cei căsătoriţi şi cei ce doresc să întemeieze o familie au obligaţia sacră să creeze o atmosferă plină de dragoste, de pace şi de îngăduinţă reciprocă pe toată perioada căsniciei, indiferent de circumstanţe. (Gen. 2:24). Căsnicia reprezintă o imagine palidă a unei realităţi spirituale copleşitoare – a legăturii veşnice dintre Cristos şi Mireasa Sa – Biserica (Efeseni 5:22-33).`,
                    `Dumnezeu urăşte divorţul (Maleahi 2:16) şi astfel, legătura în căsnicie poate fi întreruptă numai prin moartea unuia dintre parteneri (Matei 19:4-6). Chiar dacă divorţul apare atât în Vechiul cât şi în Noul Testament (Deut. 24:1-4; 1 Cor. 7:15), nu este încurajat niciodată, deoarece încalcă intenţia iniţială a lui Dumnezeu cu privire la familie (Gen. 2:24).`
                ]
            },
            {
                title: `Recăsătoria`,
                subtitle: `Noi credem că:`,
                paragraphs: [
                    `Cei văduvi se pot recăsători, dar numai în Domnul (1 Cor. 7:8-9,39).`,
                    `Cei care au divorţat înainte de întoarcerea la Dumnezeu şi rămân necăsătoriţi pot participa la Masa Domnului, la lucrarea publică în Biserică, cât şi la slujba de diaconie sau de prezbiter.`,
                    `Cei care au divorţat după momentul întoarcerii la Dumnezeu vor fi excluşi din biserică. Dacă divorţul a fost înaintat de partener şi credinciosul (care nu a dorit divorţul) rămâne necăsătorit, el poate participa la toate aspectele vieţii din biserică: Masa Domnului, mărturie personală şi lucrare publică, lucrarea de diaconie sau prezbiter, dacă rămâne necăsătorit. Dacă credinciosul care nu a cerut divorţul se recăsătoreşte, el va fi exclus din biserică (1 Cor. 7:15)`,
                    `Dacă o persoană a divorţat şi s-a recăsătorit înainte de întoarcerea la Dumnezeu, poate participa la Masă Domnului, poate da mărturie personală şi poate sluji ca diacon, dar nu va putea participa la lucrarea de vestire publică şi la slujba de prezbiter.`
                ]
            },
            {
                title: `Avortul, Homosexualitatea și Imoralitatea sexuală`,
                subtitle: `Noi credem că:`,
                paragraphs: [
                    `Viaţa începe în momentul concepţiei şi interzicem avortul (Ps. 139:13-16; Ier. 1:1-5).`,
                    `Orice formă de homosexualitate este o urăciune înaintea lui Dumnezeu (Lev. 18:22-23, 20:13-16; Romani 1:26-32).`,
                    `Curvia şi preacurvia, ca şi orice relaţie sexuală incestuoasă sunt păcate şi o urăciune înaintea lui Dumnezeu (Lev. 18:6-30; 20:10-21; 1 Cor. 6:15-20; 1 Tes. 4:3-6; Evrei 13:4).`
                ]
            }
        ]
    },
    justification: {
        sections: [
            {
                paragraphs: [
                    `Noi credem că salvarea este în totalitate prin graţia (harul) lui Dumnezeu, pe baza lucrării de răscumpărare a Domnului Isus Cristos, a sângelui Sau vărsat la Calvar şi nicidecum pe baza faptelor omului (Ioan 1:12; 14:6; Efes. 1:47; 2:8-10; 1 Petru 1:18-21).`
                ]
            },
            {
                title: `Alegerea`,
                subtitle: `Noi credem că:`,
                paragraphs: [
                    `Alegerea este actul suveran al lui Dumnezeu, prin care înainte de întemeierea lumii, El a ales în Cristos pe cei pe care îi regenerează, îi salvează (mântuieşte) şi-i sfinţeşte după bună plăcere a voii Sale (Romani 8:28-30; Efes. 1:4-12; 2 Tes. 2:13; 2 Tim. 2:10; 1 Petru 1:1-2).`,
                    `Alegerea suverană nu contrazice sau anulează responsabilitatea omului de a se pocăi, de a crede şi de a-L primi pe Domul Isus Cristos ca Salvator şi Domn personal în inima sa (Ezec. 18:23,32; 33:8-11; Ioan 1:11-13; 3:16-19,36; 5:37-40; 14:6; 20:31; Romani 10:9-17; 2 Tes. 2:9-12; Apoc. 22:17).`,
                    `Dumnezeu „doreşte ca toţi oamenii să fie mântuiţi şi să vină la cunoştinţa adevărului”, deci nu poate fi vorba de o dublă alegere: una spre viaţă veşnică şi altă spre moarte veşnică (1 Tim. 2:3-7; 2 Petru 3:9; Romani 8:28-30; 9:6-25).`,
                    `Alegerea lui Dumnezeu nu se bazează numai pe o suveranitate abstractă, ci este în armonie cu atributele Sale: omnisciența, dreptatea, sfinţenia, înţelepciunea, graţia (harul) şi iubirea Sa (Ioan 3:16; 20:31; Romani 9:11-16). Toţi cei pe care Tatăl îi cheamă la Sine vor veni cu credinţă şi se vor bucura de viaţă veşnică (Efeseni 1:4-7; Tit 3:4-7; 1 Petru 1:2-5). Această suveranitate va glorifica veşnic voia lui Dumnezeu pentru că este în armonie desăvârşită cu caracterul Sau aşa cum s-a revelat în viaţa Domnului nostru Isus Cristos (Ioan 14:6-14; 2 Timotei 1:9-10).`,
                    `Alegerea suverană a lui Dumnezeu nu anulează responsabilitatea credincioşilor de a duce mesajul vieţii la cei pierduţi, ca prin credinţă în jertfă ispăşitoare a Domnului Isus să se bucure de viaţă veşnică, iar pentru cei care-L resping pe Domnul Isus să fie că o mărturie împotriva lor (Rom. 10:9-17; 2 Cor. 4:1-6; Fapte 16:29-34; 20:24; Matei 28:18-20). Cea mai mare poruncă pentru un credincios adevărat (născut din nou) este să ducă Evanghelia Domnului Isus Cristos la cei care zac în întuneric. (Matei 28:18-20; Fapte 1:8).`
                ]
            },
            {
                title: `Nașterea din nou `,
                subtitle: `Noi credem că:`,
                paragraphs: [
                    `Naşterea din nou este lucrarea supranaturală a Spiritului Sfânt prin care credinciosul primeşte viaţă şi natură divină (Ioan 3:3-7; Tit 3:5; 1 Petru 1:23). Acest miracol al naşterii din nou are loc instantaneu, în momentul în care păcătosul, prin puterea Spiritului Sfânt şi Cuvântul lui Dumnezeu (Ioan 5:24; Iacov 1:18; Tit 3:5), crede în jertfa înlocuitoare a Domnului Isus Cristos (Romani 10: 9-17) ca singura cale de mântuire şi astfel trece din moarte la viaţă.`,
                    `Naşterea din nou are drept rezultat o schimbare radicală a persoanei respective (2 Cor. 5:17-21) şi roadele bune sunt o consecinţă a naşterii din Dumnezeu (Ioan 1:12-13; Efeseni 2:8-10). Trupul credinciosului devine un templu al Spiritului Sfânt şi roada Spiritului este consecinţa prezenţei Lui în credincios, care lucrează în el asemănarea cu Domnul Isus Cristos (1 Cor. 6:19-20; Gal. 5:22-25; 2 Cor. 3:18). La venirea Domnului Isus, credinciosul va ajunge la desăvârşire, pentru că va fi că El (1 Ioan 3:1-3; Romani 8:16-17; 2 Petru 1:4).`
                ]
            },
            {
                title: `Îndreptățirea`,
                paragraphs: [
                    `Noi credem că îndreptăţirea este un act al lui Dumnezeu (Romani 3:24-30; 8:33-39), prin care El îi declară drepţi pe toţi cei care, prin credinţa în jertfa Domnului Isus Cristos, se pocăiesc de păcatele lor (Isaia 55:6-7; Luca 13:3; Fapte 2:38-39; 3:19; 11:18; Romani 2:4; 2 Cor. 7-10) şi-L primesc pe Domnul Isus Cristos ca Domn şi Mântuitor (Ioan 1:11-13; Romani 10:9-10; 1 Cor. 12:3; 2 Cor. 4:5; Filipeni 2:11).`,
                    `Această îndreptăţire (justificare) pe care o dă Dumnezeu nu depinde de faptele sau virtuţile oamenilor (Romani 3:10,20-30; 4:6), ci constă în faptul că Dumnezeu trece (impută) păcatele noastre în contul Domnului Isus Cristos (Col. 2:14-15; 1 Petru 2:22-24) şi trece în contul nostru dreptatea (neprihănirea) lui Cristos (1 Cor. 1:2,30-31; 6:11; 2 Cor. 5:18-21). Numai în felul acesta Dumnezeu poate „să-Şi arate dreptatea (neprihănirea) Lui în aşa fel încât, să fie drept şi totuşi să socotească îndreptăţit pe cel ce crede în Isus” (Romani 3:26).`
                ]
            },
            {
                title: `Sfințirea`,
                subtitle: `Noi credem că:`,
                paragraphs: [
                    `Fiecare credincios, în momentul naşterii din nou este declarat sfânt (pus deoparte) pentru Dumnezeu. Acest act are loc instantaneu, are în vedere poziţia credinciosului faţă de Dumnezeu şi nu trebuie confundat cu sfinţirea progresivă care are în vedere umblarea credinciosului (Fapte 20:32; 1 Cor. 1:2,30; 2 Tes. 2:13; Evrei 2:11; 3:1; 10:10,14; 13:12; 1 Petru 1:2).`,
                    `Aşa cum s-a arătat mai sus, pe lângă sfinţirea pozițională, Duhul Sfânt lucrează în viaţa credinciosului şi o sfinţire progresivă, care-l împuterniceşte pe credincios prin ascultarea de Cuvântul lui Dumnezeu, să umble într-o sfinţenie mereu crescândă şi, astfel, să crească tot mai mult în asemănarea cu Dumnul nostru Isus Cristos (Ioan 17:17,19; Romani 6:1-22; 8:23; 2 Cor. 3:18; 1 Tes. 4:3-4; 5:23).`,
                    `În procesul sfinţirii progresive a umblării (zilnice) cu Cristos este o luptă, ca urmare a faptului că „firea pământească pofteşte împotriva Duhului şi Duhul împotriva firii pământeşti” (Galateni 5:17), dar noua creaţie în Cristos este biruitoare prin puterea dată de prezenţa Spiritului Sfânt, a cărui roadă ţine sub ascultare firea veche. Acest conflict durează tot timpul vieţii credinciosului şi numai la venirea Domnului Isus Cristos, când va avea loc glorificarea credinciosului, va scăpa de prezenţa păcatului (Gal. 5:16-25; Efes. 4:22-24; 6:12-17; Col. 3:9-10; 1 Petru 1:14-16; 2 Petru 3:11-12; 1 Ioan 3:5-9).`
                ]
            },
            {
                title: `Siguranța salvării (Mântuirii)`,
                subtitle: `Noi credem că:`,
                paragraphs: [
                    `Toţi credincioşii adevăraţi, adică cei născuţi din nou, se bucură de siguranţa salvării (mântuirii). Toţi cei născuţi cu adevărat din nou sunt păstraţi şi păziţi de prezenţa, promisiunea şi puterea lui Dumnezeu pentru totdeauna în Cristos (Ioan 10:27-30; Romani 8:31-39; Filip. 1:6; 2:13; 1 Petru 1:5; Iuda 24).`,
                    `Este privilegiul credincioşilor să se bucure de siguranţa salvării asigurată de mărturia Cuvântului lui Dumnezeu. Acest mare privilegiu implică şi o mare responsabilitate din partea credinciosului adevărat care trebuie să arate în mod practic realitatea salvării (Romani 6:1-23; 13:13-14; Gal. 5:13-26; Filip. 2:12; Tit 2:11-14).`,
                    `Cei născuţi cu adevărat din nou trăiesc cu speranţa (siguranţa) revenirii Domnului Isus Cristos, când adopţiunea lor va fi desăvârşită şi vor primi trupuri de slavă – ceea ce se numeşte în Scriptură răscumpărarea trupului credincioşilor. Duhul şi sufletul credincioşilor au fost răscumpărate deja, iar trupurile lor vor fi răscumpărate cu ocazia Răpirii (Romani 8:22-24; Tit 2:13; 1 Tes. 4:13-18; 1 Ioan 3:1-3). Pentru Dumnezeu, care trăieşte în afara timpului, mântuirea noastră este un lucru deja realizat. Cei credincioşi cu adevărat sunt deja „în locurile cereşti, în Cristos” (Efeseni 1:3-4; 2:6).`,
                    `Mântuirea începe odată cu naşterea din nou, continuă apoi toată viaţa şi-şi trăieşte împlinirea finală în clipa în care cel credincios este strămutat deplin din lumea păcatului în împărăţia neprihănirii.`,
                    `Cei credincioşi trăiesc „aşteptând fericita noastră speranţă şi arătarea marelui nostru Dumnezeu şi Mântuitor, Isus Cristos (Tit 2:13). El va schimba trupul stării noastre (Filip. 3:21)”.`
                ]
            },
            {
                title: `Separarea`,
                subtitle: `Noi credem că:`,
                paragraphs: [
                    `Despărţirea de păcat este o cerinţă capitală, atât în Vechiul cât şi în Noul Testament pentru credincios, care este şi atenţionat că în zilele din urmă, apostazia şi Duhul veacului vor creşte ameninţător (Romani 12:1-2; 2 Cor. 4:1-6; 6:14-7:1; Col. 2:8; 1 Tim. 4:1; 2 Tim. 4:1-4).`,
                    `Datorită iubirii nemărginite a lui Dumnezeu pentru o lume vrednică de moarte, arătată prin jertfa Domnului nostru Isus Cristos, toţi cei salvaţi din focul veşnic ar trebui să trăiască în aşa fel încât să aducă slavă, glorie şi închinare adevărată Celui care este singurul vrednic să le primească. Credem că Dumnezeu porunceşte indubitabil credincioşilor să se separe de orice asociere cu apostazia religioasă sau cu practicile lumeşti care n-au nimic comun cu adevărul (Romani 12:1-2; 1 Cor. 5:9-13; 1 Ioan 2:15-17; 2 Ioan 9-11).`,
                    `Viaţa creştină adevărată trebuie trăită în conformitate cu standardul divin şi revelată printr-o atitudine de dragoste, pace, bucurie şi dorinţă reală pentru o continuă sfinţire. Credincioşii trebuie, prin viaţa lor, să fie o adevărată lumină şi sare pentru lumea aceasta şi mulţi să ajungă să-L cunoască pe Dumnezeu prin ei. Cea mai mare dorinţă pentru credincioşi trebuie să fie grija pentru a aduce slavă, glorie şi cinste Celui Atotputernic, prin creşterea numărului celor salvaţi (mântuiţi). (2 Tes. 1:11-12; Evr. 12:1-2; Matei 5:2-12; Romani 12:1-2; Evrei 12:14; Tit 2:11-14; 1 Ioan 3:1-10).`
                ]
            }
        ]
    },
    church: {
        sections: [
            {
                subtitle: `Noi credem:`,
                paragraphs: [
                    `Că toţi cei născuţi cu adevărat din nou prin credinţa în Domnul Isus Cristos – în jertfa răscumpărătoare – formează Biserica, adică Trupul Său (1 Cor. 12:12-13; Efeseni 1:22-23), care este Mireasa lui Cristos (2 Cor. 11:2; Efes. 5:23-32; Apoc. 19:7-8), al cărei Cap este Cristos (Efes. 1:22; 4:15; Col. 1:18).`,
                    `Că actul de naştere al Bisericii s-a semnat în Ziua Cinzecimii (Fapte 2:1-21,38-47) şi ea continuă să crească până la răpirea ei de către Domnul Isus Cristos (1 Cor. 15:51-52; 1 Tes. 4:13-18).`,
                    `Că Biserica Universală este un organism spiritual unic creat de Cristos şi este alcătuită din credincioşi adevăraţi din toate neamurile, din Ziua Cinzecimii până la Răpire (Efes. 2:11-3:7; 5:32). Biserica este distinctă de Israel (1 Cor. 10:32), o taină ce nu a fost revelată până în dispensația harului (Efeseni 3:17; 5:32).`,
                    `Că Biserica Universală (Matei 16:18) – Trupul lui Cristos – este reprezentată (în parte) de bisericile locale care reprezintă totalitatea oamenilor născuţi din nou prin credinţa în jertfa răscumpărătoare a Domnului Isus Cristos (Fapte 14:23,27; 20:17,28; Gal. 1:2; Filip. 1:1; 1 Tes. 1:1; 2 Tes. 1:1).`,
                    `Că singură autoritate supremă în Biserică este Cristos, care este Capul ei (Efes. 1:22-23; Col. 1:18) şi conducerea, ordinea, disciplina, darurile şi închinarea sunt rânduite toate prin suveranitatea Sa, aşa cum o prezintă Scriptura. Lucrătorii (slujitorii) din Biserică aleşi de Duhul Sfânt (Fapte 20:28) sunt prezbiterii (care se mai numesc episcopi, păstori şi păstori-învăţători; Fapte 20:29; Efes. 4:11) şi diaconii care, pentru a fi aleşi în aceste lucrări, trebuie să îndeplinească condiţiile cerute de Scriptură (1 Tim. 3:1-12; Tit 1:5-9; 1 Petru 5:1-5). Odată aleşi, aceşti slujitori au obligaţia să conducă Biserica ca nişte servi ai lui Cristos, având autoritate în îndrumarea Bisericii, iar adunarea trebuie să se supună conducerii lor (1 Tim. 5:17-22; Evr. 13:7,17).`,
                    `În obligaţia Bisericii de a face discipoli (Matei 28:18-20; 2 Tim. 2:2), în responsabilitatea reciprocă a tuturor credincioşilor unul faţă de altul (Matei 18:15-20) şi în nevoia de disciplină pentru membrii din adunare care păcătuiesc, conform cu Scriptura (Matei 18:15-22; Fapte 5:1-11; 1 Cor. 5:1-13; 2 Tes. 3:6-15; 1 Tim. 1:18-20; Tit 1:10-16).`,
                    `În autonomia Bisericii locale, scutită de orice autoritate sau control exterior, cu dreptul autoorganizării şi independenţei faţă de intervenţia oricărei ierarhii de indivizi sau organizaţii (Tit 1:5). Noi credem că este scriptural ca Bisericile adevărate să coopereze între ele pentru răspândirea evangheliei în lume, dar această colaborare să fie decisă numai de Bisericile în cauză (Fapte 15:19-31; 20:28; 1 Cor. 5:4-7,13; 1 Petru 5:1-4).`,
                    `Că scopul principal al Bisericii este să aducă slavă, glorie şi închinare lui Dumnezeu (Efeseni 3:21); să rezidească în credinţă (Efeseni 4:11-16) prin Cuvânt (2 Tim. 2:2,15; 3:16-17), prin comuniune (părtăşie) (Fapte 2:42-47; 1 Ioan 1:3), prin împlinirea poruncilor (Ioan 13:24-35) şi prin transmiterea Evangheliei întregii lumi (Matei 28:18-20; Fapte 1:8)`,
                    `Că Dumnezeu dă daruri spirituale Bisericii. Mai întâi, El dă bărbaţi în scopul echipării şi zidirii sfinţilor pentru lucrare (Efes. 4:7-12) şi dă, de asemenea, şi capacităţi spirituale unice şi speciale fiecărui mădular din Trupul lui Cristos (Romani 12:5-8; 1 Cor. 12:4-31; 1 Petru 4:10-11).`,
                    `Că au existat două feluri de daruri date Bisericii primare: daruri semne (minuni, vindecări şi vorbirea în limbi) date temporar în perioada apostolică în scopul confirmării autenticităţii mesajului apostolilor (Evr. 2:3-4; 2 Cor. 12:12) şi daruri pentru slujirea altora, date pentru zidirea credincioşilor în vederea edificării reciproce. Acum, când revelaţia dată în Noul Testament este completă, Scriptura reprezintă singurul test al autenticităţii cuiva şi darurile miraculoase (semne) nu mai sunt necesare că să valideze un om sau mesajul lui (1 Cor. 13:8-12). Singurele daruri care operează astăzi sunt cele date în vederea zidirii şi edificării sfinţilor (Romani 12:6-8).`,
                    `Că nimeni nu mai posedă darul de vindecare, dar Dumnezeu este suveran şi poate să răspundă rugăciunilor celor bolnavi, suferinzi şi întristaţi, potrivit cu voia Sa suverană. (Luca 18:1-8; Ioan 5:7-9; 2 Cor. 12:6-10; 1 Ioan 5:14-15), rugăciunea făcută cu credinţă poate să vindece şi astăzi (Iacov 5:13-16).`,
                    `Că Bisericii locale i-au fost încredinţate două practici ceremoniale: botezul şi Cina Domnului (Matei 28:19; Fapte 2:38-42). Botezul creştin prin cufundare (Fapte 8:36-39) este mărturia unui cuget curat (1 Petru 3:21) care-şi proclamă credinţa în Salvatorul crucificat, înmormântat şi înviat, şi unirea cu El în moarte faţă de păcat şi înviere spre o viaţă nouă (Romani 6:1-11). El mai este un semn al comuniunii şi identificării cu Trupul vizibil al lui Cristos (Fapte 2:41-42).`,
                    `Că Cina Domnului este comemorarea şi proclamarea morţii şi învierii Lui până ce va veni El. Fiind cel mai înălţător moment din serviciul divin din cadrul adunării, fiecare credincios trebuie să se cerceteze în mod serios înainte de a participa la acest act de cult (1 Cor. 11:23-32). Credem că, deşi pâinea şi vinul simbolizează doar trupul şi sângele Domnului Isus Cristos, Cina Domnului e comuniune reală cu Cristos cel înviat şi cu copiii Săi, arătând prin aceasta că suntem una în Cristos (1 Cor. 10:16-17).`
                ]
            }
        ]
    },
    angels: {
        sections: [
            {
                title: `Îngerii sfinți`,
                subtitle: `Noi credem că:`,
                paragraphs: [
                    `Îngerii sunt fiinţe spirituale create de Dumnezeu şi de aceea nu trebuie să li se aducă închinare (Col 1:16; Ps 148: 2-5; Apoc 5:11-14; 19:10; 22:9). Deşi îngerii sunt o categorie de fiinţe superioare omului, ei au fost creaţi că să-L servească pe Dumnezeu şi să îi aducă închinare (Evr 1:7,14; Isaia 6:1-3; Apoc 5:11-14).`
                ]
            },
            {
                title: `Îngerii căzuți`,
                subtitle: `Noi credem că:`,
                paragraphs: [
                    `Satan este un înger creat şi autorul păcatului. El a atras asupra sa judecata lui Dumnezeu în urmă răzvrătirii împotriva Creatorului Său (Is. 14:12-17; Ezec 28: 11-19) şi a atras după sine o treime de îngeri (Matei 25:41; Apoc. 12:1-14) şi a adus păcatul în lume prin ispitirea Evei (Geneza 3:1-5).`,
                    `Satan este adversar declarat al lui Dumnezeu şi al omului (Isaia 14:13-14; Matei 4:1-11; 2 Cor. 4:1-4; Apoc. 12:9-10), dumnezeul veacului acesta care a fost învins prin moartea şi învierea lui Isus Cristos (Romani 16:20; Col. 2:15) şi care va fi pedepsit împreună cu îngerii lui pentru totdeauna în iazul de foc (Isaia 14:12-17; Ezec. 28:11-19; Matei 25:41; Apoc. 20:10).`
                ]
            }
        ]
    },
    lastThings: {
        sections: [
            {
                title: `Moartea`,
                subtitle: `Noi credem:`,
                paragraphs: [
                    `Că moartea fizică nu înseamnă o pierdere a conştiinţei noastre imateriale (Apoc. 6:9-11). Credinciosul adevărat trece imediat în prezenţa lui Cristos când are loc despărţirea duhului de trup (Iacov 2:26; 2 Cor. 5:8; Filip 1:23;) şi această separare pentru ce răscumpăraţi va continua până la Răpire (1 Tes. 4: 13-18), care inaugurează prima înviere (Apoc. 20:4-6), când sufletul şi trupul credinciosului se vor uni din nou pentru a fi glorificate pentru totdeauna (1 Cor. 15:35-44, 50-54; Filip. 3:21). Până atunci, sufletele celor răscumpăraţi în Cristos rămân într-o comuniune fericită cu Domnul nostru Isus Cristos (2 Cor. 5:8).`,
                    `În învierea fizică a tuturor oamenilor, cei mântuiţi pentru viaţa eternă (Ioan 6:39; Romani 10-11; 19-23; 2 Cor. 4:14), iar cei nemântuiți pentru judecată şi chin veşnic (Daniel 12:2; Ioan 5:29; Apoc. 20:11-15).`,
                    `Că sufletele celor care au murit nemântuiți, sunt ţinute sunb pedeapsă până la a două venire (Luca 16:19-26; Apoc. 20: 13-15), când sufletul se va uni cu trupul înviat (Ioan 5:28-29) şi vor fi judecaţi la Marele Tron Alb (Apoc. 20:11-15) şi vor fi aruncaţi în Iad, iazul de foc (Matei 25:41-46) unde vor avea parte de un chin veşnic conştient (Daniel 12:2; Matei 25:41-46; 2 Tes. 1:7-9; Apoc. 14:9-11; 20:10).`
                ]
            },
            {
                title: `Răpirea Bisericii`,
                paragraphs: [
                    `Noi credem că Domnul Isus Cristos va reveni personal, înaintea Marelui Necaz, ca să-şi răpească Biserica (Ioan 14:1-3; 1 Cor. 15:51-53; 1 Tes. 4:13-18; Tit 2:13). Între răpire şi revenirea Sa în slavă cu îngerii şi Sfinţii Săi, în cer va avea loc răsplătirea credincioşilor, în funcţie de lucrarea pe care au făcut-o pe pământ (1 Cor. 3:11-15; 2 Cor. 5:10).`
                ]
            },
            {
                title: `Necazul cel Mare`,
                paragraphs: [
                    `Noi credem că după ce Biserica va fi răpită (Ioan 14:1-3; 1 Cor. 15:51-53; 1 Tes. 4:13-18; Tit 2:13), judecata dreaptă a lui Dumnezeu se va abate asupra unei lumi necredincioase şi idolatre (Ier. 30:7; Dan. 9:27; 12:1; 2 Tes. 2:7-12; Apoc. 16). Revenirea în slavă a Domnului Isus Cristos pe pământ va pune capăt fărădelegii şi nedreptăţii (Matei 27:31; 25:31-46; Zah. 12:10, 14:1-5). La sfârşitul Necazului cel Mare sfinţii Vechiului Testament şi cei din perioada Marelui Necaz vor învia, iar cei vii vor fi judecaţi (Daniel 12:2-3; Apoc. 20:4-6). Această perioadă de şapte ani este cea de-a şaptezecea săptămână din profeţia lui Daniel (Dan. 9:24-27, Matei 24:15-31, 25:31-46).`
                ]
            },
            {
                title: `A doua venire a Domnului și Mileniul`,
                subtitle: `Noi credem că:`,
                paragraphs: [
                    `După Marele Necaz, Cristos va veni pe pământ că să ia în primire scaunul de domnie al lui David (Matei 25:31; Luca 1:32-33; Fapte 1:10-11, 2:29-30) şi să-şi întemeieze Împărăţia de o mie de ani pe pământ (Apoc. 20:1-7). În această perioadă sfinţii care au parte de întâia înviere (Apoc. 20:5-6) vor domni cu Cristos peste Israel şi peste toate naţiunile de pe pământ (Ez. 37:21-28; Dan. 7:17-22; Apoc. 19:11-16). Această domnie va fi precedată de aruncarea Anticristului şi proorocului mincinos în iazul de foc şi de legarea lui Satan în adânc pentru o mie de ani (Apoc. 19:20; 20:1-3; Dan. 7:17-27).`,
                    `Împărăţia de o mie de ani este împlinirea promisiunii lui Dumnezeu faţă de Israel (Isaia 65:17-25; Ez. 37:21-28; Zah. 8:1-17) pentru a restabili pe evrei în ţara pe care au pierdut-o din cauza neascultării faţă de Dumnezeu (Deut. 28:15-68; Lev. 26:14-46). Din cauza neascultării, Dumnezeu a pus deoparte Israelul pentru o vreme (Matei 21:43; Romani 11:1-26), dar într-o zi Israelul se va pocăi şi va putea intra în ţara binecuvântată (Ier. 31:31-34; Ez. 36:22-32; Romani 11:25-29).`,
                    `În această perioadă de o mie de ani va domni pacea, dreptatea şi dragostea în armonie cu voia lui Dumnezeu (Isaia 11; 65:17-25; Ez. 33-38) şi se va incheia când Satan va fi dezlegat că să înșele lumea (Apoc. 20:7).`
                ]
            },
            {
                title: `Judecata celor pierduți`,
                subtitle: `Noi credem că:`,
                paragraphs: [
                    `După Împărăţia de o mie de ani, Satan va fi eliberat temporar şi va înşela naţiunile pamântului şi le va uni pentru a lupta împotriva sfinţilor şi a oraşului iubit, dar un foc din cer le va mistui (Apoc. 20:9). Apoi Satan va fi aurncat în iazul de foc (Apoc. 20:10; Matei 25:41), iar Cristos care este şi Judecătorul lumii (Ioan 5:22; Fapte 17:31) va învia pe toţi cei al căror nume nu este scris în cartea vieţii, şi-i va judeca la Marele Tron Alb (Apoc. 20:11-15).`,
                    `Învierea pentru judecată a celor pierduți va fi o înviere fizică. După judecată (Ioan 5:28-29), vor fi condamnaţi la un chin veşnic conștient în lacul de foc (Matei 25:41; Apoc. 20:10-15).`
                ]
            },
            {
                title: `Eternitatatea`,
                paragraphs: [
                    `Noi credem că după terminarea Mileniului, după eliberarea temporară a lui Satan şi judecarea celor necredincioşi (2 Tes. 1:9; Apoc. 20:7-15), cei salvaţi (mântuiţi) vor intra în starea eternă a slavei lui Dumnezeu, iar pământul şi cerul vor fi înlocuite cu un cer nou și un pământ nou (2 Petru 3:10; Apoc. 21:1), unde va locui doar dreptatea. Apoi, din cer va coborî oraşul ceresc (Apoc. 21:2) care va fi locuinţa Sfinţilor, unde se vor bucura etern de părtășia cu Dumnezeu şi unul cu altul (Ioan 17:3; Apoc. 21-22). În final, Domnul nostru Isus Cristos îndeplinindu-şi lucrarea de răscumpărare, va da Împărăţia în mâinile lui Dumnezeu Tatăl (1 Cor. 14:24-28) şi, astfel, Dumnezeu triunic va fi totul în toţi (1 Cor. 15:28).`
                ]
            }
        ]
    }
};

export default vision;
