const missionData = {
  items: [
    {
      title: 'Grupurile Vestea Bună',
      photoURL: 'https://i.imgur.com/lNFRCmK.jpg',
      sections: [
        {
          title: '',
          paragraphs: [
            `Grupurile Vestea Bună sunt organizate săptămânal, de obicei acasă la cineva, şi sunt invitaţi copiii din satul respectiv.`,
            `Aceștia au în general vârsta cuprinsă între 4 şi 12 ani, şi vin pentru a li se preda o lecţie biblică, a cânta, a învăţa versete biblice, a asculta povestiri misionare şi a concura la jocuri recapitulative.`,
            `Uneori sunt copii care la aceste grupuri cred în Domnul Isus, şi sunt mântuiţi. În acest caz, Grupul Vestea Bună le oferă o posibilitate excelentă de a creşte în credinţă.`,
            `Copiii sunt încurajaţi să participe la serviciile unei biserici locale, care are ca bază Biblia, şi acolo pot să continue să crească în credinţă şi în cunoaşterea Domnului Isus Hristos.`
          ]
        },
        {
          title: 'Unde?',
          paragraphs: [
            `Avem Grupuri Vestea Bună în localităţile: Popricani, Moreni, Scânteia, Dancu, Valea Lupului şi Vânători.`
          ]
        },
        {
          title: 'Implică-te și tu',
          paragraphs: [
            `O parte din tinerii bisericii se implică la aceste grupuri săptămânal, iar o parte doar ocazional. Ne rugăm ca Dumnezeu să scoată şi alţi tineri care să dorească să slujească şi astfel în fiecare săptămână nevoia de învăţători să fie suplinită. În unele cazuri avem nevoie de maşină pentru a facilita învăţătorii să ajungă la grup.`
          ]
        }
      ],
      workers: {
        names: 'Daniel și Carmen Leonte',
        photoURL: 'https://i.imgur.com/ir6B0IT.jpg',
        subtitles: ['Membri ai bisericii Vestea Bună', 'Lucrători AMEC'],
        infoURL:
          'https://ameccef.com/echipa/lucratori-locali#tab-9ae62a1acc1ca7162d1'
      },
      map: {
        zoomLevel: 9,
        markers: [
          ['Popricani', 47.2885, 27.512, 1],
          ['Moreni', 47.0999, 27.86, 2],
          ['Scanteia', 46.9167, 27.5667, 3],
          ['Dancu', 47.1562, 27.6656, 4],
          ['Valea Lupului', 47.1801, 27.5082, 5],
          ['Vanatori', 47.242986, 27.524648, 6],
          ['Biserica Vestea Buna Iasi', 47.173894, 27.567452, 7]
        ],
        center: {
          lat: 47.1177424,
          lng: 27.709171
        }
      }
    }
  ]
};

export default missionData;
