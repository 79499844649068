import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import axios from 'axios';
import classNames from 'classnames';
import Cookies from 'universal-cookie';

import AdminLive from '../admin-live/AdminLive';
import AdminLogin from 'ui/admin/admin-login/AdminLogin';
import AdminHeader from 'ui/admin/admin-header/AdminHeader';
import AdminEvents from 'ui/admin/admin-events/AdminEvents';
import AdminMessages from 'ui/admin/admin-messages/AdminMessages';
import AdminAnnouncements from 'ui/admin/admin-announcements/AdminAnnouncements';
import AdminPastors from 'ui/admin/admin-pastors/AdminPastors';
import AdminSchedule from 'ui/admin/admin-schedule/AdminSchedule';
import AdminContact from 'ui/admin/admin-contact/AdminContact';
import constants from 'ui/constants';

import './admin.scss';

const $ = window.$;

const cookies = new Cookies();

class Admin extends Component {
  _isMounted = false;

  constructor() {
    super();

    this.state = {
      isLoggedIn: false,
      authenticationChecked: false
    };

    this.largeBackgroundImage = 'https://i.imgur.com/YxhnO2q.jpg';
    this.normalBackgroundImage = 'https://i.imgur.com/YxhnO2q.jpg';

    this.style = {
      backgroundImage:
        $(window).width() <= 859
          ? `url(${this.normalBackgroundImage})`
          : `url(${this.largeBackgroundImage})`
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.checkIfLoggedIn();
    document.title = 'Admin – Biserica Vestea Bună Iași';
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  updateLoggedInState = isLoggedIn => {
    if (this._isMounted) {
      if (isLoggedIn) {
        axios.defaults.headers.common['x-access-token'] = cookies.get('token');
      }
      this.setState({ isLoggedIn, authenticationChecked: true });
    }
  };

  checkIfLoggedIn = () => {
    axios({
      method: 'get',
      url: `${constants.backendURL}authentication/profile`,
      headers: {
        'Content-Type': 'x-www-form-urlencoded',
        'x-access-token': cookies.get('token')
      }
    })
      .then(response => {
        if (response.data.exp) {
          this.updateLoggedInState(true);
        } else {
          this.updateLoggedInState(false);
        }
      })
      .catch(() => this.updateLoggedInState(false));
  };

  logout = () => {
    cookies.remove('token');
    this.updateLoggedInState(false);
  };

  render() {
    if (!this.state.authenticationChecked) {
      return <div className="admin-empty" />;
    } else if (this.state.isLoggedIn) {
      return (
        <div className="admin-wrapper">
          <AdminHeader onLogout={this.logout} />
          {this.props.location.pathname === '/admin' && (
            <div className="admin-home" style={this.style}>
              <h3>Bine ai venit!</h3>
              <p>Selectează o secțiune din meniu.</p>
            </div>
          )}
          <div
            className={classNames('admin-content', {
              hidden: this.props.location.pathname === '/admin'
            })}
          >
            <Switch>
              <Route path="/admin/program" component={AdminSchedule} />
              <Route path="/admin/evenimente" component={AdminEvents} />
              <Route path="/admin/mesaje" component={AdminMessages} />
              <Route path="/admin/live" component={AdminLive} />
              <Route path="/admin/anunturi" component={AdminAnnouncements} />
              <Route path="/admin/prezbiteri" component={AdminPastors} />
              <Route path="/admin/contact" component={AdminContact} />
            </Switch>
          </div>
        </div>
      );
    } else {
      return (
        <AdminLogin
          onSuccessfulLogin={this.updateLoggedInState.bind(this, true)}
        />
      );
    }
  }
}

export default Admin;
