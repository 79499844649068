import React from 'react';

import { TableRow, Checkbox } from '@material-ui/core';

import CustomTableCell from './CustomTableCell';

const CustomTableHeader = props => {
  const { rowCount, selectedCount, selectAll, editMode } = props;

  return (
    <TableRow>
      {editMode && (
        <CustomTableCell padding="checkbox" center={true}>
          <Checkbox
            indeterminate={selectedCount > 0 && selectedCount < rowCount}
            style={{ fontSize: 20 }}
            checked={selectedCount === rowCount}
            onChange={selectAll}
          />
        </CustomTableCell>
      )}
      {props.cells.map((cell, key) => {
        return (
          <CustomTableCell key={key} padding={cell.padding} center={cell.center}>
            {cell.name}
          </CustomTableCell>
        );
      })}
    </TableRow>
  );
};

export default CustomTableHeader;
