import React from 'react';

import './diagram.scss';

class Diagram extends React.Component {
  render() {
    const getSectionImages = () => {
      return this.props.images.map((image, key) => <img src={image} key={key} alt="" />);
    };
    return <div className="diagram">{getSectionImages()}</div>;
  }
}

export default Diagram;
