import React, { Component } from 'react';
import { Paper, Table, TableHead, TableBody, Toolbar, Typography, IconButton } from '@material-ui/core';
import { Delete, Add, Edit, Check } from '@material-ui/icons';
import classNames from 'classnames';
import axios from 'axios';

import ItemDetailsDialog from './ItemDetailsDialog';
import CustomTableRow from './CustomTableRow';
import CustomTableHeader from './CustomTableHeader';

import './custom-table.scss';

class CustomTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      dialogOpen: false,
      editMode: false,
      selectedCount: 0,
      dialogItem: {},
      dialogType: 'create'
    };

    this.getItems();
  }

  setItems = response => {
    return response.json().then(items => this.setState({ items }));
  };

  getItems = () => {
    return fetch(this.props.backendURL, { method: 'GET' })
      .then(this.setItems)
      .catch(this.onError);
  };

  removeItems = () => {
    const itemsToBeDeleted = this.state.items.filter(item => item.isSelected);
    const removeItemsFromState = () => {
      return this.setState({
        items: this.state.items.filter(
          item => !itemsToBeDeleted.find(it => it[this.props.identifier] === item[this.props.identifier])
        ),
        selectedCount: this.state.selectedCount - itemsToBeDeleted.length
      });
    };

    Promise.all(itemsToBeDeleted.map(item => this.removeItem(item)))
      .then(removeItemsFromState)
      .then(() => this.props.displaySnackbar('remove', true, itemsToBeDeleted.length))
      .catch(() => this.props.displaySnackbar('remove', false, itemsToBeDeleted.length));
  };

  addItem = item => {
    return axios({
      method: 'post',
      url: this.props.backendURL,
      headers: {
        'Content-Type': 'application/json'
      },
      data: JSON.stringify(item)
    });
  };

  editItem = item => {
    return axios({
      method: 'put',
      url: `${this.props.backendURL}/${item[this.props.identifier]}`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: JSON.stringify(item)
    });
  };

  removeItem = item => {
    return axios({
      method: 'delete',
      url: `${this.props.backendURL}/${item[this.props.identifier]}`
    });
  };

  selectItem = (key, value) => {
    const selectedItem = this.state.items[key];

    if (selectedItem) {
      selectedItem.isSelected = value !== null ? value : !selectedItem.isSelected;
      this.setState({
        items: [...this.state.items.slice(0, key), selectedItem, ...this.state.items.slice(key + 1)],
        selectedCount: this.state.items.filter(item => item.isSelected).length
      });
    }
  };

  selectAllItems = () => {
    const selectValue = this.state.selectedCount === this.state.items.length ? false : true;
    this.state.items.forEach((item, key) => this.selectItem(key, selectValue));
  };

  deselectAllItems = () => {
    this.state.items.forEach((item, key) => this.selectItem(key, false));
  };

  toggleEditMode = () => {
    this.deselectAllItems();
    this.setState({ editMode: !this.state.editMode });
  };

  openItemDetailsDialog = () => {
    this.setState({ dialogOpen: true, dialogType: 'create' });
  };

  onCloseItemDetailsDialog = () => {
    this.setState({ dialogOpen: false, dialogItem: {}, dialogType: 'create' });
  };

  onSaveItem = item => {
    this.setState({ dialogOpen: false, dialogItem: {} });

    if (this.state.dialogType === 'create') {
      this.addItem(item)
        .catch(() => this.props.displaySnackbar('create', false))
        .then(response => this.setState({ items: [...this.state.items, response.data] }))
        .then(() => this.props.displaySnackbar('create', true));
    } else {
      const key = this.state.items.findIndex(it => it[this.props.identifier] === item[this.props.identifier]);

      this.editItem(item)
        .catch(() => this.props.displaySnackbar('edit', false))
        .then(() =>
          this.setState({
            items: [...this.state.items.slice(0, key), item, ...this.state.items.slice(key + 1)]
          })
        )
        .then(() => this.props.displaySnackbar('edit', true));
    }
  };

  onClickItem = (item, event) => {
    if (event.target.type === 'checkbox' || !this.state.editMode) {
      return;
    }
    this.setState({ dialogOpen: true, dialogItem: item, dialogType: 'edit' });
  };

  onCloseSnackbar = () => {
    this.setState({ snackbarOpen: false });
  };

  displayItems = () => {
    const rootStyle = {
      width: '100%',
      overflowX: 'auto'
    };

    return (
      <Paper className="items" style={rootStyle}>
        {this.displayTableToolbar()}
        <Table style={{ fontSize: '12px' }}>
          <TableHead>
            <CustomTableHeader
              editMode={this.state.editMode}
              rowCount={this.state.items.length}
              selectedCount={this.state.selectedCount}
              selectAll={this.selectAllItems}
              cells={this.props.headerCells}
              type={this.props.itemType}
            />
          </TableHead>
          <TableBody>
            {this.state.items.map((item, key) => (
              <CustomTableRow
                key={key}
                index={key}
                item={item}
                editMode={this.state.editMode}
                onClickItem={this.onClickItem}
                selectItem={this.selectItem}
                type={this.props.itemType}
              />
            ))}
          </TableBody>
        </Table>
      </Paper>
    );
  };

  displayTableToolbar = () => {
    return (
      <Toolbar
        className={classNames('admin-table__toolbar', { colored: this.state.selectedCount > 0 })}
        style={{ minHeight: '52px' }}
      >
        <div>
          {this.state.selectedCount > 0 ? (
            <Typography type="subheading">
              <b>{this.state.selectedCount}</b>
              {this.state.selectedCount === 1 ? ' selectat' : ' selectate'}
            </Typography>
          ) : (
            <Typography type="title" variant="h6">
              {this.props.tableTitle}
            </Typography>
          )}
        </div>
        <div className="spacer" />
        <div className="actions">
          {this.state.selectedCount > 0 ? (
            <IconButton aria-label="Delete" onClick={this.removeItems}>
              <Delete />
            </IconButton>
          ) : (
            <div>
              <IconButton color="default" onClick={this.openItemDetailsDialog}>
                <Add style={{ fontSize: 22 }} />
              </IconButton>
              <IconButton color={this.state.editMode ? 'primary' : 'default'} onClick={this.toggleEditMode}>
                {this.state.editMode ? <Check style={{ fontSize: 22 }} /> : <Edit style={{ fontSize: 22 }} />}
              </IconButton>
            </div>
          )}
        </div>
      </Toolbar>
    );
  };

  render() {
    return (
      <div className="admin-table">
        {this.displayItems()}
        <ItemDetailsDialog
          open={this.state.dialogOpen}
          handleClose={this.onCloseItemDetailsDialog}
          handleSave={this.onSaveItem}
          item={this.state.dialogItem}
          dialogType={this.state.dialogType}
          type={this.props.itemType}
        />
      </div>
    );
  }
}

export default CustomTable;
