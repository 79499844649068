import React, { Component } from 'react';

import PreviewAbout from './preview-about/PreviewAbout';
import PreviewAnnouncements from './preview-announcements/PreviewAnnouncements';
import PreviewPastors from './preview-pastors/PreviewPastors.tsx';
import PreviewGroups from './preview-groups/PreviewGroups';

export default class Previews extends Component {
  render() {
    return (
      <div className="previews">
        <PreviewAnnouncements />
        <PreviewAbout />
        <PreviewPastors />
        <PreviewGroups />
      </div>
    );
  }
}
