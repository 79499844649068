import React, { Component } from 'react';
import { Snackbar, IconButton } from '@material-ui/core';
import { Clear } from '@material-ui/icons';

import CustomTable from 'ui/admin/custom-table/CustomTable';

import constants from 'ui/constants';

class AdminPastors extends Component {
  constructor() {
    super();

    this.state = {
      snackbarOpen: false,
      snackbarMessage: '',
      headerCells: [
        { name: 'Nume', padding: 'default', center: false },
        { name: 'Poză', padding: 'default', center: true },
        { name: 'Descriere', padding: 'default', center: true }
      ]
    };
  }

  displaySnackbar = (type, positive, length) => {
    let snackbarMessage = '';

    if (type === 'create') {
      if (positive) {
        snackbarMessage = 'Datele prezbiterului au fost create.';
      } else {
        snackbarMessage = 'Datele prezbiterului nu au putut fi create.';
      }
    } else if (type === 'edit') {
      if (positive) {
        snackbarMessage = 'Datele prezbiterului au fost modificate.';
      } else {
        snackbarMessage = 'Datele prezbiterului nu au putut fi modificate.';
      }
    } else {
      if (positive) {
        snackbarMessage = length > 1 ? 'Datele prezbiterilor au fost șterse.' : 'Datele prezbiterului au fost șterse.';
      } else {
        snackbarMessage =
          length > 1 ? 'Datele prezbiterilor nu au putut fi șterse.' : 'Datele prezbiterului nu au putut fi șterse.';
      }
    }

    this.setState({ snackbarOpen: true, snackbarMessage });
  };

  onCloseSnackbar = () => {
    this.setState({ snackbarOpen: false });
  };

  render() {
    return (
      <div className="admin-pastors">
        <CustomTable
          identifier="pastorId"
          itemType="pastor"
          tableTitle="Prezbiteri"
          backendURL={`${constants.backendURL}pastors`}
          displaySnackbar={this.displaySnackbar}
          headerCells={this.state.headerCells}
        />
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={this.state.snackbarOpen}
          onClose={this.onCloseSnackbar}
          autoHideDuration={3000}
          message={<span>{this.state.snackbarMessage}</span>}
          snackbarcontentprops={{
            style: { backgroundColor: '#ffffff', color: '#191919' }
          }}
          action={[
            <IconButton key="close" aria-label="Close" color="inherit" onClick={this.onCloseSnackbar}>
              <Clear />
            </IconButton>
          ]}
        />
      </div>
    );
  }
}

export default AdminPastors;
