import React from 'react';
import { TableRow, Checkbox } from '@material-ui/core';
import classNames from 'classnames';
import moment from 'moment';

import CustomTableCell from 'ui/admin/custom-table/CustomTableCell';
import EventDatesPopover from './EventDatesPopover';

const EventsTableRow = props => {
  const { event, editMode, onClickEvent, selectEvent, index } = props;

  return (
    <TableRow
      className={classNames('admin-table__row', { editable: editMode })}
      onClick={onClickEvent.bind(this, event)}
    >
      {editMode && (
        <CustomTableCell padding="checkbox" center={true}>
          <Checkbox
            style={{ fontSize: 20 }}
            checked={event.isSelected ? event.isSelected : false}
            onChange={selectEvent.bind(null, index, null)}
          />
        </CustomTableCell>
      )}
      <CustomTableCell padding="default" center={false} editMode={editMode}>
        {event.name}
      </CustomTableCell>
      <CustomTableCell padding="none" center={false}>
        {event.startDate && moment(event.startDate).format('DD MMMM YYYY')}
      </CustomTableCell>
      <CustomTableCell padding="none" center={false}>
        {event.startDate && moment(event.startDate).format('h:mm')}
      </CustomTableCell>
      <CustomTableCell padding="none" center={false}>
        {event.endDate && moment(event.endDate).format('DD MMMM YYYY')}
      </CustomTableCell>
      <CustomTableCell padding="none" center={false}>
        {event.endDate && moment(event.endDate).format('h:mm')}
      </CustomTableCell>
      <CustomTableCell padding="none" center={false}>
        {event.type === 'special' ? 'special' : 'normal'}
      </CustomTableCell>
      <CustomTableCell padding="none" center={false}>
        {event.times && (
          <div className="admin-table__times">
            <div key={index} className="time" style={{ padding: '2px', textAlign: 'center' }}>
              {moment(event.times[0].startDate).format('DD MMMM, h:mm')} -{' '}
              {moment(event.times[0].endDate).format('DD MMMM, h:mm')}
              {event.times[1] && <EventDatesPopover times={event.times} />}
            </div>
          </div>
        )}
      </CustomTableCell>
      <CustomTableCell padding="none" center={false}>
        {event.description}
      </CustomTableCell>
      <CustomTableCell padding="none" center={false}>
        {event.photoURL && (
          <img
            src={event.photoURL}
            className="admin-table__image"
            onClick={() => window.open(event.photoURL, '_blank')}
            alt=""
          />
        )}
      </CustomTableCell>
      <CustomTableCell padding="default" center={true}>
        {event.visible ? 'da' : 'nu'}
      </CustomTableCell>
    </TableRow>
  );
};

export default EventsTableRow;
