import React from 'react';
import objectFitImages from 'object-fit-images';

import AnimatedWrapper from 'ui/utils/animated-wrapper/AnimatedWrapper';

import './page-title.scss';

class PageTitle extends React.Component {
  componentDidMount() {
    objectFitImages(document.querySelector('.page-title img'));
  }

  componentDidUpdate() {
    objectFitImages(document.querySelector('.page-title img'));
  }

  render() {
    return (
      <div className="page-title">
        <h3>{this.props.title}</h3>
        <AnimatedWrapper type="fadeIn" duration={1}>
          <img src={this.props.backgroundImage} alt="" />
        </AnimatedWrapper>
      </div>
    );
  }
}

export default PageTitle;
