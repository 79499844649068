import React, { Component } from 'react';

import {
  Dialog,
  withMobileDialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  TextField,
  FormControlLabel,
  Switch
} from '@material-ui/core';
import moment from 'moment';

class CancelledIntervalDetailsDialog extends Component {
  constructor(props) {
    super(props);

    this.state = this.getBlankCancelledInterval();
  }

  getBlankCancelledInterval = (props = this.props) => {
    return {
      cancelledIntervalId: props.cancelledInterval._id || null,
      from: props.cancelledInterval.from
        ? moment(props.cancelledInterval.from)
        : moment(),
      until: props.cancelledInterval.until
        ? moment(props.cancelledInterval.until)
        : moment(),
      shouldTemporarilyCancel: false
    };
  };

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      const newState = this.getBlankCancelledInterval(this.props);
      this.setState(newState);
    }
  }

  onUpdateFrom = event => {
    const newDate = moment(event.target.value);
    this.setState({ from: newDate });
  };

  onUpdateUntil = event => {
    const newDate = moment(event.target.value);
    this.setState({ until: newDate });
  };

  onUpdateShouldTemporarilyCancel = checked => {
    this.setState({ shouldTemporarilyCancel: checked });
  };

  onCloseDialog = () => {
    this.setState(this.getBlankException());
    this.props.handleClose();
  };

  onSaveCancelledInterval = () => {
    const newCancelledInterval = {
      from: this.state.from,
      until: this.state.until,
      shouldTemporarilyCancel: this.state.shouldTemporarilyCancel
    };

    if (this.state.cancelledIntervalId) {
      newCancelledInterval._id = this.state.cancelledIntervalId;
    }

    this.props.handleSave(newCancelledInterval);
    this.setState(this.getBlankCancelledInterval());
  };

  render() {
    const { fullScreen } = this.props;

    return (
      <div>
        <Dialog
          className="admin-dialog"
          fullScreen={fullScreen}
          open={this.props.open}
          onClose={this.props.handleClose}
        >
          <DialogTitle>
            {this.props.dialogType === 'create'
              ? 'Adaugă un interval în care să fie anulate întâlnirile'
              : 'Editează intervalul în care să fie anulate întâlnirile'}
          </DialogTitle>
          <DialogContent>
            <div>
              <div className="admin-row">
                <TextField
                  label="De la"
                  type="date"
                  className="admin-input date"
                  value={moment(this.state.from).format('YYYY-MM-DD')}
                  onChange={this.onUpdateFrom}
                  style={{ flex: 1, fontSize: '14px' }}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  label="Până la"
                  type="date"
                  className="admin-input date"
                  value={moment(this.state.until).format('YYYY-MM-DD')}
                  onChange={this.onUpdateUntil}
                  style={{ flex: 1, fontSize: '14px' }}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
            </div>
            <div className="admin-row">
              <div>
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.shouldTemporarilyCancel}
                      onChange={(event, checked) =>
                        this.onUpdateShouldTemporarilyCancel(checked)
                      }
                    />
                  }
                  label={
                    <span style={{ fontSize: '14px' }}>
                      Anulează temporar întâlnirile ?
                    </span>
                  }
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.onCloseDialog}
              className="admin-dialog__button"
              color="primary"
            >
              Anulează
            </Button>
            <Button
              onClick={this.onSaveCancelledInterval}
              className="admin-dialog__button"
              color="primary"
              autoFocus
            >
              Salvează
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withMobileDialog()(CancelledIntervalDetailsDialog);
