import React, { Component } from 'react';
import { Snackbar, IconButton } from '@material-ui/core';
import { Clear } from '@material-ui/icons';

import CustomTable from 'ui/admin/custom-table/CustomTable';

import constants from 'ui/constants';

class AdminSchedule extends Component {
  constructor() {
    super();

    this.state = {
      snackbarOpen: false,
      snackbarMessage: '',
      exceptionsHeaderCells: [
        { name: 'Nume', padding: 'default', center: false },
        { name: 'Dată', padding: 'default', center: false },
        { name: 'Oră început dimineață', padding: 'none', center: false },
        { name: 'Oră început seară', padding: 'none', center: false },
      ],
      cancelledIntervalsHeaderCells: [
        { name: 'De la', padding: 'default', center: false },
        { name: 'Până la', padding: 'none', center: false },
        { name: 'Întâlniri temporar anulate?', padding: 'none', center: false },
      ],
    };
  }

  displaySnackbar = (type, positive, length) => {
    let snackbarMessage = '';

    if (type === 'create') {
      if (positive) {
        snackbarMessage = 'Excepția fost creată.';
      } else {
        snackbarMessage = 'Excepția nu a putut fi creată.';
      }
    } else if (type === 'edit') {
      if (positive) {
        snackbarMessage = 'Excepția fost modificată.';
      } else {
        snackbarMessage = 'Excepția nu a putut fi modificată.';
      }
    } else {
      if (positive) {
        snackbarMessage = length > 1 ? 'Excepțiile au fost șterse.' : 'Excepția fost ștearsă.';
      } else {
        snackbarMessage = length > 1 ? 'Excepțiile nu au putut fi șterse.' : 'Excepția nu a putut fi ștearsă.';
      }
    }

    this.setState({ snackbarOpen: true, snackbarMessage });
  };

  onCloseSnackbar = () => {
    this.setState({ snackbarOpen: false });
  };

  render() {
    return (
      <div className="admin-schedule">
        <CustomTable
          identifier="exceptionId"
          itemType="exception"
          tableTitle="Excepții"
          backendURL={`${constants.backendURL}schedule-exceptions`}
          displaySnackbar={this.displaySnackbar}
          headerCells={this.state.exceptionsHeaderCells}
        />
        <CustomTable
          identifier="_id"
          itemType="cancelledInterval"
          tableTitle="Întâlniri anulate"
          backendURL={`${constants.backendURL}cancelled-intervals`}
          displaySnackbar={this.displaySnackbar}
          headerCells={this.state.cancelledIntervalsHeaderCells}
        />
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={this.state.snackbarOpen}
          onClose={this.onCloseSnackbar}
          autoHideDuration={3000}
          message={<span>{this.state.snackbarMessage}</span>}
          snackbarcontentprops={{
            style: { backgroundColor: '#ffffff', color: '#191919' },
          }}
          action={[
            <IconButton key="close" aria-label="Close" color="inherit" onClick={this.onCloseSnackbar}>
              <Clear />
            </IconButton>,
          ]}
        />
      </div>
    );
  }
}

export default AdminSchedule;
