import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import $ from 'jquery';

import constants from 'ui/constants';

import './menu.scss';

class Menu extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.handleScroll = this.handleScroll.bind(this);
    this.toggleNavbar = this.toggleNavbar.bind(this);

    this.state = {
      path: window.location.pathname,
      coloredBackground: this.props.location.pathname !== '/',
      whiteLogo:
        this.props.location.pathname !== '/contact' &&
        this.props.location.pathname !== '/',
      hideLogo: false,
      isOpen: false
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.listenForScroll();
    this.closeMenuWhenChangingRoute();
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener('scroll', this.handleScroll.bind(this));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.listenForScroll();

      if (this._isMounted) {
        this.setState({
          path: this.props.location.pathname,
          coloredBackground: this.props.location.pathname !== '/',
          whiteLogo:
            this.props.location.pathname !== '/contact' &&
            this.props.location.pathname !== '/',
          hideLogo: false
        });
      }
    }
  }

  closeMenuWhenChangingRoute() {
    $('.nav a').on('click', () => {
      if ($(window).width() <= 767) {
        $('.navbar-toggle').click();
      }
    });
  }

  listenForScroll() {
    window.addEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    const scrollTop = window.scrollY;
    const innerHeight = window.innerHeight;
    const mobileDevice = window.innerWidth <= 859;
    const heightSplitValue = mobileDevice ? 7 : 4;
    const colored = scrollTop > innerHeight / heightSplitValue;

    if (
      this._isMounted &&
      (colored !== this.state.coloredBackground ||
        colored !== this.state.hideLogo)
    ) {
      this.setState({
        coloredBackground: colored || this.props.location.pathname !== '/',
        hideLogo: colored
      });
    }
  }

  toggleNavbar() {
    if (this._isMounted) {
      this.setState({ isOpen: !this.state.isOpen });
    }
  }

  render() {
    if (this.props.location.pathname.includes('admin')) {
      return null;
    }

    let navClassName = classNames(
      'navbar',
      'navbar-default',
      'navbar-fixed-top',
      { colored: this.state.coloredBackground },
      { open: this.state.isOpen }
    );
    let logoClassName = classNames('logo', 'logo__left', {
      'hide-logo': this.state.hideLogo
    });
    let navbarToggleClassName = classNames('navbar-toggle collapsed', {
      dark: !this.state.coloredBackground
    });

    return (
      <nav className={navClassName}>
        <div className={logoClassName}>
          <Link to="/">
            <img
              src={
                this.state.whiteLogo
                  ? constants.whiteLogoURL
                  : constants.blueLogoURL
              }
              alt=""
            />
          </Link>
        </div>

        <div className="container-fluid">
          <div className="navbar-header">
            <button
              type="button"
              onClick={this.toggleNavbar}
              className={navbarToggleClassName}
              data-toggle="collapse"
              data-target="#menu-navbar"
              aria-expanded="false"
            >
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar top" />
              <span className="icon-bar middle" />
              <span className="icon-bar bottom" />
            </button>
          </div>
          <div className="collapse navbar-collapse" id="menu-navbar">
            <ul className="nav navbar-nav">
              <li className="logo">
                <Link to={{ pathname: '/', search: window.location.search }}>
                  <img
                    src={
                      window.innerWidth <= 859 && this.state.coloredBackground
                        ? constants.whiteLogoURL
                        : constants.blueLogoURL
                    }
                    alt=""
                  />
                </Link>
              </li>
              <div className="nav__center">
                <li
                  className={classNames('home', {
                    active: this.state.path === '/'
                  })}
                >
                  <Link to={{ pathname: '/', search: window.location.search }}>
                    Acasă<span className="sr-only">(current)</span>
                  </Link>
                </li>
                <li
                  className={classNames({
                    active: this.state.path === '/despre'
                  })}
                >
                  <Link
                    to={{ pathname: '/despre', search: window.location.search }}
                  >
                    Despre noi<span className="sr-only">(current)</span>
                  </Link>
                </li>
                {/* <li
                  className={classNames({
                    active: this.state.path === '/prezbiteri'
                  })}
                >
                  <Link
                    to={{
                      pathname: '/prezbiteri',
                      search: window.location.search
                    }}
                  >
                    Prezbiteri<span className="sr-only">(current)</span>
                  </Link>
                </li> */}
                <li
                  className={classNames({
                    active: this.state.path === '/lucrari'
                  })}
                >
                  <Link
                    to={{
                      pathname: '/lucrari',
                      search: window.location.search
                    }}
                  >
                    Lucrări<span className="sr-only">(current)</span>
                  </Link>
                </li>
                <li
                  className={classNames({
                    active: this.state.path === '/misiune'
                  })}
                >
                  <Link
                    to={{
                      pathname: '/misiune',
                      search: window.location.search
                    }}
                  >
                    Misiune<span className="sr-only">(current)</span>
                  </Link>
                </li>
                <li
                  className={classNames({
                    active: this.state.path === '/mesaje'
                  })}
                >
                  <Link
                    to={{ pathname: '/mesaje', search: window.location.search }}
                  >
                    Mesaje<span className="sr-only">(current)</span>
                  </Link>
                </li>
                <li
                  className={classNames({
                    active: this.state.path === '/live'
                  })}
                >
                  <Link
                    to={{ pathname: '/live', search: window.location.search }}
                  >
                    Live<span className="sr-only">(current)</span>
                  </Link>
                </li>
                {/* <li
                  className={classNames({
                    active: this.state.path === '/anunturi'
                  })}
                >
                  <Link
                    to={{
                      pathname: '/anunturi',
                      search: window.location.search
                    }}
                  >
                    Anunțuri<span className="sr-only">(current)</span>
                  </Link>
                </li> */}
                <li
                  className={classNames('contact', {
                    active: this.state.path === '/contact'
                  })}
                >
                  <Link
                    to={{
                      pathname: '/contact',
                      search: window.location.search
                    }}
                  >
                    Contact<span className="sr-only">(current)</span>
                  </Link>
                </li>
              </div>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

export default withRouter(Menu);
