const constants = {
  // backendURL: 'https://vestea-buna-api.herokuapp.com/',
  // backendURL: 'http://localhost:3000/',
  backendURL: 'https://api.vesteabunaiasi.ro/',
  whiteLogoURL: 'https://i.imgur.com/B5jI3iQ.png',
  blueLogoURL: 'https://i.imgur.com/1Gwzwcr.png',
  previews: {
    about_text: {
      text: 'Biserica Vestea Bună este o biserică creștină după Evanghelie. A fost înființată în anul 2000 și are aproximativ 200 de membri.',
      versetText:
        '"De aceea, preaiubiţii mei fraţi, fiţi tari, neclintiţi, sporiţi totdeauna în lucrul Domnului, căci ştiţi că osteneala voastră în Domnul nu este zadarnică."',
      versetTrimitere: '1 Corinteni 15:58'
    }
  }
};

export default constants;
