import React from 'react';
import { TableRow, Checkbox } from '@material-ui/core';
import classNames from 'classnames';
import moment from 'moment';

import CustomTableCell from 'ui/admin/custom-table/CustomTableCell';

const CancelledIntervalsTableRow = props => {
  const {
    cancelledInterval,
    editMode,
    onClickCancelledInterval,
    selectCancelledInterval,
    index
  } = props;

  return (
    <TableRow
      className={classNames('admin-table__row', { editable: editMode })}
      onClick={onClickCancelledInterval.bind(this, cancelledInterval)}
    >
      {editMode && (
        <CustomTableCell padding="checkbox" center={true}>
          <Checkbox
            style={{ fontSize: 20 }}
            checked={
              cancelledInterval.isSelected
                ? cancelledInterval.isSelected
                : false
            }
            onChange={selectCancelledInterval.bind(null, index, null)}
          />
        </CustomTableCell>
      )}
      <CustomTableCell padding="default" center={false} editMode={editMode}>
        {cancelledInterval.from &&
          moment(cancelledInterval.from).format('DD MMM YYYY')}
      </CustomTableCell>
      <CustomTableCell padding="none" center={false}>
        {cancelledInterval.until &&
          moment(cancelledInterval.until).format('DD MMM YYYY')}
      </CustomTableCell>
      <CustomTableCell padding="none" center={false}>
        {cancelledInterval.shouldTemporarilyCancel ? 'Da' : 'Nu'}
      </CustomTableCell>
    </TableRow>
  );
};

export default CancelledIntervalsTableRow;
