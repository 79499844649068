import React from 'react';
import { TableRow, Checkbox } from '@material-ui/core';
import classNames from 'classnames';

import CustomTableCell from 'ui/admin/custom-table/CustomTableCell';

const MessageTableRow = props => {
  const { message, editMode, onClickMessage, selectMessage, index } = props;

  return (
    <TableRow
      className={classNames('admin-table__row', { editable: editMode })}
      onClick={onClickMessage.bind(this, message)}
    >
      {editMode && (
        <CustomTableCell padding="checkbox" center={true}>
          <Checkbox
            style={{ fontSize: 20 }}
            checked={message.isSelected ? message.isSelected : false}
            onChange={selectMessage.bind(null, index, null)}
          />
        </CustomTableCell>
      )}
      <CustomTableCell padding="default" center={false} editMode={editMode}>
        {message.title}
      </CustomTableCell>
      <CustomTableCell padding="default" center={false} editMode={editMode}>
        {message.description}
      </CustomTableCell>
      <CustomTableCell padding="default" center={false} editMode={editMode}>
        {message.youtubeEmbeddedCode}
      </CustomTableCell>
    </TableRow>
  );
};

export default MessageTableRow;
