import React, { Component } from 'react';
import { Button, TextField } from '@material-ui/core';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  withMobileDialog
} from '@material-ui/core';

class LiveDetailsDialog extends Component {
  constructor(props) {
    super(props);

    this.state = this.getBlankVideo();
  }

  getBlankVideo = (props = this.props) => {
    return {
      videoId: props.video.videoId || null,
      title: props.video.title || '',
      description: props.video.description || '',
      youtube: props.video.youtubeEmbeddedCode || ''
    };
  };

  componentDidUpdate(prevPops) {
    if (prevPops.open !== this.props.open) {
      const newState = this.getBlankVideo(this.props);
      this.setState(newState);
    }
  }

  onUpdateTitle = event => {
    this.setState({ title: event.target.value });
  };

  onUpdateDescription = event => {
    this.setState({ description: event.target.value });
  };

  onUpdateYoutube = event => {
    this.setState({ youtube: event.target.value });
  };

  onCloseDialog = () => {
    this.setState(this.getBlankVideo());
    this.props.handleClose();
  };

  onSaveVideo = () => {
    const newVideo = {
      title: this.state.title,
      description: this.state.description,
      youtubeEmbeddedCode: this.state.youtube
    };

    if (this.state.videoId) {
      newVideo.videoId = this.state.videoId;
    }

    this.props.handleSave(newVideo);
    this.setState(this.getBlankVideo());
  };

  render() {
    const { fullScreen } = this.props;

    return (
      <div>
        <Dialog
          className="admin-dialog"
          fullScreen={fullScreen}
          open={this.props.open}
          onClose={this.props.handleClose}
        >
          <DialogTitle>
            {this.props.dialogType === 'create'
              ? 'Adaugă un video'
              : 'Editează video-ul'}
          </DialogTitle>
          <DialogContent>
            <div>
              <div className="admin-row">
                <TextField
                  label="Titlu"
                  className="admin-input"
                  value={this.state.title}
                  onChange={this.onUpdateTitle}
                  fullWidth
                />
              </div>
              <div className="admin-row">
                <TextField
                  label="Descriere"
                  className="admin-input"
                  rows={8}
                  style={{ overflowY: 'auto' }}
                  value={this.state.description}
                  onChange={this.onUpdateDescription}
                  multiline
                  fullWidth
                />
              </div>
              <div className="admin-row">
                <TextField
                  label="Link Youtube"
                  className="admin-input"
                  rows={8}
                  style={{ overflowY: 'auto' }}
                  value={this.state.youtube}
                  onChange={this.onUpdateYoutube}
                  multiline
                  fullWidth
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.onCloseDialog}
              className="admin-dialog__button"
              color="primary"
            >
              Anulează
            </Button>
            <Button
              onClick={this.onSaveVideo}
              className="admin-dialog__button"
              color="primary"
              autoFocus
            >
              Salvează
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withMobileDialog()(LiveDetailsDialog);
