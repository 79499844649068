import React from 'react';
import AnimateHeight from 'react-animate-height';

import classNames from 'classnames';

import PageTitle from 'ui/utils/page-title/PageTitle';
import vision from 'ui/data/aboutData';
import pageViews from 'ui/utils/google-analytics/pageViews';

import './about.scss';

class About extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visionSections: this.getVisionSections(),
      activeSectionIndex: 0,
      activeVisionCategoryIndex: 0
    };

    // this.backgroundImageUrl = 'https://i.imgur.com/5r3ZWhQ.jpg';
    this.backgroundImageUrl = 'https://i.imgur.com/5BkAoK2.jpg';

    this.getChurchVisionContent = this.getChurchVisionContent.bind(this);
    this.getChurchVisionText = this.getChurchVisionText.bind(this);
    pageViews.registerPageView();
  }

  componentDidMount() {
    document.title = 'Despre noi – Biserica Vestea Bună Iași';
    window.scrollTo(0, 0);
  }

  getVisionSections() {
    return Object.keys(vision).map((item, key) => {
      return {
        sections: vision[item].sections,
        visible: false
      };
    });
  }

  getChurchVisionContent() {
    const getSectionClassName = index => {
      return classNames('vision-title', {
        active: this.state.activeVisionCategoryIndex === index
      });
    };
    const getIconClassName = index => {
      return classNames('vision-arrow', 'fa', {
        up: this.state.visionSections[index].visible
      });
    };

    return (
      <div className="vision-content__wrapper">
        <div
          id="section-0"
          className={getSectionClassName(0)}
          onClick={this.onSelectVisionCategory.bind(this, 0)}
        >
          <span>Sfânta Scriptură</span>
          <i className={getIconClassName(0)} aria-hidden="true" />
        </div>
        <AnimateHeight
          duration={400}
          height={this.state.visionSections[0].visible ? 'auto' : 0}
        >
          {this.getChurchVisionText('vision-content__wrapper__text', 0)}
        </AnimateHeight>
        <div
          id="section-1"
          className={getSectionClassName(1)}
          onClick={this.onSelectVisionCategory.bind(this, 1)}
        >
          <span>Dumnezeu</span>
          <i className={getIconClassName(1)} aria-hidden="true" />
        </div>
        <AnimateHeight
          duration={400}
          height={this.state.visionSections[1].visible ? 'auto' : 0}
        >
          {this.getChurchVisionText('vision-content__wrapper__text', 1)}
        </AnimateHeight>
        <div
          id="section-2"
          className={getSectionClassName(2)}
          onClick={this.onSelectVisionCategory.bind(this, 2)}
        >
          <span>Omul</span>
          <i className={getIconClassName(2)} aria-hidden="true" />
        </div>
        <AnimateHeight
          duration={400}
          height={this.state.visionSections[2].visible ? 'auto' : 0}
        >
          {this.getChurchVisionText('vision-content__wrapper__text', 2)}
        </AnimateHeight>
        <div
          id="section-3"
          className={getSectionClassName(3)}
          onClick={this.onSelectVisionCategory.bind(this, 3)}
        >
          <span>Mântuirea</span>
          <i className={getIconClassName(3)} aria-hidden="true" />
        </div>
        <AnimateHeight
          duration={400}
          height={this.state.visionSections[3].visible ? 'auto' : 0}
        >
          {this.getChurchVisionText('vision-content__wrapper__text', 3)}
        </AnimateHeight>
        <div
          id="section-4"
          className={getSectionClassName(4)}
          onClick={this.onSelectVisionCategory.bind(this, 4)}
        >
          <span>Biserica</span>
          <i className={getIconClassName(4)} aria-hidden="true" />
        </div>
        <AnimateHeight
          duration={400}
          height={this.state.visionSections[4].visible ? 'auto' : 0}
        >
          {this.getChurchVisionText('vision-content__wrapper__text', 4)}
        </AnimateHeight>
        <div
          id="section-5"
          className={getSectionClassName(5)}
          onClick={this.onSelectVisionCategory.bind(this, 5)}
        >
          <span>Îngerii</span>
          <i className={getIconClassName(5)} aria-hidden="true" />
        </div>
        <AnimateHeight
          duration={400}
          height={this.state.visionSections[5].visible ? 'auto' : 0}
        >
          {this.getChurchVisionText('vision-content__wrapper__text', 5)}
        </AnimateHeight>
        <div
          id="section-6"
          className={getSectionClassName(6)}
          onClick={this.onSelectVisionCategory.bind(this, 6)}
        >
          <span>Lucrurile din urmă</span>
          <i className={getIconClassName(6)} aria-hidden="true" />
        </div>
        <AnimateHeight
          duration={400}
          height={this.state.visionSections[6].visible ? 'auto' : 0}
        >
          {this.getChurchVisionText('vision-content__wrapper__text', 6)}
        </AnimateHeight>
      </div>
    );
  }

  getChurchVisionText(className, index) {
    const visionIndex =
      index !== undefined ? index : this.state.activeVisionCategoryIndex;
    const visionItem = this.state.visionSections[visionIndex];
    const visionClassName = classNames(className, {
      visible: visionItem.visible
    });

    const getParagraphs = section => {
      if (!section.paragraphs) {
        return null;
      }
      return section.paragraphs.map((paragraph, index) => (
        <div key={index} className="vision-section__paragraph">
          <i className="fa fa-circle" aria-hidden="true" />
          {paragraph}
        </div>
      ));
    };
    const content = visionItem.sections.map((section, index) => (
      <div key={index} className="vision-section">
        <div className="vision-section__title">{section.title}</div>
        <div className="vision-section__subtitle">{section.subtitle}</div>
        {getParagraphs(section)}
      </div>
    ));
    return <div className={visionClassName}>{content}</div>;
  }

  onSelectVisionCategory(index) {
    let visionSections = this.state.visionSections;
    visionSections[index].visible = !visionSections[index].visible;
    this.setState({
      activeVisionCategoryIndex: index,
      visionSections: visionSections
    });
  }

  render() {
    return (
      <div className="about page-wrapper">
        <PageTitle
          title="Despre noi"
          backgroundImage={this.backgroundImageUrl}
        />
        <div className="about__main page-content">
          <div className="about__main__general">
            <h5 className="section-title">Cine suntem?</h5>
            <div>
              <span>Biserica "Vestea Bună"</span> este o biserică creștină după
              Evanghelie.
              <br />A fost înființată în anul <span>2000</span> și are
              aproximativ <span>143</span> de membri.
            </div>

            <div className="section-subtitle">Scopul</div>
            <div className="section-subcontent">
              Glorificarea lui Dumnezeu prin zidirea bisericii și extinderea
              împărăției Lui în comunitate.
            </div>

            <div className="section-subtitle">Viziunea</div>
            <div className="section-subcontent">
              Creștem în Cristos pentru a sluji pe semeni.
            </div>

            <div className="section-subtitle">Obiective și valori</div>
            <div className="section-subcontent">
              Biserica "Vestea Bună" este o comunitate în care: <br></br>
              <div className="section-subcontent__objectives">
                <span className="number">1</span>
                <span className="objective">
                  membrii cultivă o relație autentică cu Dumnezeu
                </span>
              </div>
              <div className="section-subcontent__objectives">
                <span className="number">2</span>
                <span className="objective">se dezvoltă relații sănătoase</span>
              </div>
              <div className="section-subcontent__objectives">
                <span className="number">3</span>
                <span className="objective">
                  se slujește în funcție de darurile spirituale
                </span>
              </div>
              <div className="section-subcontent__objectives">
                <span className="number">4</span>
                <span className="objective">
                  conducerea împuternicește pe oamenii potriviți
                </span>
              </div>
              <div className="section-subcontent__objectives">
                <span className="number">5</span>
                <span className="objective">
                  membrii sunt pasionați de împărtășirea Evangheliei
                </span>
              </div>
              <div className="section-subcontent__objectives">
                <span className="number">6</span>
                <span className="objective">
                  membrii sunt integrați în grupuri mici
                </span>
              </div>
              <div className="section-subcontent__objectives">
                <span className="number">7</span>
                <span className="objective">
                  serviciile divine sunt edificatoare
                </span>
              </div>
            </div>
            <hr />

            <div className="about__main__vision">
              <h5 className="section-title">Ce credem?</h5>
              <div className="vision-content">
                {this.getChurchVisionContent()}
                {this.getChurchVisionText('vision-text')}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default About;
