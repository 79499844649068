import React, { Component } from 'react';

import { Add, Clear } from '@material-ui/icons';
import {
  Dialog,
  withMobileDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  MenuItem,
  IconButton,
  Typography,
  FormControlLabel,
  Switch
} from '@material-ui/core';
import moment from 'moment';

class EventDetailsDialog extends Component {
  constructor(props) {
    super(props);

    this.state = this.getBlankEvent();
  }

  getBlankEvent = (props = this.props) => {
    return {
      name: props.event.name || '',
      description: props.event.description || '',
      type: props.event.type || 'normal',
      startDate: props.event.startDate ? moment(props.event.startDate) : moment(),
      endDate: props.event.endDate ? moment(props.event.endDate) : moment().add(1, 'hours'),
      times: props.event.times || null,
      photoURL: props.event.photoURL || '',
      eventId: props.event.eventId || null,
      visible: props.event.visible !== undefined ? props.event.visible : true
    };
  };

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      const newState = this.getBlankEvent(this.props);
      this.setState(newState);
    }
  }

  onUpdateName = event => {
    this.setState({ name: event.target.value });
  };

  onUpdateDescription = event => {
    this.setState({ description: event.target.value });
  };

  onUpdateType = event => {
    const type = event.target.value;
    const blankEvent = this.getBlankEvent();

    this.setState({
      type,
      times:
        type === 'normal' ? null : [{ startDate: moment(blankEvent.startDate), endDate: moment(blankEvent.endDate) }]
    });
  };

  onUpdateStartDate = event => {
    const newStartDate = moment(event.target.value);
    const newEndDate = newStartDate.clone().add(1, 'hours');
    this.setState({ startDate: newStartDate, endDate: newEndDate });
  };

  onUpdateEndDate = event => {
    this.setState({ endDate: moment(event.target.value) });
  };

  onUpdateTimeStartDate = (key, event) => {
    const time = this.state.times[key];
    time.startDate = moment(event.target.value);
    time.endDate = time.startDate.clone().add(1, 'hours');
    this.setState({ times: [...this.state.times.slice(0, key), time, ...this.state.times.slice(key + 1)] });
  };

  onUpdateTimeEndDate = (key, event) => {
    const time = this.state.times[key];
    time.endDate = moment(event.target.value);
    this.setState({ times: [...this.state.times.slice(0, key), time, ...this.state.times.slice(key + 1)] });
  };

  onUpdateVisibility = visible => {
    this.setState({ visible });
  };

  addNewTime = () => {
    this.setState({
      times: [...this.state.times, { startDate: moment(), endDate: moment().add(1, 'hours') }]
    });
  };

  removeTime = key => {
    this.setState({ times: [...this.state.times.slice(0, key), ...this.state.times.slice(key + 1)] });
  };

  onUpdatePhotoURL = event => {
    this.setState({ photoURL: event.target.value });
  };

  onCloseDialog = () => {
    this.setState(this.getBlankEvent());
    this.props.handleClose();
  };

  onSaveEvent = () => {
    const newEvent = { name: this.state.name, description: this.state.description, type: this.state.type };

    if (this.state.type === 'special') {
      newEvent.times = this.state.times;
      newEvent.photoURL = this.state.photoURL;
    } else {
      newEvent.startDate = this.state.startDate;
      newEvent.endDate = this.state.endDate;
    }

    if (this.state.eventId) {
      newEvent.eventId = this.state.eventId;
    }

    newEvent.visible = this.state.visible;

    this.props.handleSave(newEvent);
    this.setState(this.getBlankEvent());
  };

  render() {
    const { fullScreen } = this.props;

    return (
      <div>
        <Dialog
          className="admin-dialog"
          fullScreen={fullScreen}
          open={this.props.open}
          onClose={this.props.handleClose}
        >
          <DialogTitle>
            {this.props.dialogType === 'create' ? 'Adaugă un eveniment' : 'Editează evenimentul'}
          </DialogTitle>
          <DialogContent>
            <div>
              <div className="admin-row">
                <TextField
                  label="Nume"
                  style={{ flex: 2 }}
                  className="admin-input"
                  value={this.state.name}
                  onChange={this.onUpdateName}
                />
                <TextField
                  select
                  label="Tip"
                  className="admin-input select"
                  value={this.state.type}
                  onChange={this.onUpdateType}
                >
                  <MenuItem value="normal">normal</MenuItem>
                  <MenuItem value="special">special</MenuItem>
                </TextField>
              </div>
              <div className="admin-row">
                <TextField
                  fullWidth
                  label="Descriere"
                  style={{ flex: 1 }}
                  className="admin-input"
                  multiline
                  rows="3"
                  rowsMax="5"
                  value={this.state.description}
                  onChange={this.onUpdateDescription}
                />
              </div>
            </div>
            {this.state.type !== 'special' && (
              <div className="admin-row">
                <TextField
                  label="Dată început"
                  type="datetime-local"
                  className="admin-input date"
                  onChange={this.onUpdateStartDate}
                  value={this.state.startDate.format('YYYY-MM-DDTHH:mm')}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
                <TextField
                  label="Dată sfârșit"
                  type="datetime-local"
                  className="admin-input date"
                  onChange={this.onUpdateEndDate}
                  value={this.state.endDate.format('YYYY-MM-DDTHH:mm')}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </div>
            )}
            {this.state.type === 'special' && (
              <div>
                <div className="admin-section inset">
                  <Typography>
                    <span>Date:</span>
                    <IconButton>
                      <Add onClick={this.addNewTime} style={{ fontSize: '20px', color: '#ffcc00' }} />
                    </IconButton>
                  </Typography>
                  {this.state.times.map((time, key) => (
                    <div className="admin-row" key={key}>
                      <TextField
                        label="Dată început"
                        type="datetime-local"
                        className="admin-input date"
                        value={moment(time.startDate).format('YYYY-MM-DDTHH:mm')}
                        onChange={this.onUpdateTimeStartDate.bind(this, key)}
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                      <TextField
                        label="Dată sfârșit"
                        type="datetime-local"
                        className="admin-input date"
                        value={moment(time.endDate).format('YYYY-MM-DDTHH:mm')}
                        onChange={this.onUpdateTimeEndDate.bind(this, key)}
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                      <IconButton style={{ width: '30px', height: '30px' }}>
                        <Clear onClick={this.removeTime.bind(null, key)} style={{ fontSize: '16px' }} />
                      </IconButton>
                    </div>
                  ))}
                </div>

                <div className="admin-row">
                  <TextField
                    fullWidth
                    className="admin-input"
                    value={this.state.photoURL}
                    label="URL poză"
                    onChange={this.onUpdatePhotoURL}
                  />
                </div>
              </div>
            )}
            <div className="admin-row">
              <div>
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.visible}
                      onChange={(event, checked) => this.onUpdateVisibility(checked)}
                    />
                  }
                  label={<span style={{ fontSize: '14px' }}>Vizibil ?</span>}
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.onCloseDialog} className="admin-dialog__button" color="primary">
              Anulează
            </Button>
            <Button onClick={this.onSaveEvent} className="admin-dialog__button" color="primary" autoFocus>
              Salvează
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withMobileDialog()(EventDetailsDialog);
