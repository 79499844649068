import React from 'react';

import moment from 'moment';

import constants from 'ui/constants';
import { Link } from 'react-router-dom';

import './preview-announcements.scss';

class PreviewAnnouncements extends React.Component {
  _isMounted = false;

  constructor() {
    super();

    this.state = {
      announcement: ''
    };

    this.setAnnouncements = this.setAnnouncements.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.getAnnouncements();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getAnnouncements() {
    return fetch(`${constants.backendURL}announcements`, { method: 'GET' }).then(this.setAnnouncements);
  }

  setAnnouncements(response) {
    return response.json().then(announcements => {
      if (this._isMounted) {
        const flaggedAnnouncement = announcements.find(announcement => announcement.isFlagged);

        if (flaggedAnnouncement) {
          this.setState({ announcement: flaggedAnnouncement });
        }
      }
    });
  }

  render() {
    if (!this.state.announcement) {
      return null;
    }
    return (
      <div className="preview-announcements">
        <div className="preview-announcements__content">
          <div className="preview-announcements__header">
            <div className="announcement-header">Anunț</div>
          </div>

          <div className="preview-announcements__message">
            <div className="preview-announcements__message__date">
              {moment(this.state.announcement.date).format('D MMMM YYYY')}
            </div>
            <div className="preview-announcements__message__title">{this.state.announcement.title}</div>
            <div className="preview-announcements__message__content">{this.state.announcement.message}</div>
          </div>

          <div className="preview-more">
            <Link to={{ pathname: '/anunturi', search: window.location.search }}>
              <span>Vezi toate anunțurile</span>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default PreviewAnnouncements;
