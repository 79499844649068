import React from 'react';

import EventsTableRow from 'ui/admin/admin-events/EventsTableRow';
import ExceptionsTableRow from 'ui/admin/admin-schedule/ExceptionsTableRow';
import PastorsTableRow from 'ui/admin/admin-pastors/PastorsTableRow';
import MessageTableRow from '../admin-messages/MessageTableRow';
import AnnouncementsTableRow from '../admin-announcements/AnnouncementsTableRow';
import CancelledIntervalsTableRow from '../admin-schedule/CancelledIntervalsTableRow';
import LiveTableRow from '../admin-live/LiveTableRow';

const CustomTableRow = props => {
  if (props.type === 'event') {
    return (
      <EventsTableRow
        index={props.index}
        event={props.item}
        editMode={props.editMode}
        onClickEvent={props.onClickItem}
        selectEvent={props.selectItem}
      />
    );
  } else if (props.type === 'exception') {
    return (
      <ExceptionsTableRow
        index={props.index}
        exception={props.item}
        editMode={props.editMode}
        onClickException={props.onClickItem}
        selectException={props.selectItem}
      />
    );
  } else if (props.type === 'pastor') {
    return (
      <PastorsTableRow
        index={props.index}
        pastor={props.item}
        editMode={props.editMode}
        onClickPastor={props.onClickItem}
        selectPastor={props.selectItem}
      />
    );
  } else if (props.type === 'message') {
    return (
      <MessageTableRow
        index={props.index}
        message={props.item}
        editMode={props.editMode}
        onClickMessage={props.onClickItem}
        selectMessage={props.selectItem}
      />
    );
  } else if (props.type === 'live') {
    return (
      <LiveTableRow
        index={props.index}
        video={props.item}
        editMode={props.editMode}
        onClickVideo={props.onClickItem}
        selectVideo={props.selectItem}
      />
    );
  } else if (props.type === 'announcement') {
    return (
      <AnnouncementsTableRow
        index={props.index}
        announcement={props.item}
        editMode={props.editMode}
        onClickAnnouncement={props.onClickItem}
        selectAnnouncement={props.selectItem}
      />
    );
  } else if (props.type === 'cancelledInterval') {
    return (
      <CancelledIntervalsTableRow
        index={props.index}
        cancelledInterval={props.item}
        editMode={props.editMode}
        onClickCancelledInterval={props.onClickItem}
        selectCancelledInterval={props.selectItem}
      />
    );
  }
};

export default CustomTableRow;
