import React from 'react';

import classNames from 'classnames';

import constants from 'ui/constants';

import PageTitle from 'ui/utils/page-title/PageTitle';
import pageViews from 'ui/utils/google-analytics/pageViews';

import './messages.scss';

class Messages extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.itemsPerPage = 4;

    this.state = {
      messagePages: [],
      currentPage: 0,
      totalNumberOfMessages: 0,
      messagesLoaded: false,
      // backgroundImageUrl: 'https://i.imgur.com/5r3ZWhQ.jpg'
      backgroundImageUrl: 'https://i.imgur.com/5BkAoK2.jpg'
    };

    this.setMessages = this.setMessages.bind(this);

    pageViews.registerPageView();
  }

  componentDidMount() {
    this._isMounted = true;
    this.getMessages(0);
    document.title = 'Mesaje – Biserica Vestea Bună Iași';
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getMessages(pageNumber) {
    return fetch(
      `${constants.backendURL}messages?resultsPerPage=${
        this.itemsPerPage
      }&skip=${pageNumber * this.itemsPerPage}`,
      { method: 'GET' }
    )
      .then(response => this.setMessages(response, pageNumber))
      .catch(this.onError);
  }

  setMessages(response, pageNumber) {
    return response.json().then(responseData => {
      this._isMounted &&
        this.setState({
          messagePages: {
            ...this.state.messagePages,
            [pageNumber]: responseData.messages
          },
          totalNumberOfMessages: responseData.totalCount,
          messagesLoaded: true
        });
    });
  }

  changePage(pageNumber) {
    if (!this.state.messagePages[pageNumber]) {
      this.getMessages(pageNumber).then(() => {
        window.scrollTo(0, 0);
        this.setState({ currentPage: pageNumber });
      });
    } else {
      this.setState({ currentPage: pageNumber });
    }
  }

  displayMessages() {
    if (
      this.state.messagePages &&
      this.state.messagePages[this.state.currentPage]?.length > 0
    ) {
      return this.state.messagePages[this.state.currentPage].map(
        (message, key) => {
          // TODO update the property name at some point
          const splitYoutubeLinkParts =
            message.youtubeEmbeddedCode?.split('/') || [];
          const splitQueryParamParts =
            splitYoutubeLinkParts[splitYoutubeLinkParts.length - 1].split(
              '?v='
            );
          const embeddedCode = splitQueryParamParts
            ? splitQueryParamParts[splitQueryParamParts.length - 1]
            : null;

          return (
            <div className="message" key={key}>
              <div className="message__title">{message.title}</div>
              <div className="message__youtube">
                <div
                  className="video"
                  style={{
                    position: 'relative',
                    paddingBottom: '56.25%',
                    paddingTop: 25,
                    height: 0
                  }}
                >
                  <iframe
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%'
                    }}
                    src={`https://www.youtube.com/embed/${embeddedCode}`}
                    frameBorder="0"
                    title="message"
                    allow="fullscreen"
                  />
                </div>
              </div>
              <div className="message__description">{message.description}</div>
            </div>
          );
        }
      );
    } else if (this.state.messagesLoaded) {
      return <div className="coming-soon">În curând.</div>;
    } else {
      return null;
    }
  }

  getPages() {
    const pages = [];

    for (
      let i = 0;
      i < this.state.totalNumberOfMessages / this.itemsPerPage;
      i++
    ) {
      let pageClassName = classNames('page', {
        active: this.state.currentPage === i
      });

      pages.push(
        <span
          className={pageClassName}
          onClick={this.changePage.bind(this, i)}
          key={i}
        >
          {i + 1}
        </span>
      );
    }

    return (
      <div className="pages-container">
        <p>Selectează pagina:</p>
        <div className="pages">{pages}</div>
      </div>
    );
  }

  render() {
    return (
      <div className="messages page-wrapper">
        <PageTitle
          title="Mesaje"
          backgroundImage={this.state.backgroundImageUrl}
        />
        <div className="messages__main page-content">
          {this.displayMessages()}
          {this.state.totalNumberOfMessages > this.itemsPerPage &&
            this.getPages()}
        </div>
      </div>
    );
  }
}

export default Messages;
