import React from 'react';

import moment from 'moment';
import { orderBy } from 'lodash';

import constants from 'ui/constants';

import PageTitle from 'ui/utils/page-title/PageTitle';
import pageViews from 'ui/utils/google-analytics/pageViews';

import './announcements.scss';

class Announcements extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      announcements: [],
      announcementsLoaded: false,
      backgroundImageUrl: 'https://i.imgur.com/5r3ZWhQ.jpg'
    };

    this.setAnnouncements = this.setAnnouncements.bind(this);

    pageViews.registerPageView();
  }

  componentDidMount() {
    this._isMounted = true;
    this.getAnnouncements();
    document.title = 'Anunțuri – Biserica Vestea Bună Iași';
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getAnnouncements() {
    return fetch(`${constants.backendURL}announcements`, { method: 'GET' })
      .then(this.setAnnouncements)
      .catch(this.onError);
  }

  setAnnouncements(response) {
    return response.json().then(announcements => {
      this._isMounted &&
        this.setState({ announcements: orderBy(announcements, 'date', 'desc'), announcementsLoaded: true });
    });
  }

  displayAnnouncements() {
    if (this.state.announcements && this.state.announcements.length > 0) {
      return this.state.announcements.map((announcement, key) => {
        return (
          <div className="announcement" key={key}>
            <div className="announcement__top">
              <div className="announcement__title">{announcement.title}</div>
              <div className="announcement__date">{moment(announcement.date).format('D MMMM YYYY')}</div>
            </div>

            <div className="announcement__message">{announcement.message}</div>
          </div>
        );
      });
    } else if (this.announcementsLoaded) {
      return <div className="coming-soon">Nu există niciun anunț momentan.</div>;
    } else {
      return null;
    }
  }

  render() {
    return (
      <div className="announcements page-wrapper">
        <PageTitle title="Anunțuri" backgroundImage={this.state.backgroundImageUrl} />
        <div className="announcements__main page-content">{this.displayAnnouncements()}</div>
      </div>
    );
  }
}

export default Announcements;
