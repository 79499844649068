import React from 'react';

import PageTitle from 'ui/utils/page-title/PageTitle';
import pageViews from 'ui/utils/google-analytics/pageViews';
import constants from 'ui/constants';

import './pastors.scss';

class Pastors extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pastors: [],
      // backgroundImageUrl: 'https://i.imgur.com/YPU20HC.jpg'
      backgroundImageUrl: 'https://i.imgur.com/5BkAoK2.jpg'
    };

    this.setPastors = this.setPastors.bind(this);

    this.getPastors();
    pageViews.registerPageView();
  }

  componentDidMount() {
    document.title = 'Prezbiteri – Biserica Vestea Bună Iași';
    window.scrollTo(0, 0);
  }

  getPastors() {
    return fetch(`${constants.backendURL}pastors`, { method: 'GET' })
      .then(this.setPastors)
      .catch(this.onError);
  }

  setPastors(response) {
    return response
      .json()
      .then(pastors => !this._unmounted && this.setState({ pastors: pastors }));
  }

  displayPastors() {
    return this.state.pastors.map((item, key) => {
      return (
        <div className="pastor" key={key}>
          <div
            className="pastor__photo"
            style={{ backgroundImage: `url(${item.photoURL})` }}
          >
            {!item.photoURL && <i className="fa fa-user"></i>}
          </div>
          <div className="pastor__details">
            <div className="pastor__name">
              <span>{item.name}</span>
              <span />
            </div>
            {/* <div className="pastor__description">{item.description}</div> */}
          </div>
        </div>
      );
    });
  }

  render() {
    return (
      <div className="pastors page-wrapper">
        <PageTitle
          title="Prezbiteri"
          backgroundImage={this.state.backgroundImageUrl}
        />
        <div className="pastors__main page-content">
          {this.displayPastors()}
        </div>
      </div>
    );
  }
}

export default Pastors;
