import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import 'whatwg-fetch';

import GoogleMap from 'ui/utils/google-map/GoogleMap';
import AnimatedWrapper from 'ui/utils/animated-wrapper/AnimatedWrapper';
import pageViews from 'ui/utils/google-analytics/pageViews';
import isInternetExplorer from 'ui/utils/detectInternetExplorer';
import constants from 'ui/constants';

import './contact.scss';

class Contact extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: {
        sender: { name: '', email: '' },
        content: ''
      },
      shouldShowErrorMessage: false,
      shouldShowSuccessMessage: false,
      sendEmailInProgress: false
    };

    this.getGoogleMap = this.getGoogleMap.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
    this.updateMessageSenderName = this.updateMessageSenderName.bind(this);
    this.updateMessageSenderEmail = this.updateMessageSenderEmail.bind(this);
    this.updateMessageContent = this.updateMessageContent.bind(this);
    pageViews.registerPageView();
  }

  componentDidMount() {
    document.title = 'Contact – Biserica Vestea Bună Iași';
    window.scrollTo(0, 0);
  }

  getGoogleMap() {
    if (isInternetExplorer()) {
      return null;
    }
    return (
      <GoogleMap
        zoom={17}
        markers={[['Iasi', 47.173894, 27.567452, 10]]}
        const
        center={{ lat: 47.1742733, lng: 27.5680318 }}
        styleMap={[
          {
            featureType: 'administrative',
            elementType: 'all',
            stylers: [{ visibility: 'on' }, { lightness: 14 }]
          },
          {
            featureType: 'landscape',
            elementType: 'all',
            stylers: [{ color: '#f7f7f7' }]
          },
          {
            featureType: 'poi.business',
            elementType: 'all',
            stylers: [{ visibility: 'on' }]
          },
          {
            featureType: 'poi.park',
            elementType: 'geometry',
            stylers: [{ color: '#deecdb' }]
          },
          {
            featureType: 'poi.park',
            elementType: 'labels',
            stylers: [{ visibility: 'on' }, { lightness: '25' }]
          },
          {
            featureType: 'road',
            elementType: 'all',
            stylers: [{ lightness: '25' }]
          },
          {
            featureType: 'road',
            elementType: 'labels.icon',
            stylers: [{ visibility: 'on' }]
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry',
            stylers: [{ color: '#ffffff' }]
          },
          {
            featureType: 'road.highway',
            elementType: 'labels',
            stylers: [{ saturation: '-90' }, { lightness: '25' }]
          },
          {
            featureType: 'road.arterial',
            elementType: 'all',
            stylers: [{ visibility: 'on' }]
          },
          {
            featureType: 'road.arterial',
            elementType: 'geometry',
            stylers: [
              {
                color: '#ffffff'
              }
            ]
          },
          {
            featureType: 'road.local',
            elementType: 'geometry',
            stylers: [{ color: '#ffffff' }]
          },
          {
            featureType: 'transit.line',
            elementType: 'all',
            stylers: [{ visibility: 'off' }]
          },
          {
            featureType: 'transit.station',
            elementType: 'all',
            stylers: [{ visibility: 'on' }]
          },
          {
            featureType: 'water',
            elementType: 'all',
            stylers: [{ visibility: 'on' }, { color: '#e0f1f9' }]
          }
        ]}
      />
    );
  }

  updateMessageSenderName(e) {
    const newMessage = this.state.message;
    newMessage.sender.name = e.target.value;
    this.setState({ message: newMessage });
  }

  updateMessageSenderEmail(e) {
    const newMessage = this.state.message;
    newMessage.sender.email = e.target.value;
    this.setState({ message: newMessage });
  }

  updateMessageContent(e) {
    const newMessage = this.state.message;
    newMessage.content = e.target.value;
    this.setState({ message: newMessage });
  }

  shouldDisableMessageButton() {
    const nameEmpty = this.state.message.sender.name.trim().length === 0;
    const emailEmpty = this.state.message.sender.email.trim().length === 0;
    const contentEmpty = this.state.message.content.trim().length === 0;

    if (nameEmpty || emailEmpty || contentEmpty) {
      return true;
    }

    const emailRegex = /(\w+)@(\w+)\.[a-zA-Z]/g;
    const emailIsValid = emailRegex.test(this.state.message.sender.email);

    return !emailIsValid;
  }

  getSuccessMessage() {
    if (!this.state.shouldShowSuccessMessage) {
      return null;
    }

    return (
      <AnimatedWrapper type="fadeIn">
        <span className="message success-message">
          Mesajul a fost trimis. Mulțumim.
        </span>
      </AnimatedWrapper>
    );
  }

  getErrorMessage() {
    if (!this.state.shouldShowErrorMessage) {
      return null;
    }

    return (
      <AnimatedWrapper type="fadeIn">
        <span className="message error-message">
          Mesajul nu a putut fi trimis. Mai încearcă o dată.
        </span>
      </AnimatedWrapper>
    );
  }

  sendEmail() {
    const onError = () => {
      this.setState({
        shouldShowErrorMessage: true,
        sendEmailInProgress: false
      });
      setTimeout(() => this.setState({ shouldShowErrorMessage: false }), 8000);
    };
    const onSuccess = response => {
      if (response.status === 404 || response.statys === 500) {
        return onError();
      }
      const emptyMessage = {
        sender: {
          name: '',
          email: ''
        },
        content: ''
      };
      this.setState({
        shouldShowSuccessMessage: true,
        message: emptyMessage,
        sendEmailInProgress: false
      });
      setTimeout(
        () => this.setState({ shouldShowSuccessMessage: false }),
        8000
      );
    };

    const messageToSend = {
      // to prevent XSS attacks
      name: this.state.message.sender.name
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;'),
      email: this.state.message.sender.email
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;'),
      content: this.state.message.content
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
    };

    this.setState({ sendEmailInProgress: true });

    fetch(`${constants.backendURL}contact/email`, {
      method: 'POST',
      body: JSON.stringify({ message: messageToSend }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(onSuccess)
      .catch(onError);
  }

  getLink(text, link) {
    return (
      <a href={link} target="_blank" rel="noopener noreferrer">
        {text}
      </a>
    );
  }

  getDocuments() {
    return (
      <>
        <p>
          <i className="fa fa-circle"></i>
          {this.getLink(
            'Mărturisire de credință Biserica Vestea Bună',
            '/documente/Marturisirea de credinta Biserica Vestea Buna.pdf'
          )}
        </p>
        <p>
          <i className="fa fa-circle"></i>
          {this.getLink(
            'Statutul Bisericii Creștine După Evanghelie',
            '/documente/Statutul Bisericii Crestine Dupa Evanghelie - MO Partea I nr 638.pdf'
          )}
        </p>
        <p>
          <i className="fa fa-circle"></i>
          {this.getLink(
            'Decret de recunoaștere',
            '/documente/Decret-de-recunoastere-a-statutului.pdf'
          )}
        </p>
        <p>
          <i className="fa fa-circle"></i>
          {this.getLink('Acord GDPR', '/documente/Acord GDPR.pdf')}
        </p>
        <p>
          <i className="fa fa-circle"></i>
          {this.getLink(
            'Cerere membralitate',
            '/documente/Cerere Membralitate.pdf'
          )}
        </p>
      </>
    );
  }

  render() {
    const buttonStyle = {
      backgroundColor: '#01446b',
      border: 0,
      color: 'white',
      padding: '4px 15px',
      fontWeight: 600,
      fontSize: '14px',
      textTransform: 'uppercase',
      float: 'right',
      marginTop: 10
    };

    return (
      <div className="contact page-wrapper">
        <div className="contact__main page-content">
          <div className="contact__main__map">{this.getGoogleMap()}</div>
          <div className="contact__main__bottom">
            <div className="contact-form">
              <label className="contact__main__label">Scrie-ne:</label>
              <TextField
                fullWidth
                label="Nume"
                name="sender-name"
                className="contact-input"
                labelclassname="contact-label"
                value={this.state.message.sender.name}
                onChange={this.updateMessageSenderName}
              />
              <TextField
                fullWidth
                label="E-mail"
                name="sender-email"
                className="contact-input"
                labelclassname="contact-label"
                value={this.state.message.sender.email}
                onChange={this.updateMessageSenderEmail}
              />
              <TextField
                fullWidth
                multiline
                rows={3}
                rowsMax={8}
                label="Mesaj"
                name="content"
                className="contact-input"
                labelclassname="contact-label"
                value={this.state.message.content}
                onChange={this.updateMessageContent}
              />
              <Button
                disabled={this.shouldDisableMessageButton()}
                style={buttonStyle}
                onClick={this.sendEmail}
              >
                {!this.state.sendEmailInProgress && <span>Trimite</span>}
                {this.state.sendEmailInProgress && (
                  <i className="fa fa-circle-o-notch" aria-hidden="true" />
                )}
              </Button>
              <div className="message-wrapper">
                {this.getSuccessMessage()}
                {this.getErrorMessage()}
              </div>
              <div className="contact-email">
                <span>Sau prin e-mail la:</span>
                <div>
                  <i className="fa fa-envelope"></i>
                  <a href="mailto:contact@vesteabunaiasi.ro">
                    contact@vesteabunaiasi.ro
                  </a>
                  .
                </div>
              </div>
            </div>
            <div className="contact-details">
              <label className="contact__main__label">Vizitează-ne:</label>

              <div className="contact-details__wrapper">
                <div className="contact-details__wrapper__text">
                  De regulă, ne întâlnim în fiecare
                </div>
                <div className="contact-details__wrapper__schedule">
                  <i className="fa fa-circle"></i>
                  <span>duminică</span> de la ora 10:00, timp de închinare și
                  învățătură
                </div>
                <div className="contact-details__wrapper__schedule">
                  <i className="fa fa-circle"></i>
                  <span>miercuri</span> de la ora 19:00, timp de rugăciune
                </div>
                <div className="contact-details__wrapper__text">
                  pe <span>Str. dr. Victor Babeș nr. 3, Iași</span>.
                </div>
              </div>

              <label className="contact__main__label">Pentru donații:</label>
              <div className="contact-details__wrapper donations">
                <div className="contact-details__wrapper__text">
                  <div className="regular">Beneficiar:</div>
                  <span>Biserica Creștină "Vestea Bună" Iași</span>
                </div>
                <div className="contact-details__wrapper__text">
                  <div className="regular">Cont bancar:</div>
                  <span>RO19BTRLRONCRT0245189901</span>
                </div>
                <div className="contact-details__wrapper__text">
                  <i>
                    Puteți specifica destinația donației în detaliile
                    transferului.
                  </i>
                </div>
              </div>

              <label className="contact__main__label">Documente:</label>
              <div className="contact-details__wrapper documents">
                <div className="contact-details__wrapper__text">
                  {this.getDocuments()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
