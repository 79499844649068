import React, { Component } from 'react';
import { Snackbar, IconButton } from '@material-ui/core';
import { Clear } from '@material-ui/icons';

import CustomTable from 'ui/admin/custom-table/CustomTable';

import constants from 'ui/constants';

import './admin-events.scss';

class AdminEvents extends Component {
  constructor() {
    super();

    this.state = {
      snackbarOpen: false,
      snackbarMessage: '',
      headerCells: [
        { name: 'Nume', padding: 'default', center: false },
        { name: 'Dată început', padding: 'none', center: false },
        { name: 'Oră început', padding: 'none', center: false },
        { name: 'Dată sfârșit', padding: 'none', center: false },
        { name: 'Oră sfarșit', padding: 'none', center: false },
        { name: 'Tip', padding: 'none', center: false },
        { name: 'Date început și sfârșit', padding: 'none', center: false },
        { name: 'Descriere', padding: 'none', center: false },
        { name: 'Poză', padding: 'none', center: false },
        { name: 'Vizibil', padding: 'none', center: false }
      ]
    };
  }

  displaySnackbar = (type, positive, length) => {
    let snackbarMessage = '';

    if (type === 'create') {
      if (positive) {
        snackbarMessage = 'Evenimentul a fost creat.';
      } else {
        snackbarMessage = 'Evenimentul nu a putut fi creat.';
      }
    } else if (type === 'edit') {
      if (positive) {
        snackbarMessage = 'Evenimentul a fost modificat.';
      } else {
        snackbarMessage = 'Evenimentul nu a putut fi modificat.';
      }
    } else {
      if (positive) {
        snackbarMessage = length > 1 ? 'Evenimentele au fost șterse.' : 'Evenimentul a fost șters.';
      } else {
        snackbarMessage = length > 1 ? 'Evenimentele nu au putut fi șterse.' : 'Evenimentul nu a putut fi șters.';
      }
    }

    this.setState({ snackbarOpen: true, snackbarMessage });
  };

  onCloseSnackbar = () => {
    this.setState({ snackbarOpen: false });
  };

  render() {
    return (
      <div className="admin-events">
        <CustomTable
          identifier="eventId"
          itemType="event"
          tableTitle="Evenimente"
          backendURL={`${constants.backendURL}events`}
          displaySnackbar={this.displaySnackbar}
          headerCells={this.state.headerCells}
        />
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={this.state.snackbarOpen}
          onClose={this.onCloseSnackbar}
          autoHideDuration={3000}
          message={<span>{this.state.snackbarMessage}</span>}
          snackbarcontentprops={{
            style: { backgroundColor: '#ffffff', color: '#191919' }
          }}
          action={[
            <IconButton key="close" aria-label="Close" color="inherit" onClick={this.onCloseSnackbar}>
              <Clear />
            </IconButton>
          ]}
        />
      </div>
    );
  }
}

export default AdminEvents;
