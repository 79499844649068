import React from 'react';
import $ from 'jquery';
import objectFitImages from 'object-fit-images';
import classNames from 'classnames';

import PageTitle from 'ui/utils/page-title/PageTitle';
import Diagram from 'ui/utils/diagram/Diagram';
import ministriesData from 'ui/data/ministriesData';
import pageViews from 'ui/utils/google-analytics/pageViews';

import './ministries.scss';

class Ministries extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      lightboxIsOpen: [false, false, false, false],
      // backgroundImageUrl: 'https://i.imgur.com/00hmVJr.jpg'
      backgroundImageUrl: 'https://i.imgur.com/5BkAoK2.jpg'
    };

    this.displayMinistry = this.displayMinistry.bind(this);
    this.openLightbox = this.openLightbox.bind(this);
    this.closeLightbox = this.closeLightbox.bind(this);
    this.scrollToMinistry = this.scrollToMinistry.bind(this);
    pageViews.registerPageView();
  }

  componentDidMount() {
    document.title = 'Lucrări – Biserica Vestea Bună Iași';
    window.scrollTo(0, 0);
    objectFitImages(document.querySelectorAll('.ministry__header img'));
    objectFitImages(
      document.querySelectorAll('.ministries__preview__item img')
    );
  }

  openLightbox(index) {
    let lightboxIsOpen = this.state.lightboxIsOpen;
    lightboxIsOpen[index] = true;
    this.setState({ lightboxIsOpen });
  }

  closeLightbox(index) {
    let lightboxIsOpen = this.state.lightboxIsOpen;
    lightboxIsOpen[index] = false;
    this.setState({ lightboxIsOpen });
  }

  scrollToMinistry(target) {
    $('html, body').animate(
      {
        scrollTop: $(target).offset().top - ($(window).width() > 767 ? 65 : 0)
      },
      800
    );
  }

  displayMinistry(ministry, index) {
    const getSectionParagraphs = paragraphs => {
      return paragraphs.map((paragraph, key) => <p key={key}>{paragraph}</p>);
    };

    const sections = ministry.content.sections.map((section, key) => {
      return (
        <div className="ministry__section" key={key}>
          {section.title && (
            <div
              className={classNames('ministry__section__title', {
                'center-title': section.centerTitle
              })}
            >
              <span>{section.title}</span>
            </div>
          )}
          <div className="ministry__section__paragraphs">
            {getSectionParagraphs(section.paragraphs)}
          </div>
          {section.images && (
            <div className="ministry__section__images">
              <Diagram images={section.images} />
            </div>
          )}
        </div>
      );
    });

    return (
      <div>
        <div className="ministry__header">
          <img
            src={ministry.photoURL}
            onClick={this.openLightbox.bind(this, index)}
            alt=""
          />
          <div className="ministry__header__titles">
            <span>{ministry.content.title}</span>
            <span>{ministry.content.subtitle}</span>
          </div>
        </div>
        <div className="ministry__content">
          <span>{sections}</span>
        </div>
      </div>
    );
  }

  displayPreview() {
    const imagePreviews = ministriesData.map((ministry, key) => (
      <div
        onClick={this.scrollToMinistry.bind(
          this,
          `#${ministry.content.title.toLowerCase()}`
        )}
        className="ministries__preview__item"
        key={key}
      >
        <img src={ministry.mediumPreviewPhotoURL} alt="" />
        <span>{ministry.content.title}</span>
      </div>
    ));

    return <div className="ministries__preview">{imagePreviews}</div>;
  }

  displayMinistries() {
    return ministriesData.map((ministry, key) => {
      const id = ministry.content.title.toLowerCase();
      return (
        <div className="ministry" key={key} id={id}>
          {this.displayMinistry(ministry, key)}
        </div>
      );
    });
  }

  render() {
    return (
      <div className="ministries page-wrapper">
        <PageTitle
          title="Lucrări"
          backgroundImage={this.state.backgroundImageUrl}
        />
        <div className="ministries__main">
          {this.displayPreview()}
          <p className="ministries__intro">
            "De aceea, preaiubiţii mei fraţi, fiţi tari, neclintiţi, sporiţi
            totdeauna în lucrul Domnului, căci ştiţi că osteneala voastră în
            Domnul nu este zadarnică."
            <br />1 Corinteni 15:58
          </p>
          {this.displayMinistries()}
        </div>
      </div>
    );
  }
}

export default Ministries;
