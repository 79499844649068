import React from 'react';

import classNames from 'classnames';

import constants from 'ui/constants';

import PageTitle from 'ui/utils/page-title/PageTitle';
import pageViews from 'ui/utils/google-analytics/pageViews';

import './live.scss';

class Live extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.itemsPerPage = 4;

    this.state = {
      videoPages: [],
      currentPage: 0,
      totalNumberOfVideos: 0,
      videosLoaded: false,
      // backgroundImageUrl: 'https://i.imgur.com/5r3ZWhQ.jpg'
      backgroundImageUrl: 'https://i.imgur.com/5BkAoK2.jpg'
    };

    this.setVideos = this.setVideos.bind(this);

    pageViews.registerPageView();
  }

  componentDidMount() {
    this._isMounted = true;
    this.getVideos(0);
    document.title = 'Live – Biserica Vestea Bună Iași';
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getVideos(pageNumber) {
    return fetch(
      `${constants.backendURL}live?resultsPerPage=${this.itemsPerPage}&skip=${
        pageNumber * this.itemsPerPage
      }`,
      { method: 'GET' }
    )
      .then(response => this.setVideos(response, pageNumber))
      .catch(this.onError);
  }

  setVideos(response, pageNumber) {
    return response.json().then(responseData => {
      this._isMounted &&
        this.setState({
          videoPages: {
            ...this.state.videoPages,
            [pageNumber]: responseData.videos
          },
          totalNumberOfVideos: responseData.totalCount,
          videosLoaded: true
        });
    });
  }

  changePage(pageNumber) {
    if (!this.state.videoPages[pageNumber]) {
      this.getVideos(pageNumber).then(() => {
        window.scrollTo(0, 0);
        this.setState({ currentPage: pageNumber });
      });
    } else {
      this.setState({ currentPage: pageNumber });
    }
  }

  displayVideos() {
    if (
      this.state.videoPages &&
      this.state.videoPages[this.state.currentPage]?.length > 0
    ) {
      return this.state.videoPages[this.state.currentPage].map((video, key) => {
        // TODO update the property name at some point
        const splitYoutubeLinkParts =
          video.youtubeEmbeddedCode?.split('/') || [];
        const splitQueryParamParts =
          splitYoutubeLinkParts[splitYoutubeLinkParts.length - 1].split('?v=');
        const embeddedCode = splitQueryParamParts
          ? splitQueryParamParts[splitQueryParamParts.length - 1]
          : null;

        return (
          <div className="video" key={key}>
            <div className="video__title">{video.title}</div>
            <div className="video__youtube">
              <div
                className="youtube-video"
                style={{
                  position: 'relative',
                  paddingBottom: '56.25%',
                  paddingTop: 25,
                  height: 0
                }}
              >
                <iframe
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%'
                  }}
                  src={`https://www.youtube.com/embed/${embeddedCode}`}
                  frameBorder="0"
                  title="message"
                  allow="fullscreen"
                />
              </div>
            </div>
            <div className="message__description">{video.description}</div>
          </div>
        );
      });
    } else if (this.state.videosLoaded) {
      return <div className="coming-soon">În curând.</div>;
    } else {
      return null;
    }
  }

  getPages() {
    const pages = [];

    for (
      let i = 0;
      i < this.state.totalNumberOfVideos / this.itemsPerPage;
      i++
    ) {
      let pageClassName = classNames('page', {
        active: this.state.currentPage === i
      });

      pages.push(
        <span
          className={pageClassName}
          onClick={this.changePage.bind(this, i)}
          key={i}
        >
          {i + 1}
        </span>
      );
    }

    return (
      <div className="pages-container">
        <p>Selectează pagina:</p>
        <div className="pages">{pages}</div>
      </div>
    );
  }

  render() {
    return (
      <div className="videos page-wrapper">
        <PageTitle
          title="Live"
          backgroundImage={this.state.backgroundImageUrl}
        />
        <div className="messages__main page-content">
          {this.displayVideos()}
          {this.state.totalNumberOfVideos > this.itemsPerPage &&
            this.getPages()}
        </div>
      </div>
    );
  }
}

export default Live;
