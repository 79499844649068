import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import PreviewHeader from 'ui/pages/homepage/previews/preview-header/PreviewHeader';
import Group from 'ui/pages/homepage/previews/preview-groups/group/Group';
import ministriesData from 'ui/data/ministriesData';

import './PreviewGroups.scss';

export default class PreviewGroups extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photos: ministriesData.map(ministry => ministry.largePreviewPhotoURL)
    };
  }

  render() {
    return (
      <div className="preview-groups">
        <div className="preview-groups__header">
          <PreviewHeader headerText="Lucrări" dark />
        </div>

        <div className="preview-groups__content">
          <Group title="Tineri" imgSrc={this.state.photos[0]} />
          <Group title="Cor" imgSrc={this.state.photos[1]} />
          <Group title="Adolescenți" imgSrc={this.state.photos[2]} />
          <Group title="Copii" imgSrc={this.state.photos[3]} />
        </div>

        <div className="preview-more">
          <Link to={{ pathname: '/lucrari', search: window.location.search }}>
            <span>Citește mai mult</span>
          </Link>
        </div>
      </div>
    );
  }
}
