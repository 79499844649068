import React from 'react';

import EventDetailsDialog from 'ui/admin/admin-events/EventDetailsDialog';
import ExceptionDetailsDialog from 'ui/admin/admin-schedule/ExceptionDetailsDialog';
import PastorDetailsDialog from 'ui/admin/admin-pastors/PastorDetailsDialog';
import MessageDetailsDialog from 'ui/admin/admin-messages/MessageDetailsDialog';
import LiveDetailsDialog from 'ui/admin/admin-live/LiveDetailsDialog';
import AnnouncementDetailsDialog from 'ui/admin/admin-announcements/AnnouncementDetailsDialog';
import CancelledIntervalDetailsDialog from 'ui/admin/admin-schedule/CancelledIntervalDetailsDialog';

const ItemDetailsDialog = props => {
  if (props.type === 'event') {
    return (
      <EventDetailsDialog
        open={props.open}
        handleClose={props.handleClose}
        handleSave={props.handleSave}
        event={props.item}
        dialogType={props.dialogType}
      />
    );
  } else if (props.type === 'exception') {
    return (
      <ExceptionDetailsDialog
        open={props.open}
        handleClose={props.handleClose}
        handleSave={props.handleSave}
        exception={props.item}
        dialogType={props.dialogType}
      />
    );
  } else if (props.type === 'pastor') {
    return (
      <PastorDetailsDialog
        open={props.open}
        handleClose={props.handleClose}
        handleSave={props.handleSave}
        pastor={props.item}
        dialogType={props.dialogType}
      />
    );
  } else if (props.type === 'message') {
    return (
      <MessageDetailsDialog
        open={props.open}
        handleClose={props.handleClose}
        handleSave={props.handleSave}
        message={props.item}
        dialogType={props.dialogType}
      />
    );
  } else if (props.type === 'live') {
    return (
      <LiveDetailsDialog
        open={props.open}
        handleClose={props.handleClose}
        handleSave={props.handleSave}
        video={props.item}
        dialogType={props.dialogType}
      />
    );
  } else if (props.type === 'announcement') {
    return (
      <AnnouncementDetailsDialog
        open={props.open}
        handleClose={props.handleClose}
        handleSave={props.handleSave}
        announcement={props.item}
        dialogType={props.dialogType}
      />
    );
  } else if (props.type === 'cancelledInterval') {
    return (
      <CancelledIntervalDetailsDialog
        open={props.open}
        handleClose={props.handleClose}
        handleSave={props.handleSave}
        cancelledInterval={props.item}
        dialogType={props.dialogType}
      />
    );
  }
};

export default ItemDetailsDialog;
