import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';

import Homepage from 'ui/pages/homepage/Homepage';
import About from 'ui/pages/about/About';
import Ministries from 'ui/pages/ministries/Ministries';
import Pastors from 'ui/pages/pastors/Pastors';
import Mission from 'ui/pages/mission/Mission';
import Contact from 'ui/pages/contact/Contact';
import Events from 'ui/pages/events/Events';
import Messages from 'ui/pages/messages/Messages';
import Live from 'ui/pages/live/Live';
import Announcements from 'ui/pages/announcements/Announcements';
import Admin from 'ui/admin/admin/Admin';
import Footer from 'ui/utils/footer/Footer';

class Main extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      shouldDisplayFooter:
        this.props.location.pathname !== '/' &&
        !this.props.location.pathname.includes('admin')
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({
        shouldDisplayFooter:
          this.props.location.pathname !== '/' &&
          !this.props.location.pathname.includes('admin')
      });
    }
  }

  render() {
    return (
      <div className="main">
        <Switch>
          <Route exact path="/" component={Homepage} />
          <Route exact path="/despre" component={About} />
          <Route exact path="/lucrari" component={Ministries} />
          <Route exact path="/prezbiteri" component={Pastors} />
          <Route exact path="/misiune" component={Mission} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/evenimente" component={Events} />
          <Route exact path="/mesaje" component={Messages} />
          <Route exact path="/live" component={Live} />
          <Route exact path="/anunturi" component={Announcements} />
          <Route path="/admin" component={Admin} />
        </Switch>
        {this.state.shouldDisplayFooter && <Footer />}
      </div>
    );
  }
}

export default withRouter(Main);
