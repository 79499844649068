import React from 'react';
import styled, { keyframes } from 'styled-components';
import { fadeIn, fadeInUp } from 'react-animations';

class AnimatedWrapper extends React.Component {
  render() {
    if (this.props.type === 'fadeIn') {
      const fadeInAnimation = keyframes`${fadeIn}`;
      const duration = `${this.props.duration ? this.props.duration : 0.3}s`;
      const FadeInWrapper = styled.div`
        animation: ${duration} ${fadeInAnimation};
      `;

      return <FadeInWrapper>{this.props.children}</FadeInWrapper>;
    } else if (this.props.type === 'fadeInUp') {
      const fadeInUpAnimation = keyframes`${fadeInUp}`;
      const FadeInUpWrapper = styled.div`
        animation: 0.3s ${fadeInUpAnimation};
      `;
      return <FadeInUpWrapper>{this.props.children}</FadeInUpWrapper>;
    }
  }
}

export default AnimatedWrapper;
