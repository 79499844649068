import React from 'react';
import moment from 'moment';
import 'whatwg-fetch';

import constants from 'ui/constants';
import AnimatedWrapper from 'ui/utils/animated-wrapper/AnimatedWrapper';

import './upcoming-events.scss';

class UpcomingEvents extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      events: []
    };
    this._unmounted = false;

    this.getUpcomingEvents = this.getUpcomingEvents.bind(this);
    this.setUpcomingEvents = this.setUpcomingEvents.bind(this);
    this.displaySpecialUpcomingEvent =
      this.displaySpecialUpcomingEvent.bind(this);

    this.getUpcomingEvents();
  }

  componentWillUnmount() {
    this._unmounted = true;
  }

  getUpcomingEvents() {
    return fetch(`${constants.backendURL}events`, { method: 'GET' })
      .then(this.setUpcomingEvents)
      .catch(this.onError);
  }

  setUpcomingEvents(response) {
    return response
      .json()
      .then(
        events =>
          !this._unmounted &&
          this.setState({ events: events.filter(event => event.visible) })
      );
  }

  onError() {
    if (this._unmounted) {
      return;
    }
    this.setState({ events: [] });
  }

  displaySpecialUpcomingEvent(event) {
    return (
      <div className="upcoming-events">
        <label>Evenimente:</label>
        <div className="upcoming-events__days">
          <div className="upcoming-event special">
            <img src={event.photoURL} alt="" />
            <div className="upcoming-event__center">
              {event.times.map((time, index) => {
                return (
                  <div key={index} className="upcoming-event__date">
                    <div className="upcoming-event__day">
                      <span>{moment(time.startDate).format('D')}</span>
                      <span>{moment(time.startDate).format('MMMM')}</span>
                    </div>
                    <div className="upcoming-event__time">
                      <span>{moment(time.startDate).format('HH:mm')}</span>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="upcoming-event__title">{event.name}</div>
          </div>
        </div>
      </div>
    );
  }

  displayUpcomingEvents() {
    const specialEvent = this.state.events.find(
      event => event.type && event.type === 'special'
    );

    if (specialEvent) {
      const now = moment(new Date());
      const latestEventTime = specialEvent.times.concat().sort((a, b) => {
        return moment(a.endDate).diff(moment(b.endDate)) < 0;
      })[0];
      const eventIsInTheFutureOrOngoing = now.isSameOrBefore(
        latestEventTime.endDate
      );

      if (eventIsInTheFutureOrOngoing) {
        return this.displaySpecialUpcomingEvent(specialEvent);
      }

      return null;
    }

    return (
      <div className="upcoming-events">
        <label>Evenimente:</label>
        <div className="upcoming-events__days">
          {this.state.events
            .filter(event => moment(event.endDate).isAfter(moment()))
            .map((item, key) => {
              return (
                <div className="upcoming-event" key={key}>
                  <div className="upcoming-event__date">
                    <div className="upcoming-event__day">
                      <span>{moment(item.startDate).format('D')}</span>
                      <span className="upcoming-event__day__medium">
                        {moment(item.startDate).format('MMMM')}
                      </span>
                      <span className="upcoming-event__day__small">
                        {moment(item.startDate).format('.MM')}
                      </span>
                    </div>
                    <div className="upcoming-event__time">
                      <span>{moment(item.startDate).format('HH:mm')}</span>
                    </div>
                  </div>
                  <div className="upcoming-event__title">{item.name}</div>
                </div>
              );
            })}
        </div>
      </div>
    );
  }

  render() {
    if (!this.state.events.length) {
      return null;
    }

    return (
      <AnimatedWrapper type="fadeIn">
        {this.displayUpcomingEvents()}
      </AnimatedWrapper>
    );
  }
}

export default UpcomingEvents;
