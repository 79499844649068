import React from 'react';

import moment from 'moment';
import { TableRow, Checkbox } from '@material-ui/core';
import classNames from 'classnames';

import CustomTableCell from 'ui/admin/custom-table/CustomTableCell';

const AnnouncementsTableRow = props => {
  const { announcement, editMode, onClickAnnouncement, selectAnnouncement, index } = props;

  return (
    <TableRow
      className={classNames('admin-table__row', { editable: editMode })}
      onClick={onClickAnnouncement.bind(this, announcement)}
    >
      {editMode && (
        <CustomTableCell padding="checkbox" center={true}>
          <Checkbox
            style={{ fontSize: 20 }}
            checked={announcement.isSelected ? announcement.isSelected : false}
            onChange={selectAnnouncement.bind(null, index, null)}
          />
        </CustomTableCell>
      )}
      <CustomTableCell padding="default" center={false} editMode={editMode}>
        {moment(announcement.date).locale('ro').format('D MMM YYYY')}
      </CustomTableCell>
      <CustomTableCell padding="default" center={false} editMode={editMode}>
        {announcement.title}
      </CustomTableCell>
      <CustomTableCell padding="default" center={false} editMode={editMode}>
        {announcement.message}
      </CustomTableCell>
      <CustomTableCell padding="default" center={false} editMode={editMode}>
        {announcement.isFlagged ? 'Da' : 'Nu'}
      </CustomTableCell>
    </TableRow>
  );
};

export default AnnouncementsTableRow;
