import React, { Component } from 'react';

import classNames from 'classnames';

import './PreviewHeader.scss';

export default class Preview extends Component {
  render() {
    const { headerText } = this.props;

    return (
      <div className="preview-header">
        <div className="preview-header__content">
          <span
            className={classNames('preview-header__content__text', {
              dark: this.props.dark
            })}
          >
            {headerText}
          </span>
          <div
            className={classNames('preview-header__bottom-border', {
              dark: this.props.dark
            })}
          ></div>
        </div>
      </div>
    );
  }
}
