import React from 'react';
import { TableCell } from '@material-ui/core';

const CustomTableCell = props => {
  const style = {
    textAlign: props.center ? 'center' : 'left',
    padding: props.padding === 'default' ? props.padding : '0 5px'
  };

  return (
    <TableCell style={style} padding={props.padding}>
      {props.children ? props.children : '-'}
    </TableCell>
  );
};

export default CustomTableCell;
