import React, { Component } from 'react';

import './pastor.scss';

export default class Pastor extends Component {
  render() {
    return (
      <div className="preview-pastor">
        <div className="preview-pastor__content">
          <div
            className="preview-pastor__content__photo"
            style={{ backgroundImage: `url(${this.props.data.photoURL})` }}
          >
            {!this.props.data.photoURL && <i className="fa fa-user-circle"></i>}
          </div>
          <div className="preview-pastor__details">
            <span>{this.props.data.name}</span>
            <span />
          </div>
        </div>
      </div>
    );
  }
}
