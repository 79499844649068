import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Drawer, Divider, List, ListItem } from '@material-ui/core';
import classNames from 'classnames';

import './admin-menu.scss';

class AdminMenu extends Component {
  isSelected(pathname) {
    return this.props.location.pathname === pathname;
  }

  getListItem(path, name) {
    return (
      <ListItem
        button
        className={classNames({ selected: this.isSelected(path) })}
        onClick={this.props.onClose}
      >
        <Link to={path}>
          <span>{name}</span>
        </Link>
      </ListItem>
    );
  }

  getDrawerContent() {
    return (
      <div className="admin-menu__wrapper">
        <div className="admin-menu__toolbar" />
        <Divider />
        <List className="admin-menu__list">
          {this.getListItem('/admin/program', 'Program')}
          {this.getListItem('/admin/evenimente', 'Evenimente')}
          {this.getListItem('/admin/mesaje', 'Mesaje')}
          {this.getListItem('/admin/live', 'Live')}
          {this.getListItem('/admin/anunturi', 'Anunturi')}
        </List>
        <Divider />
        <List className="admin-menu__list">
          {this.getListItem('/admin/prezbiteri', 'Prezbiteri')}
          {this.getListItem('/admin/contact', 'Contact')}
        </List>
        <Divider />
      </div>
    );
  }

  render() {
    return (
      <div>
        <Drawer
          className="admin-menu"
          variant={this.props.isPermanent ? 'permanent' : 'temporary'}
          anchor="left"
          open={this.props.isPermanent || this.props.isOpen}
          onClose={this.props.onClose}
        >
          {this.getDrawerContent()}
        </Drawer>
        <Drawer
          variant="permanent"
          open
          className="admin-menu__permanent admin-menu"
        >
          {this.getDrawerContent()}
        </Drawer>
      </div>
    );
  }
}

export default withRouter(AdminMenu);
