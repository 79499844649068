import ReactGA from 'react-ga';

const pageViews = {
    registerPageView: () => {
        ReactGA.set({ page: window.location.pathname + window.location.search });
        ReactGA.pageview(window.location.pathname + window.location.search);
    }
};

export default pageViews;
