import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import ReactGA from 'react-ga';
import moment from 'moment';
import 'moment/locale/ro';

import Main from 'ui/main/Main';
import Menu from 'ui/utils/menu/Menu';

import './index.scss';

const theme = createMuiTheme({
  typography: { fontFamily: 'Bahnschrift' }
});

class App extends React.Component {
  constructor() {
    super();

    moment.locale('ro');
    this.disableHoverOnScroll();
    ReactGA.initialize('UA-107518137-1');
  }

  componentDidMount() {
    document.onscroll = () => {
      document.documentElement.style.setProperty(
        '--100vh',
        window.innerHeight + 'px'
      );
    };
  }

  disableHoverOnScroll() {
    let timer;

    window.addEventListener(
      'scroll',
      function () {
        clearTimeout(timer);
        if (!document.body.classList.contains('disable-hover')) {
          document.body.classList.add('disable-hover');
        }

        timer = setTimeout(function () {
          document.body.classList.remove('disable-hover');
        }, 200);
      },
      false
    );
  }

  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <div className="root-child">
          <Router>
            <Menu />
            <Main />
          </Router>
        </div>
      </MuiThemeProvider>
    );
  }
}

render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root')
);

export default App;
