import React, { Component } from 'react';
import { Button, TextField } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogTitle, withMobileDialog } from '@material-ui/core';

class PastorDetailsDialog extends Component {
  constructor(props) {
    super(props);

    this.state = this.getBlankPastor();
  }

  getBlankPastor = (props = this.props) => {
    return {
      pastorId: props.pastor.pastorId || null,
      name: props.pastor.name || '',
      photoURL: props.pastor.photoURL || '',
      description: props.pastor.description || ''
    };
  };

  componentDidUpdate(prevPops) {
    if (prevPops.open !== this.props.open) {
      const newState = this.getBlankPastor(this.props);
      this.setState(newState);
    }
  }

  onUpdateName = event => {
    this.setState({ name: event.target.value });
  };

  onUpdatePhotoURL = event => {
    this.setState({ photoURL: event.target.value });
  };

  onUpdateDescription = event => {
    this.setState({ description: event.target.value });
  };

  onCloseDialog = () => {
    this.setState(this.getBlankPastor());
    this.props.handleClose();
  };

  onSavePastor = () => {
    const newPastor = {
      name: this.state.name,
      photoURL: this.state.photoURL,
      description: this.state.description
    };

    if (this.state.pastorId) {
      newPastor.pastorId = this.state.pastorId;
    }

    this.props.handleSave(newPastor);
    this.setState(this.getBlankPastor());
  };

  render() {
    const { fullScreen } = this.props;

    return (
      <div>
        <Dialog
          className="admin-dialog"
          fullScreen={fullScreen}
          open={this.props.open}
          onClose={this.props.handleClose}
        >
          <DialogTitle>
            {this.props.dialogType === 'create'
              ? 'Adaugă detalii despre un prezbiter'
              : 'Editează detaliile despre prezbiter'}
          </DialogTitle>
          <DialogContent>
            <div>
              <div className="admin-row">
                <TextField
                  label="Nume"
                  className="admin-input"
                  value={this.state.name}
                  onChange={this.onUpdateName}
                  fullWidth
                />
              </div>
              <div className="admin-row">
                <TextField
                  label="URL poză"
                  className="admin-input"
                  value={this.state.photoURL}
                  onChange={this.onUpdatePhotoURL}
                  fullWidth
                />
              </div>
              <div className="admin-row">
                <TextField
                  label="Descriere"
                  className="admin-input"
                  rows={8}
                  style={{ overflowY: 'auto' }}
                  value={this.state.description}
                  onChange={this.onUpdateDescription}
                  multiline
                  fullWidth
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.onCloseDialog} className="admin-dialog__button" color="primary">
              Anulează
            </Button>
            <Button onClick={this.onSavePastor} className="admin-dialog__button" color="primary" autoFocus>
              Salvează
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withMobileDialog()(PastorDetailsDialog);
