import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './Group.scss';

export default class Group extends Component {
  constructor(props) {
    super(props);

    this.style = {
      backgroundImage: `url(${this.props.imgSrc})`
    };
  }

  render() {
    const { title } = this.props;

    return (
      <div className="group">
        <Link to={{ pathname: '/lucrari', search: window.location.search }}>
          <div className="group__content" style={this.style}>
            <div className="group__content__title">
              <span>{title}</span>
            </div>
          </div>
        </Link>
      </div>
    );
  }
}
