import React, { Component } from 'react';
import { Snackbar, IconButton } from '@material-ui/core';
import { Clear } from '@material-ui/icons';

import CustomTable from 'ui/admin/custom-table/CustomTable';

import constants from 'ui/constants';

class AdminAnnouncements extends Component {
  constructor() {
    super();

    this.state = {
      snackbarOpen: false,
      snackbarMessage: '',
      headerCells: [
        { name: 'Data', padding: 'default', center: false },
        { name: 'Titlu', padding: 'default', center: false },
        { name: 'Mesaj', padding: 'default', center: false },
        { name: 'Pe prima pagină', padding: 'default', center: false }
      ]
    };
  }

  displaySnackbar = (type, positive, length) => {
    let snackbarMessage = '';

    if (type === 'create') {
      if (positive) {
        snackbarMessage = 'Anunțul a fost adăugat.';
      } else {
        snackbarMessage = 'Anunțul nu a putut fi adăugat.';
      }
    } else if (type === 'edit') {
      if (positive) {
        snackbarMessage = 'Anunțul a fost modificat.';
      } else {
        snackbarMessage = 'Anunțul nu a putut fi modificat.';
      }
    } else {
      if (positive) {
        snackbarMessage = length > 1 ? 'Anunțurile au fost șterse.' : 'Anunțul a fost șters.';
      } else {
        snackbarMessage = length > 1 ? 'Anunțurile nu au putut fi șterse.' : 'Anunțul nu a putut fi șters.';
      }
    }

    this.setState({ snackbarOpen: true, snackbarMessage });
  };

  onCloseSnackbar = () => {
    this.setState({ snackbarOpen: false });
  };

  render() {
    return (
      <div className="admin-announcements">
        <CustomTable
          identifier="announcementId"
          itemType="announcement"
          tableTitle="Anunțuri"
          backendURL={`${constants.backendURL}announcements`}
          displaySnackbar={this.displaySnackbar}
          headerCells={this.state.headerCells}
        />
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={this.state.snackbarOpen}
          onClose={this.onCloseSnackbar}
          autoHideDuration={3000}
          message={<span>{this.state.snackbarMessage}</span>}
          snackbarcontentprops={{
            style: { backgroundColor: '#ffffff', color: '#191919' }
          }}
          action={[
            <IconButton key="close" aria-label="Close" color="inherit" onClick={this.onCloseSnackbar}>
              <Clear />
            </IconButton>
          ]}
        />
      </div>
    );
  }
}

export default AdminAnnouncements;
