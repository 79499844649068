import React, { Component } from 'react';

import {
  Dialog,
  withMobileDialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  TextField,
  MenuItem
} from '@material-ui/core';
import moment from 'moment';

class ExceptionDetailsDialog extends Component {
  constructor(props) {
    super(props);

    this.state = this.getBlankException();
  }

  getBlankException = (props = this.props) => {
    return {
      exceptionId: props.exception.exceptionId || null,
      name: props.exception.name || '',
      type: props.exception.eveningStartTime ? 'evening' : 'morning',
      date: this.getExceptionDate(props.exception),
      time: this.getExceptionTime(props.exception)
    };
  };

  getExceptionDate = exception => {
    if (exception.morningStartTime || exception.eveningStartTime) {
      const time = exception.morningStartTime || exception.eveningStartTime;
      return time;
    }

    const today = moment(new Date());
    if (today.day() === 7) {
      return today;
    }
    return moment().day(7);
  };

  getExceptionTime = exception => {
    if (exception.morningStartTime || exception.eveningStartTime) {
      const time = exception.morningStartTime || exception.eveningStartTime;
      return time;
    }

    return moment(this.date).set({ hours: 10, minutes: 0 });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      const newState = this.getBlankException(this.props);
      this.setState(newState);
    }
  }

  onUpdateName = event => {
    this.setState({ name: event.target.value });
  };

  onUpdateDate = event => {
    const newDate = moment(event.target.value);
    this.setState({ date: newDate });
  };

  onUpdateTime = event => {
    const data = event.target.value.split(':');
    const hours = data[0];
    const minutes = data[1];
    const newTime = moment(this.state.time).set({ hours, minutes });
    this.setState({ time: newTime });
  };

  onUpdateType = event => {
    const type = event.target.value;
    this.setState({ type });
  };

  onCloseDialog = () => {
    this.setState(this.getBlankException());
    this.props.handleClose();
  };

  onSaveException = () => {
    const newException = {
      name: this.state.name
    };

    const property =
      this.state.type === 'morning' ? 'morningStartTime' : 'eveningStartTime';
    newException.date = null;
    newException[property] = moment(this.state.date)
      .set({
        hours: this.state.time.hours(),
        minutes: this.state.time.minutes()
      })
      .toISOString();

    if (this.state.exceptionId) {
      newException.exceptionId = this.state.exceptionId;
    }

    this.props.handleSave(newException);
    this.setState(this.getBlankException());
  };

  render() {
    const { fullScreen } = this.props;

    return (
      <div>
        <Dialog
          className="admin-dialog"
          fullScreen={fullScreen}
          open={this.props.open}
          onClose={this.props.handleClose}
        >
          <DialogTitle>
            {this.props.dialogType === 'create'
              ? 'Adaugă o excepție'
              : 'Editează excepția'}
          </DialogTitle>
          <DialogContent>
            <div>
              <div className="admin-row">
                <TextField
                  label="Nume"
                  style={{ flex: 2 }}
                  className="admin-input"
                  value={this.state.name}
                  onChange={this.onUpdateName}
                />
                <TextField
                  select
                  label="Tip"
                  className="admin-input select"
                  value={this.state.type}
                  onChange={this.onUpdateType}
                  style={{ minWidth: '100px' }}
                >
                  <MenuItem value="morning">dimineața</MenuItem>
                  <MenuItem value="evening">seara</MenuItem>
                </TextField>
              </div>
              <div className="admin-row">
                <TextField
                  label="Dată"
                  type="date"
                  className="admin-input date"
                  value={moment(this.state.date).format('YYYY-MM-DD')}
                  onChange={this.onUpdateDate}
                  style={{ flex: 1, fontSize: '14px' }}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
                <TextField
                  label="Oră"
                  type="time"
                  className="admin-input date"
                  value={moment(this.state.time).format('HH:mm')}
                  onChange={this.onUpdateTime}
                  style={{ flex: 1, fontSize: '14px' }}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.onCloseDialog}
              className="admin-dialog__button"
              color="primary"
            >
              Anulează
            </Button>
            <Button
              onClick={this.onSaveException}
              className="admin-dialog__button"
              color="primary"
              autoFocus
            >
              Salvează
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withMobileDialog()(ExceptionDetailsDialog);
