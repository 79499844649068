const ministriesData = [
  {
    photoURL: 'https://i.imgur.com/IYYpG5h.jpg',
    // mediumPreviewPhotoURL: 'https://i.imgur.com/t4uZk2Rl.jpg',
    mediumPreviewPhotoURL: 'https://i.imgur.com/IYYpG5h.jpg',
    // largePreviewPhotoURL: 'https://i.imgur.com/t4uZk2Rh.jpg',
    largePreviewPhotoURL: 'https://i.imgur.com/IYYpG5h.jpg',
    content: {
      title: 'Tineri',
      subtitle: '',
      sections: [
        {
          title: '',
          paragraphs: [
            `Suntem un grup de tineri care dorim să ne maturizăm spiritual pentru a avea impact în Biserică și în societate.`,
            `Urmărim să-L glorificam pe Dumnezeu în tinerețea noastră, să dezvoltăm relații interpersonale sănătoase și să exprimăm o credință vie prin implicarea noastră în slujire și prin comunicarea mesajului Evangheliei celor care nu îl cunosc.`
          ]
        },
        {
          title: '',
          centerTitle: true,
          paragraphs: [],
          images: [
            'https://i.imgur.com/53kxEw7.png',
            'https://i.imgur.com/wqKMJbq.png',
            'https://i.imgur.com/uZfuwG0.png',
            'https://i.imgur.com/l7pA9vn.png'
          ]
        }
      ]
    }
  },
  {
    photoURL: 'https://i.imgur.com/UTWvneR.jpg',
    mediumPreviewPhotoURL: 'https://i.imgur.com/UTWvneRl.jpg',
    largePreviewPhotoURL: 'https://i.imgur.com/UTWvneRh.jpg',
    content: {
      title: 'Cor',
      subtitle: '',
      sections: [
        {
          title: '',
          paragraphs: []
        }
      ]
    }
  },
  {
    photoURL: 'https://i.imgur.com/UwQRo4M.jpg',
    mediumPreviewPhotoURL: 'https://i.imgur.com/UwQRo4Mm.jpg',
    largePreviewPhotoURL: 'https://i.imgur.com/UwQRo4Mh.jpg',
    content: {
      title: 'Adolescenți',
      subtitle: '',
      sections: [
        {
          title: '',
          paragraphs: [
            `La întrebarea: “Ce nu vă place în biserică?”, unii dintre adolescenți au reclamat ipocrizia. Aceștia sesizează imediat dacă te prezinți înaintea lor predicând, dar nu și demonstrând cu propria viață cele spuse.`,
            `Viziunea lucrării este de a sădi în inimile adolescenților o dragoste necondiționată pentru Dumnezeu și de a-i învăța să-și stabilească un sistem de valori propriu, puternic ancorat în Scriptură, pentru a fi pregătiți să ia cele mai bune decizii în viață.`
          ]
        },
        {
          title: '',
          paragraphs: [
            `Ne întâlnim săptămânal, iar lunar avem o întâlnire formală în care facem împreună pizza, clătite, brioşe sau alte bunătăţi, jucăm jocuri, ne rugăm sau urmărim împreună ceva interesant.`
          ]
        }
      ]
    }
  },
  {
    photoURL: 'https://i.imgur.com/W9Cn6gy.jpg',
    mediumPreviewPhotoURL: 'https://i.imgur.com/W9Cn6gyl.jpg',
    largePreviewPhotoURL: 'https://i.imgur.com/W9Cn6gyh.jpg',
    content: {
      title: 'Copii',
      subtitle: '',
      sections: [
        {
          title: '',
          paragraphs: [
            `Scopul nostru este ca ei să ajungă să Îl cunoască pe Dumnezeu ca Domn şi Mântuitor personal.`,
            `Să înţeleagă, chiar dacă au crescut în biserică, că au nevoie de iertarea păcatelor ca să fie mântuiţi. Faptul că au părinţi creştini nu le garantează un loc în cer.`
          ]
        },
        {
          title: '',
          paragraphs: [
            `Ne întâlnim săptămânal, iar din când în când facem câte o ieşire împreună.`
          ]
        }
      ]
    }
  }
];

export default ministriesData;
