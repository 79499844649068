import React from 'react';
import moment from 'moment';

import constants from 'ui/constants';
import AnimatedWrapper from 'ui/utils/animated-wrapper/AnimatedWrapper';

import './schedule.scss';

class Schedule extends React.Component {
  _isMounted = false;

  constructor() {
    super();
    const nextService = this.getNextService();

    this.state = {
      nextService: nextService,
      hours: [
        moment(nextService).day() === 0
          ? moment(nextService).set({ h: 10, m: 0 })
          : moment(nextService).set({ h: 19, m: 0 })
      ],
      exception: null,
      isTemporarilyCancelled: null,
      scheduleLoaded: false
    };

    this.getScheduleHours = this.getScheduleHours.bind(this);
    this.getNextService = this.getNextService.bind(this);
    this.setException = this.setException.bind(this);
    this.getCancelledIntervals = this.getCancelledIntervals.bind(this);
    this.setCancelledInterval = this.setCancelledInterval.bind(this);
    this.onError = this.onError.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.getScheduleData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getScheduleData() {
    this.getScheduleExceptions().then(this.getCancelledIntervals);
  }

  getScheduleExceptions() {
    return fetch(`${constants.backendURL}schedule-exceptions`, {
      method: 'GET'
    })
      .then(this.setException)
      .catch(this.onError);
  }

  getCancelledIntervals() {
    return fetch(`${constants.backendURL}cancelled-intervals`, {
      method: 'GET'
    })
      .then(this.setCancelledInterval)
      .catch(this.onError);
  }

  setException(response) {
    response.json().then(exceptions => {
      let hours = this.state.hours;
      const todayException = exceptions.find(exception => {
        const exceptionDate =
          exception.morningStartTime || exception.eveningStartTime;

        if (exceptionDate) {
          return (
            moment(this.state.nextSunday).diff(exceptionDate, 'days') === 0
          );
        } else {
          return null;
        }
      });

      if (todayException) {
        hours = [];

        if (todayException.morningStartTime) {
          hours.push(moment(todayException.morningStartTime));
        }

        if (todayException.eveningStartTime) {
          hours.push(moment(todayException.eveningStartTime));
        }
      }

      if (this._isMounted) {
        this.setState({
          exception: todayException ? todayException : null,
          hours
        });
      }
    });
  }

  setCancelledInterval(response) {
    return response.json().then(cancelledIntervals => {
      if (!cancelledIntervals.error) {
        const currentCancelledInterval =
          cancelledIntervals &&
          cancelledIntervals.find(interval => {
            if (!interval.until) {
              return moment(interval.from).isSameOrBefore(
                this.state.nextSunday,
                'd'
              );
            } else if (interval.from) {
              return (
                moment(interval.from).isSameOrBefore(
                  this.state.nextSunday,
                  'd'
                ) &&
                moment(this.state.nextSunday).isSameOrBefore(
                  interval.until,
                  'd'
                )
              );
            } else {
              return false;
            }
          });

        if (this._isMounted) {
          this.setState({
            isTemporarilyCancelled:
              !!currentCancelledInterval?.shouldTemporarilyCancel,
            nextSunday: !currentCancelledInterval?.shouldTemporarilyCancel
              ? this.getNextSunday(new Date(currentCancelledInterval.until))
              : this.state.nextSunday,
            scheduleLoaded: true
          });
        }
      } else if (this._isMounted) {
        this.setState({ hours: this.getScheduleHours() });
      }
    });
  }

  onError() {
    if (this._isMounted) {
      this.setState({
        exception: null,
        cancelledInterval: null,
        scheduleLoaded: true
      });
    }
  }

  getNextService(date = new Date()) {
    const today = moment(new Date());
    const isSunday =
      today.day() === 0 &&
      this.isBeforeMorning(today) &&
      moment(date).isSameOrBefore(today, 'd');
    const isWednesday =
      today.day() === 3 &&
      this.isBeforeEvening(today) &&
      moment(date).isSameOrBefore(today, 'd');

    if (isSunday || isWednesday) {
      return today;
    } else if (today.day() >= 3) {
      return moment(date).day(7).set({ h: 0, m: 0 });
    } else {
      return moment(date).day(3).set({ h: 0, m: 0 });
    }
  }

  isBeforeMorning(momentDate) {
    return (
      momentDate.hour() < 12 ||
      (momentDate.hour() === 12 && momentDate.minute() <= 15)
    );
  }

  isBeforeEvening(momentDate) {
    return (
      momentDate.hour() < 19 ||
      (momentDate.hour() === 19 && momentDate.minute() <= 15)
    );
  }

  getScheduleHours() {
    if (moment(this.state.nextService).day() === 0) {
      if (moment(this.state.nextService).diff(moment(), 'd') > 0) {
        return [
          moment(this.state.nextService).set({ h: 10, m: 0 }),
          moment(this.state.nextService).set({ h: 18, m: 0 })
        ];
      } else {
        const isAfterNoon = moment().hour() >= 12;

        if (!isAfterNoon) {
          return [
            moment(this.state.nextService).set({ h: 10, m: 0 }),
            moment(this.state.nextService).set({ h: 18, m: 0 })
          ];
        } else if (isAfterNoon && this.isBeforeEvening(moment())) {
          return [moment(this.state.nextService).set({ h: 18, m: 0 })];
        }
      }
    } else {
      return [moment(this.state.nextService).set({ h: 19, m: 0 })];
    }
  }

  displayNextService() {
    return <span>{moment(this.state.nextService).format('D MMMM')}</span>;
  }

  displayScheduleHours() {
    return this.state.hours.map((item, key) => {
      return (
        <span className="hour" key={key}>
          {item.format('HH:mm')}
        </span>
      );
    });
  }

  render() {
    if (!this.state.scheduleLoaded) {
      return null;
    } else if (this.state.isTemporarilyCancelled) {
      return (
        <AnimatedWrapper type="fadeIn">
          <div className="schedule canceled">
            <div className="schedule__content">
              <label>Program:</label>
              <div className="schedule__dates canceled">
                Momentan, toate întâlnirile sunt suspendate.
              </div>
            </div>
          </div>
        </AnimatedWrapper>
      );
    }

    return (
      <AnimatedWrapper type="fadeIn">
        <div className="schedule">
          <div className="schedule__content">
            <label>
              {this.state.hours.length === 0 && 'Următoarele întâlniri:'}
              {this.state.hours.length > 1 && 'Următoarele întâlniri:'}
              {this.state.hours.length === 1 && 'Următoarea întâlnire:'}
            </label>
            <div className="schedule__dates">
              <span className="schedule__sunday">
                {moment(this.state.nextService).day() === 0
                  ? 'Duminică'
                  : 'Miercuri'}
              </span>
              <div className="schedule__next-sunday">
                {this.state.nextService && this.displayNextService()}
              </div>
              <div className="schedule__hours">
                {this.state.hours.length && this.displayScheduleHours()}
              </div>
            </div>
          </div>
        </div>
      </AnimatedWrapper>
    );
  }
}

export default Schedule;
