import React from 'react';
import 'whatwg-fetch';

import PageTitle from 'ui/utils/page-title/PageTitle';
import GoogleMap from 'ui/utils/google-map/GoogleMap';
import missionData from 'ui/data/missionData';
import pageViews from 'ui/utils/google-analytics/pageViews';
import isInternetExplorer from 'ui/utils/detectInternetExplorer';

import './mission.scss';

class Mission extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // backgroundImageUrl: 'https://i.imgur.com/tZZftX2h.jpg'
      backgroundImageUrl: 'https://i.imgur.com/5BkAoK2.jpg'
    };
    this.getGoogleMap = this.getGoogleMap.bind(this);
    this.getMissionMainContent = this.getMissionMainContent.bind(this);
    this.getMissionContent = this.getMissionContent.bind(this);
  }

  componentDidMount() {
    pageViews.registerPageView();
    document.title = 'Misiune – Biserica Vestea Bună Iași';
    window.scrollTo(0, 0);
  }

  getMissionContent(mission) {
    const getWorkersSubtitles = () =>
      mission.workers.subtitles.map((subtitle, key) => (
        <span key={key}>{subtitle}</span>
      ));
    const getSectionParagraphs = section =>
      section.paragraphs.map((paragraph, key) => (
        <span key={key}>{paragraph}</span>
      ));
    const getSections = () =>
      mission.sections.map((section, key) => (
        <div className="section" key={key}>
          <span className="section__title">{section.title}</span>
          {getSectionParagraphs(section)}
        </div>
      ));

    return (
      <div>
        <div className="mission__item__flex">
          <div className="mission__item__heading">
            <span className="mission__item__title">{mission.title}</span>
            <img src={mission.photoURL} alt="" />
          </div>

          <div className="mission__item__map">
            {!isInternetExplorer() && this.getGoogleMap(mission)}
          </div>
        </div>

        <div className="mission__item__content">
          <div className="mission__item__workers">
            <img src={mission.workers.photoURL} alt="" />
            <div className="workers-info">
              <a href={mission.workers.infoURL} target="blank">
                <span className="workers-name">
                  {mission.workers.names}
                  <i className="fa fa-external-link" aria-hidden="true" />
                </span>
              </a>
              <div className="workers-subtitles">{getWorkersSubtitles()}</div>
            </div>
          </div>
          {getSections()}
        </div>
      </div>
    );
  }

  getGoogleMap(mission) {
    return (
      <GoogleMap
        zoom={mission.map.zoomLevel}
        zoomControl={false}
        markers={mission.map.markers}
        center={mission.map.center}
        styleMap={missionData.styleMap}
      />
    );
  }

  getMissionMainContent(items) {
    return (
      <div className="mission__main">
        {items.map((item, key) => (
          <div className="mission__item vestea-buna" key={key}>
            {this.getMissionContent(item)}
          </div>
        ))}
      </div>
    );
  }

  render() {
    return (
      <div className="mission page-wrapper">
        <PageTitle
          title="Misiune"
          backgroundImage={this.state.backgroundImageUrl}
        />
        {this.getMissionMainContent(missionData.items)}
      </div>
    );
  }
}

export default Mission;
