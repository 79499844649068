import React from 'react';
import PageTitle from 'ui/utils/page-title/PageTitle';
import pageViews from 'ui/utils/google-analytics/pageViews';

class Events extends React.Component {
  constructor(props) {
    super(props);
    pageViews.registerPageView();
  }

  componentDidMount() {
    document.title = 'Evenimente – Biserica Vestea Bună Iași';
  }

  render() {
    return (
      <div className="events page-wrapper">
        <PageTitle title="Evenimente" />
      </div>
    );
  }
}

export default Events;
