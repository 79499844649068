import React, { Component } from 'react';
import {
  Paper,
  Table,
  TableHead,
  TableBody,
  Toolbar,
  Typography,
  IconButton
} from '@material-ui/core';
import { Delete, Add, Edit, Check } from '@material-ui/icons';
import classNames from 'classnames';
import axios from 'axios';

import ItemDetailsDialog from './ItemDetailsDialog';
import CustomTableRow from './CustomTableRow';
import CustomTableHeader from './CustomTableHeader';

import './custom-table.scss';

class CustomPaginatedTable extends Component {
  constructor(props) {
    super(props);

    this.numberOfResultsPerPage = 8;

    this.state = {
      itemsPages: {},
      totalCount: 0,
      currentPage: 0,
      dialogOpen: false,
      editMode: false,
      selectedCount: 0,
      dialogItem: {},
      dialogType: 'create'
    };

    this.getItems(0);
  }

  setItems = (response, pageNumber) => {
    return response.json().then(responseData => {
      this.setState({
        itemsPages: {
          ...this.state.itemsPages,
          [pageNumber]: responseData[this.props.propertyName]
        },
        totalCount: responseData.totalCount,
        currentPage: pageNumber
      });
    });
  };

  getItems = pageNumber => {
    const requestUrl = `${this.props.backendURL}?resultsPerPage=${
      this.numberOfResultsPerPage
    }&skip=${pageNumber * this.numberOfResultsPerPage}`;

    return fetch(requestUrl, { method: 'GET' })
      .then(response => this.setItems(response, pageNumber))
      .catch(this.onError);
  };

  removeItems = () => {
    const itemsToBeDeleted = this.state.itemsPages[
      this.state.currentPage
    ].filter(item => item.isSelected);

    Promise.all(itemsToBeDeleted.map(item => this.removeItem(item)))
      .then(() =>
        this.props.displaySnackbar('remove', true, itemsToBeDeleted.length)
      )
      .then(() => this.clearItems())
      .catch(() =>
        this.props.displaySnackbar('remove', false, itemsToBeDeleted.length)
      );
  };

  addItem = item => {
    return axios({
      method: 'post',
      url: this.props.backendURL,
      headers: {
        'Content-Type': 'application/json'
      },
      data: JSON.stringify(item)
    });
  };

  editItem = item => {
    return axios({
      method: 'put',
      url: `${this.props.backendURL}/${item[this.props.identifier]}`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: JSON.stringify(item)
    });
  };

  removeItem = item => {
    return axios({
      method: 'delete',
      url: `${this.props.backendURL}/${item[this.props.identifier]}`
    }).then(() => this.clearItems());
  };

  selectItem = (key, value) => {
    const selectedItem = this.state.itemsPages[this.state.currentPage][key];

    if (selectedItem) {
      selectedItem.isSelected =
        value !== null ? value : !selectedItem.isSelected;
      this.setState({
        items: [
          ...this.state.itemsPages[this.state.currentPage].slice(0, key),
          selectedItem,
          ...this.state.itemsPages[this.state.currentPage].slice(key + 1)
        ],
        selectedCount: this.state.itemsPages[this.state.currentPage].filter(
          item => item.isSelected
        ).length
      });
    }
  };

  selectAllItems = () => {
    const selectValue =
      this.state.selectedCount ===
      this.state.itemsPages[this.state.currentPage].length
        ? false
        : true;
    this.state.itemsPages[this.state.currentPage].forEach((item, key) =>
      this.selectItem(key, selectValue)
    );
  };

  deselectAllItems = () => {
    this.state.itemsPages[this.state.currentPage].forEach((item, key) =>
      this.selectItem(key, false)
    );
  };

  toggleEditMode = () => {
    this.deselectAllItems();
    this.setState({ editMode: !this.state.editMode });
  };

  changePage = pageNumber => {
    if (!this.state.itemsPages[pageNumber]) {
      this.getItems(pageNumber).then(() => {
        window.scrollTo(0, 0);
      });
    } else {
      this.setState({ currentPage: pageNumber });
    }
  };

  clearItems = () => {
    this.setState({ itemsPages: {}, currentPage: 0, totalCount: 0 });
    this.getItems(0);
  };

  openItemDetailsDialog = () => {
    this.setState({ dialogOpen: true, dialogType: 'create' });
  };

  onCloseItemDetailsDialog = () => {
    this.setState({ dialogOpen: false, dialogItem: {}, dialogType: 'create' });
  };

  onSaveItem = item => {
    this.setState({ dialogOpen: false, dialogItem: {} });

    if (this.state.dialogType === 'create') {
      this.addItem(item)
        .catch(() => this.props.displaySnackbar('create', false))
        .then(() => this.props.displaySnackbar('create', true))
        .then(() => this.clearItems());
    } else {
      this.editItem(item)
        .catch(() => this.props.displaySnackbar('edit', false))
        .then(() => this.props.displaySnackbar('edit', true))
        .then(() => this.clearItems());
    }
  };

  onClickItem = (item, event) => {
    if (event.target.type === 'checkbox' || !this.state.editMode) {
      return;
    }
    this.setState({ dialogOpen: true, dialogItem: item, dialogType: 'edit' });
  };

  onCloseSnackbar = () => {
    this.setState({ snackbarOpen: false });
  };

  displayItems = () => {
    const rootStyle = {
      width: '100%',
      overflowX: 'auto'
    };

    return (
      <Paper className="items" style={rootStyle}>
        {this.displayTableToolbar()}
        <Table style={{ fontSize: '12px' }}>
          <TableHead>
            <CustomTableHeader
              editMode={this.state.editMode}
              rowCount={this.state.itemsPages[this.state.currentPage]?.length}
              selectedCount={this.state.selectedCount}
              selectAll={this.selectAllItems}
              cells={this.props.headerCells}
              type={this.props.itemType}
            />
          </TableHead>
          <TableBody>
            {this.state.itemsPages[this.state.currentPage]?.map((item, key) => (
              <CustomTableRow
                key={key}
                index={key}
                item={item}
                editMode={this.state.editMode}
                onClickItem={this.onClickItem}
                selectItem={this.selectItem}
                type={this.props.itemType}
              />
            ))}
          </TableBody>
        </Table>
      </Paper>
    );
  };

  displayTableToolbar = () => {
    return (
      <Toolbar
        className={classNames('admin-table__toolbar', {
          colored: this.state.selectedCount > 0
        })}
        style={{ minHeight: '52px' }}
      >
        <div>
          {this.state.selectedCount > 0 ? (
            <Typography type="subheading">
              <b>{this.state.selectedCount}</b>
              {this.state.selectedCount === 1 ? ' selectat' : ' selectate'}
            </Typography>
          ) : (
            <Typography type="title" variant="h6">
              {this.props.tableTitle}
            </Typography>
          )}
        </div>
        <div className="spacer" />
        <div className="actions">
          {this.state.selectedCount > 0 ? (
            <IconButton aria-label="Delete" onClick={this.removeItems}>
              <Delete />
            </IconButton>
          ) : (
            <div>
              <IconButton color="default" onClick={this.openItemDetailsDialog}>
                <Add style={{ fontSize: 22 }} />
              </IconButton>
              <IconButton
                color={this.state.editMode ? 'primary' : 'default'}
                onClick={this.toggleEditMode}
              >
                {this.state.editMode ? (
                  <Check style={{ fontSize: 22 }} />
                ) : (
                  <Edit style={{ fontSize: 22 }} />
                )}
              </IconButton>
            </div>
          )}
        </div>
      </Toolbar>
    );
  };

  displayPages = () => {
    const pages = [];

    for (let i = 0; i < this.state.totalCount / 4; i++) {
      let pageClassName = classNames('page', {
        active: this.state.currentPage === i
      });

      pages.push(
        <span
          className={pageClassName}
          onClick={this.changePage.bind(this, i)}
          key={i}
        >
          {i + 1}
        </span>
      );
    }

    return <div className="pages">{pages}</div>;
  };

  render() {
    return (
      <div className="admin-table">
        {this.displayItems()}
        {this.displayPages()}
        <ItemDetailsDialog
          open={this.state.dialogOpen}
          handleClose={this.onCloseItemDetailsDialog}
          handleSave={this.onSaveItem}
          item={this.state.dialogItem}
          dialogType={this.state.dialogType}
          type={this.props.itemType}
        />
      </div>
    );
  }
}

export default CustomPaginatedTable;
